import { CREDIT_CARD_FORM_ELEMENT } from './actions';

const initialState = {
  address: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREDIT_CARD_FORM_ELEMENT.SAVE_ADDRESS:
      return {
        ...state,
        address: action.payload.address,
      };

    case CREDIT_CARD_FORM_ELEMENT.SAVE_TAX_ID:
      return {
        ...state,
        taxId: action.payload.taxId,
      };

    case CREDIT_CARD_FORM_ELEMENT.CLEAR_ADDRESS:
      return initialState;

    default:
      return state;
  }
}
