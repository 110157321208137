import { call, select, put, take, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import Raven from 'raven-js';

import { isRegularUser } from '@frameio/core/src/users/utils';

import { isProduction } from 'config';
import { authenticate as authenticateSaga } from 'sagas/authedUser';
import { initSocketService } from 'sagas/sockets';
import { currentUserEntitySelector } from 'selectors/users';
import {
  configureCore,
  configureAuthRequiredInterceptor,
  getRefreshTokenId,
} from 'utils/auth';
import { redirectToAuth, redirectToAdobeIms, redirectTo } from 'utils/router';
import { getRedirectTrackingOpts } from 'analytics';
import DOMPurify from 'dompurify';
import { AUTHED_ROUTE, setLockedOutEmail } from './actions';

function handleFromAdobe() {
  /*
  when a user has one or more `+` symbol(s) in their email we want to preserve it by encoding all instances of the character
  before we sanitize, otherwise it will be translated as a
  space when used in a url
  */
  const regexPreservePlus = /\+/g;
  const queryParams = DOMPurify.sanitize(
    window.location.search.replace(regexPreservePlus, '%2B')
  );

  const urlParams = new URLSearchParams(queryParams);
  const originPath = urlParams.get('redirect_path');
  const userEmail = urlParams.get('email');

  return {
    originPath,
    userEmail,
  };
}

function* authenticate() {
  /*
    (adobe jira)FIOG-79: when coming from an adobe integrated service check params to take unauthed user to sign-in via adobe sso rather than to the welcome auth splash screen
  */

  const fromAdobe = new URLSearchParams(window.location.search).get(
    'from_adobe'
  );

  const params = fromAdobe && handleFromAdobe();

  /*
    When landing directly on app.frame.io before auth
    (say, when coming from the link on the marketing site), go to auth immediately. Note that if just the auth token is missing, we still want to skip this and attempt to refresh it.
  */
  if (!getRefreshTokenId() && !fromAdobe) {
    yield call(
      redirectToAuth,
      {},
      true,
      getRedirectTrackingOpts({
        details: 'Refresh token was not available',
      })
    );
    return;
  }

  yield call(configureCore);
  yield call(initSocketService);

  const stopRequiringLogin = yield call(configureAuthRequiredInterceptor);
  const { success, failure } = yield call(authenticateSaga);

  // When not connected to VPN, stay put and don't redirect to auth
  const hasAnyResponse = success || failure.payload?.error.response;
  const requiresVPN = !isProduction;
  if (requiresVPN && !hasAnyResponse) return;
  if (success) {
    const user = yield select(currentUserEntitySelector);
    const isAuthorized = yield call(isRegularUser, user);
    if (!isAuthorized) {
      yield call(
        redirectToAuth,
        {},
        true,
        getRedirectTrackingOpts({
          details:
            'Anonymous user manually added refreshTokenId to cookies and navigated to Frame app',
        })
      );
    }

    if (fromAdobe) {
      // when coming from an adobe integrated service,
      // we'll use the redirect link from params to route
      // the authed user to the intended page.
      yield call(redirectTo, params.originPath);
    }

    yield call(configureCore);

    // When AuthedRoute unmounts, cancel the interceptor above
    yield take(AUTHED_ROUTE.UNMOUNT);
    yield call(stopRequiringLogin);

    return;
  }

  // If api says that the email address is not confirmed, let
  // `AuthedRoute` render `UnconfirmedEmailLockout`.
  const email = get(failure, 'payload.error.response.data.errors[0].email');
  if (email) {
    yield put(setLockedOutEmail(email));
    return;
  }

  if (failure && fromAdobe) {
    // when coming from an adobe integrated service,
    // we'll redirect the unauthed user to login in via adobe
    // sso
    yield call(redirectToAdobeIms, params);
    return;
  }

  Raven.captureMessage('authenticate() failure, redirecting to auth', {
    level: 'warning',
    extra: {
      details: 'Auth token was not available or expired',
      message: failure?.payload?.error?.message,
      code: failure?.payload?.error?.response?.status,
      url: failure?.payload?.error?.config?.url,
    },
    tags: {
      jira_ticket: 'V3FRAME-1021',
    },
  });

  // Otherwise, ask the user to auth.
  yield call(
    redirectToAuth,
    {},
    true,
    getRedirectTrackingOpts({
      details: 'Auth token was not available or expired',
    })
  );
}

export const testExports = { authenticate };

export default [takeLatest(AUTHED_ROUTE.AUTHENTICATE, authenticate)];
