import { AUTHED_USER } from '@frameio/core/src/users/actions';
import { AUTHED_USER as CLIENT_AUTHED_USER } from 'actions/users';
import { ACCOUNTS_FOR_ME } from '@frameio/core/src/accounts/actions';
import { AUTHED_ROUTE } from './actions';

export default function currentUserReducer(
  state = {
    isAuthed: false,
    hasFetchedAccountsOnce: false,
  },
  action
) {
  const { payload } = action;
  switch (action.type) {
    case CLIENT_AUTHED_USER.SET_CURRENT:
      return {
        ...state,
        id: payload.userId,
        isAuthed: true,
      };
    case AUTHED_USER.GET.SUCCESS:
      return {
        ...state,
        id: payload.response.result,
        isAuthed: true,
      };
    case AUTHED_ROUTE.SET_LOCKED_OUT_EMAIL:
      return {
        ...state,
        lockedOutEmail: payload.email,
        isAuthed: false,
      };
    case ACCOUNTS_FOR_ME.GET.SUCCESS:
      return {
        ...state,
        hasFetchedAccountsOnce: true,
      };
    default:
      return state;
  }
}
