import {
  call,
  cancel,
  fork,
  put,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';
import track from 'analytics';
import { cancelAccount } from '@frameio/core/src/accounts/sagas';
import { MODAL } from 'components/Modal/actions';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { getSubscriptionByAccount } from '@frameio/core/src/subscriptions/sagas';
import { openSelectPlanFlowModal } from 'components/SelectPlanFlow/sagas';
import flowHistory from './history';
import {
  CANCELLATION_CONFIRMATION_URL,
  CANCELLATION_FEEDBACK_URL,
  CANCELLATION_REASON_URL,
  CANCELLATION_SAVE_OFFER_URL,
} from './constants';
import { CANCEL_ACCOUNT_FLOW, continueFlow, isFetching } from './actions';
import { canShowSaveOfferSelector } from './selectors';

const ERROR_MESSAGE =
  'Something went wrong when trying to cancel your account. Please contact support@frame.io.';
const SUCCESS_MESSAGE = 'Your subscription has been cancelled.';

const TOAST_DELAY = 3000;

function* openSelectPlanFlowWithSaveOffer({ payload }) {
  const { accountId, discountOffer, source } = payload;

  yield call(openSelectPlanFlowModal, accountId, { source }, discountOffer);
}

function* selectReason({ reason, source }) {
  yield spawn(track, 'cancellation-reason-submitted', {
    cancellation_reason: reason,
    cancellation_source: source,
  });
  yield call(flowHistory.push, CANCELLATION_FEEDBACK_URL);
  yield put(continueFlow());
}

function* submitFeedback({ feedback, reason, source }) {
  yield spawn(track, 'cancellation-message-submitted', {
    cancellation_message: feedback,
    cancellation_reason: reason,
    cancellation_source: source,
  });

  const canShowSaveOffer = yield select(canShowSaveOfferSelector);

  if (canShowSaveOffer) {
    yield call(flowHistory.push, CANCELLATION_SAVE_OFFER_URL);
  } else {
    yield call(flowHistory.push, CANCELLATION_CONFIRMATION_URL);
  }

  yield put(continueFlow());
}

function* continueCancel() {
  yield call(flowHistory.push, CANCELLATION_CONFIRMATION_URL);
  yield put(continueFlow());
}

function* confirmedCancellation({ accountId, feedback, reason, source }) {
  yield put(isFetching(true));
  yield spawn(track, 'subscription-cancelled', {
    cancellation_message: feedback,
    cancellation_reason: reason,
    cancellation_source: source,
  });
  const { failure } = yield call(cancelAccount, accountId);
  if (failure) {
    yield put(
      showErrorToast({
        header: ERROR_MESSAGE,
        autoCloseDelay: TOAST_DELAY,
      })
    );
    yield put(isFetching(false));
    return;
  }

  yield put(
    showSuccessToast({
      header: SUCCESS_MESSAGE,
      autoCloseDelay: TOAST_DELAY,
    })
  );
  yield call(getSubscriptionByAccount, accountId);
  yield put(isFetching(false));
}

function* watchCancelAccountFlow() {
  yield call(flowHistory.push, CANCELLATION_REASON_URL);
}

export function* startCancelAccountFlow(accountId) {
  const flow = yield fork(watchCancelAccountFlow, accountId);
  yield take([MODAL.CLOSE, CANCEL_ACCOUNT_FLOW.END]);
  yield cancel(flow);
  yield call(flowHistory.push, '');
}

export default [
  takeLatest(CANCEL_ACCOUNT_FLOW.START, ({ payload: { accountId } }) =>
    startCancelAccountFlow(accountId)
  ),
  takeLatest(CANCEL_ACCOUNT_FLOW.SELECT_REASON, ({ payload: { reason } }) =>
    selectReason(reason)
  ),
  takeLatest(CANCEL_ACCOUNT_FLOW.SUBMIT_FEEDBACK, ({ payload: { feedback } }) =>
    submitFeedback(feedback)
  ),
  takeLatest(CANCEL_ACCOUNT_FLOW.CLAIM_OFFER, openSelectPlanFlowWithSaveOffer),
  takeLatest(CANCEL_ACCOUNT_FLOW.CONTINUE_CANCEL, continueCancel),
  takeLatest(
    CANCEL_ACCOUNT_FLOW.CONFIRM_CANCELLATION,
    ({ payload: { accountId } }) => confirmedCancellation(accountId)
  ),
];
