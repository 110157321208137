import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

const module = 'CREDIT_CARD_FORM_ELEMENT';
export const CREDIT_CARD_FORM_ELEMENT = {
  ...generateActionTypes(module, [
    'SAVE_ADDRESS',
    'CLEAR_ADDRESS',
    'SAVE_TAX_ID',
  ]),
};

export const saveAddress = createAction(
  CREDIT_CARD_FORM_ELEMENT.SAVE_ADDRESS,
  (address) => ({ address })
);

export const clearSavedAddress = createAction(
  CREDIT_CARD_FORM_ELEMENT.CLEAR_ADDRESS,
  () => ({})
);

export const saveTaxId = createAction(
  CREDIT_CARD_FORM_ELEMENT.SAVE_TAX_ID,
  (taxId) => ({ taxId })
);
