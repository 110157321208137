import { modifierSortOrderIndices } from './constants';

export const getUpdatedAddOns = ({ addOns }) => {
  if (!addOns) return [];

  const updatedAddons = JSON.parse(JSON.stringify(addOns));

  /** type Plan does not include a modifer. This simply sets that. */
  const plan = updatedAddons.find((addOn) => {
    return addOn.item._type === 'plan';
  });

  if (plan) {
    plan.item.modifier = 'plan';
  }

  updatedAddons.sort((a, b) => {
    const aIndex = modifierSortOrderIndices[a.item.modifier];
    const bIndex = modifierSortOrderIndices[b.item.modifier];
    return aIndex - bIndex;
  });

  return updatedAddons;
};

export const showIntercom = () => {
  if (window.Intercom) {
    window.Intercom('show');
  }
};
