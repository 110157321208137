import SharesIcon from './icons/icon-shares.svg';
import MetadataIcon from './icons/icon-metadata.svg';
import CollectionsIcon from './icons/icon-collections.svg';
import AttachmentsIcon from './icons/icon-attachments.svg';
import ViewerIcon from './icons/icon-viewer.svg';
import IntegrationsIcon from './icons/icon-integrations.svg';
import NotSupportedIcon from './icons/icon-not-supported.svg';
import StorageIcon from './icons/icon-storage.svg';
import FoldersIcon from './icons/icon-folders.svg';

export const features = {
  newInV4: {
    title: 'What’s New',
    content: [
      {
        key: 'increased-storage',
        title: 'Increased Storage',
        description: 'Get 2 TB of extra storage for each additional paid user.',
        icon: StorageIcon,
      },
      {
        key: 'powerful-shares',
        title: 'Powerful Shares',
        description:
          'Create captivating presentations with custom colors, your logo, layout, and view configurations.',
        icon: SharesIcon,
      },
      {
        key: 'custom-metadata',
        title: 'Custom Metadata',
        description:
          'Tag, track, and organize your media with custom or 32 out-of-the-box metadata fields.',
        icon: MetadataIcon,
      },
      {
        key: 'collections',
        title: 'Collections',
        description:
          'Group and sort media assets into Collections to craft the perfect workflow for your team.',
        icon: CollectionsIcon,
      },
      {
        key: 'comment-attachments',
        title: 'Comment Attachments',
        description:
          'Attach references, links, and other assets with your feedback for added context.',
        icon: AttachmentsIcon,
      },
      {
        key: 'enhanced-viewer',
        title: 'Enhanced Viewer',
        description:
          'Transform your review process with precise frame previews, improved controls, and high-quality playback.',
        icon: ViewerIcon,
      },
    ],
  },

  comingSoon: {
    title: 'Not Available, Coming Soon',
    content: [
      {
        key: 'private-folders-assets',
        title: 'Private Folders & Assets',
        description:
          'To keep existing private folders & assets private, move them to a new project before upgrading.',
        icon: FoldersIcon,
      },
      {
        key: 'api-integrations-custom-actions-webhooks',
        title: 'API Integrations, Custom Actions & Webhooks',
        description:
          "3rd party/custom integrations (Zapier or image.canon) and webhooks won't work until the V4 API is available.",
        icon: IntegrationsIcon,
      },
    ],
  },

  notSupported: {
    title: 'Not Supported',
    content: [
      {
        key: 'davinci-resolve-integration',
        title: 'DaVinci Resolve Integration',
        description: 'Alternatively, you can export comments from Frame.io.',
        icon: NotSupportedIcon,
      },
      {
        key: 'after-effects-integration',
        title: 'After Effects Integration',
        description: 'Review comments on Frame.io.',
        icon: NotSupportedIcon,
      },
      {
        key: 'capture-one',
        title: 'Capture One',
        description:
          'Alternatively, use the Transfer app to download and upload from Frame.io.',
        icon: NotSupportedIcon,
      },
    ],
  },
};

export default features;
