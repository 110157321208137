import React from 'react';
import styled from 'styled-components';
import Proptypes from 'prop-types';
import { formatLongDate } from '@frameio/components/src/utils/datetimeHelpers';
import { V2_SETTINGS_PAGE_URL } from 'URLs';

const ReactivateSubscription = ({ subscriptionEndAt }) => (
  <DescriptionContainer>
    <Description>
      Your subscription has been cancelled. It will remain active until{' '}
      <span> {formatLongDate(subscriptionEndAt)}</span>, then downgrade to a
      free plan with reduced storage and features.
    </Description>
    <Description>
      Reactivate to continue with your previous subscription. When your
      subscription renews on <span> {formatLongDate(subscriptionEndAt)}</span>,
      you will be charged the total amount shown in your{' '}
      <a href={V2_SETTINGS_PAGE_URL.BILLING}>Account Billing</a>.
    </Description>
  </DescriptionContainer>
);

const DescriptionContainer = styled.div`
  p:not(:last-child) {
    margin-bottom: ${(p) => p.theme.spacing.units(2)};
  }
`;

const Description = styled.p`
  ${(p) => p.theme.fontStyle.body};
`;

ReactivateSubscription.propTypes = {
  subscriptionEndAt: Proptypes.string.isRequired,
};

export default ReactivateSubscription;
