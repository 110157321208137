import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import CarotThin from '@frameio/components/src/svgs/icons/carot-thin.svg';
import { noop, isNumber } from 'lodash';
import Button from '@frameio/components/src/styled-components/Button';
import {
  formatLongDate,
  formatShortDate,
} from '@frameio/components/src/utils/datetimeHelpers';
import ModalHeader from 'components/Modal/FullScreenModal/Header';
import { formatCents } from 'formatters/money';
import { TERMS_URL, PRIVACY_URL, V2_SETTINGS_PAGE_URL } from 'URLs';
import { formatBytes } from 'shared/filesizeHelpers';
import Spinner from '@frameio/components/src/styled-components/SpinnerBase';
import Flex from 'styled-flex-component';
import {
  getCountryNameFromAbbreviation,
  getIsTaxExempt,
  getIsBillingCountryVatExemptEligible,
} from 'components/CreditCardFormElements/helpers';
import IconInfo from 'components/MigrationModal/icons/icon-info.svg';
import { Tooltip } from '@frameio/vapor';
import { getXMonthsFromNow } from 'utils/dates';
import { storageModifers, addOnLabel, periodText } from './constants';
import { getUpdatedAddOns, showIntercom } from './utils';

const CONTAINER_MAX_WIDTH = 500;
const EXPANDED_BORDER_SPACING = 8;
const EXPANDED_TD_HEIGHT = 64;

const TEXT = {
  agreeAndSubscribe: 'Agree & Subscribe',
  back: 'Back',
  cancel: 'Cancel',
  header: 'Summary',
  afterFreeTrial: (promotionExpiresAt?: string) =>
    `After free trial ends ${
      promotionExpiresAt ? formatLongDate(promotionExpiresAt, 'MMMM d, y') : ''
    }`,
  description: 'Description',
  quanity: 'Quantity',
  unitPrice: 'Unit price',
  amount: 'Amount',
  subtotal: 'Subtotal',
  price: (price: number) => {
    const formattedCents = formatCents(price, true);
    return formattedCents;
  },
  pricePeriod: (period) => `/${periodText[period]}`,
  included: (userLimit) =>
    `${userLimit} ${userLimit === 1 ? 'User' : 'Users'} included`,
  addOnTitleStorage: ({ increment, modifier }) => {
    return {
      title: `${addOnLabel[modifier]} add-on`,
      description: `${formatBytes(increment)} each`,
    };
  },
  addOnTitle: ({ modifier }) => {
    return {
      title: `${addOnLabel[modifier]} add-on`,
      description: null,
    };
  },
  tax: 'Tax',
  dueNow: (isOnTrial, isDueNow, isFreePlan) => {
    const showDueNowAsterisk = isDueNow && !isFreePlan && !isOnTrial;

    return showDueNowAsterisk ? 'Due now*' : 'Due now';
  },
  currentPlanCanceled:
    'Your current plan will be cancelled and your new plan will begin immediately.',
  plan: (title, userLimit) => {
    return {
      title: `${title} plan`,
      description: isNumber(userLimit) ? `${TEXT.included(userLimit)}` : '',
    };
  },
  taxPercent: (price, taxPercent, isTaxExempt, billingCountry) => {
    const noTaxCharged = price === 0;

    if (billingCountry) {
      return noTaxCharged
        ? 'VAT (excluded)'
        : `VAT • ${billingCountry} (${taxPercent}%)`;
    }
    if (noTaxCharged) {
      return isTaxExempt ? 'Tax (excluded)' : 'Tax (0%)';
    }
    return `Tax (${taxPercent}%)`;
  },
  total: 'Total',
  dueNowDisclaimer: (
    <>
      *Due now amount based on when change is processed. A prorated credit for
      unused time on your existing subscription will be applied. Detailed
      receipt available on{' '}
      <a
        href={V2_SETTINGS_PAGE_URL.BILLING}
        target="_blank"
        rel="noopener noreferrer"
      >
        Billing page.
      </a>
    </>
  ),
  confirmationDisclaimer: ({
    period,
    isOnTrial,
    isDueNow,
    total,
    tax,
    isTaxExempt,
    isBillingCountryVatExemptEligible,
    selectedPlanData,
  }) => {
    const taxAndVatExemptionDetails = () => {
      const noTaxIncluded = tax === 0;

      if (isBillingCountryVatExemptEligible) {
        return noTaxIncluded ? '(excl. VAT)' : '(incl. VAT)';
      }
      if (isTaxExempt) {
        return '(excl. tax)';
      }
      return '';
    };

    const cancellation = (
      <>
        Cancel anytime via{' '}
        <a
          href={V2_SETTINGS_PAGE_URL.BILLING}
          target="_blank"
          rel="noopener noreferrer"
        >
          Account Settings
        </a>{' '}
        or{' '}
        <CustomerSupportButton onClick={showIntercom}>
          Customer Support
        </CustomerSupportButton>
        . You also agree to the Frame.io{' '}
        <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </>
    );

    if (selectedPlanData?.isDiscountOffer) {
      const offerEndsOn = formatShortDate(
        getXMonthsFromNow(selectedPlanData?.discountOfferDurationMonths)
      );

      if (period === 'MONTHLY') {
        return (
          <>
            By clicking “Agree & Subscribe”, you agree: You will be charged{' '}
            {formatCents(total, true)} monthly for the first year. After your
            offer term ends on {offerEndsOn}, you will be charged the
            then-current price.
            <br />
            At the end of your one-year term, your subscription will
            automatically renew monthly until you cancel. No annual commitment
            required after the first year. Price subject to change at renewal.{' '}
            {cancellation}
          </>
        );
      }

      return (
        <>
          By clicking “Agree & Subscribe”, you agree: You will be charged{' '}
          {formatCents(total, true)} for the first year.
          <br />
          After your offer term ends on {offerEndsOn}, you will be charged the
          then-current price annually. Your subscription will automatically
          renew annually until you cancel. Price subject to change at renewal.{' '}
          {cancellation}
        </>
      );
    }

    return (
      <>
        By clicking “Agree & Subscribe”, you agree:{' '}
        {isOnTrial && !isDueNow ? 'After the free trial ends, you' : 'You'} will
        be charged {formatCents(total, true)} {taxAndVatExemptionDetails()}{' '}
        {period.toLowerCase()}. Your subscription will automatically renew{' '}
        {period.toLowerCase()} until you cancel (price subject to change).{' '}
        {cancellation}
      </>
    );
  },
  toolTip:
    'Ensure your Tax ID is correct in your Payment Details to exclude tax.',
};

const BackButton = styled(Button)`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: space-between;
`;

const BackArrow = styled(CarotThin)`
  color: ${({ theme }) => theme.color.gray};
  width: ${(p) => p.theme.spacing.small};
  padding-right: ${(p) => p.theme.spacing.micro};
  padding-bottom: 1px;
  vertical-align: bottom;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  min-width: 100px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
  justify-content: right;
  padding: 24px;
`;

const Container = styled.div`
  width: 100%;
  max-width: ${CONTAINER_MAX_WIDTH}px;

  ${(p) =>
    !p.$isCollapsed &&
    `
    margin: auto;
   `};
`;

const Text = styled.span`
  color: ${(p) => (p.lightGray ? p.theme.color.gray : p.theme.color.gray900)};
  font-family: Avenir Next;
  font-size: ${(p) =>
    p.small || p.$isCollapsed ? p.theme.fontSize[0] : p.theme.fontSize[2]};
  font-weight: ${(p) =>
    p.bold ? p.theme.fontWeight.bold : p.theme.fontWeight.normal};
  line-height: ${(p) => p.theme.lineHeight[2]};
  margin: 0 0;
`;

const AddOnRow = styled.tr`
  ${(p) =>
    !p.$isCollapsed &&
    `
       background-color: ${p.theme.color.coldWhite};
   `};
`;

const PriceContainer = styled.div`
  align-items: baseline;
  display: inline-flex;
  gap: 4px;
`;

const StyledTable = styled.table`
  width: 100%;

  th,
  td {
    text-align: right;
  }

  td:first-child {
    text-align: left;
  }

  th:first-child {
    text-align: left;
  }

  td {
    padding: 0;
  }

  th {
    padding: 0 0 2px 0;
  }

  ${(p) =>
    p.$isCollapsed &&
    `border-spacing: 0;
      td {
       vertical-align: baseline;
    }`};

  ${(p) =>
    !p.$isCollapsed &&
    `border-spacing: 0 ${EXPANDED_BORDER_SPACING}px;
    margin: -${EXPANDED_BORDER_SPACING}px 0;

    td:first-child {
      border-radius: 8px 0 0 8px;
      padding-left:   16px;
    }

    td:last-child {
      border-radius: 0 8px 8px 0;
      padding-right: 16px;
    }

    td {
      height: ${EXPANDED_TD_HEIGHT}px;
     }

    th:first-child {
      padding-left:   16px;
    }

    th:last-child {
       padding-right:   16px;
    }

     th {
       padding-bottom: 4px
    }
  `};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 2px;

  ${(p) =>
    !p.$isCollapsed &&
    `
    padding: 16px 16px 0 16px;
    gap: 16px;
 `}
`;

const AddOnContainer = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.$isCollapsed ? 'row' : 'column')};
  gap: ${(p) => p.theme.spacing.micro};
  white-space: nowrap;
`;

const CustomerSupportButton = styled.button`
  background: none;
  border: none;
  color: #007aff;
  cursor: pointer;
  font-size: ${(p) => p.theme.fontSize[0]};
  line-height: ${(p) => p.theme.lineHeight[2]};
  text-decoration: none;
`;

const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 0 16px;
`;

const Separator = styled.div`
  background-color: ${(p) => p.theme.color.coldWhite};
  width: 100%;
  height: 1px;
`;

const TaxPercentWrapper = styled.div`
  align-items: center;
  gap: 4px;
  display: flex;
  flex-direction: row;
  z-index: 9;
`;

const PriceDisplay = ({ amount, period, isCollapsed }) => {
  return (
    <PriceContainer>
      <Text bold $isCollapsed={isCollapsed}>
        {TEXT.price(amount)}
      </Text>{' '}
      <Text $isCollapsed={isCollapsed}>{TEXT.pricePeriod(period)}</Text>
    </PriceContainer>
  );
};

const UpgradedPlanSummary = ({
  accountId,
  continueFlow,
  isOnTrial,
  isSubmitting,
  isFreePlan,
  onCtaClick = noop,
  promotionExpiresAt,
  selectedPlan,
  restartFlow,
  shouldDisplayCurrentPlan,
  planQuote,
  onCancel,
  isManagingStorage = false,
  showDetailsOnly = false,
  billingCountry,
  hasVat,
  selectedPlanData,
}) => {
  const subtotal = planQuote.subtotal;
  const tax = planQuote.tax;
  const taxPercent = planQuote.tax_percent;
  const total = planQuote.total;
  const invoiceLineItems = planQuote.invoice_line_items;
  const dueNow = planQuote.due_now;
  const period = selectedPlan.period.toUpperCase();
  const isDueNow = true;

  const showTrialTotal = isOnTrial && !isDueNow;

  const showDueNowDisclaimer = isDueNow && !isFreePlan && !isOnTrial;

  const showCancelDisclaimer = showDueNowDisclaimer && !isManagingStorage;

  const addOns = getUpdatedAddOns({
    addOns: invoiceLineItems,
  });

  const showSpinner = Object.keys(planQuote).length === 0;

  const showBackButton =
    !shouldDisplayCurrentPlan &&
    !showDetailsOnly &&
    !!restartFlow &&
    /** Plan is preselected */
    !selectedPlanData?.planId;

  const showCtas = !showDetailsOnly && !!continueFlow;

  const countryNameFromAbbreviation = getCountryNameFromAbbreviation(
    billingCountry
  );

  const taxExempt = planQuote?.taxExempt;
  const isTaxExempt = getIsTaxExempt(taxExempt);
  const isBillingCountryVatExemptEligible = getIsBillingCountryVatExemptEligible(
    billingCountry
  );

  const maybeHasErrorValidatingVat =
    isBillingCountryVatExemptEligible && tax > 0 && hasVat;

  const shouldShowMaybeHasErrorValidatingVat =
    maybeHasErrorValidatingVat && !showDetailsOnly;

  return (
    <>
      {showBackButton && (
        <BackButton text onClick={() => restartFlow(accountId)}>
          <BackArrow />
          {TEXT.back}
        </BackButton>
      )}

      <Container
        $isCollapsed={showDetailsOnly}
        data-testid="upgraded-plan-summary"
      >
        {showSpinner ? (
          <Flex justifyCenter>
            <Spinner
              color="91,83,255"
              size={24}
              spinning
              radius={10}
              stroke={2}
            />
          </Flex>
        ) : (
          <>
            {!showDetailsOnly && <ModalHeader>{TEXT.header}</ModalHeader>}
            <StyledTable $isCollapsed={showDetailsOnly}>
              <thead>
                <tr>
                  <th>
                    <Text lightGray small>
                      {TEXT.description}
                    </Text>
                  </th>
                  <th>
                    <Text lightGray small>
                      {TEXT.quanity}
                    </Text>
                  </th>
                  <th>
                    <Text lightGray small>
                      {TEXT.unitPrice}
                    </Text>
                  </th>
                  <th>
                    <Text lightGray small>
                      {TEXT.amount}
                    </Text>
                  </th>
                </tr>
              </thead>

              <tbody>
                {/** add-on */}
                {addOns.map(
                  ({
                    amount: cost,
                    unit_amount: costPerUnit,
                    item,
                    quantity,
                  }) => {
                    const noAddOn = quantity === 0;

                    if (noAddOn) return null;

                    const { increment, modifier } = item;
                    const addOnTitleParams = {
                      increment,
                      modifier,
                      quantity,
                    };

                    const addOnTitle = storageModifers.has(modifier)
                      ? TEXT.addOnTitleStorage(addOnTitleParams)
                      : TEXT.addOnTitle(addOnTitleParams);

                    const description =
                      modifier === 'plan'
                        ? TEXT.plan(item?.title, item?.user_limit)
                        : addOnTitle;

                    return (
                      <AddOnRow key={modifier} $isCollapsed={showDetailsOnly}>
                        <td>
                          <AddOnContainer $isCollapsed={showDetailsOnly}>
                            <Text $isCollapsed={showDetailsOnly}>
                              {description.title}
                            </Text>
                            {!!description.description && (
                              <Text
                                lightGray
                                small
                                $isCollapsed={showDetailsOnly}
                              >
                                {showDetailsOnly
                                  ? `(${description.description})`
                                  : description.description}
                              </Text>
                            )}
                          </AddOnContainer>
                        </td>
                        <td>
                          <Text $isCollapsed={showDetailsOnly}>{quantity}</Text>
                        </td>
                        <td>
                          <PriceDisplay
                            isCollapsed={showDetailsOnly}
                            amount={costPerUnit}
                            period={period}
                          />
                        </td>
                        <td>
                          <PriceDisplay
                            isCollapsed={showDetailsOnly}
                            amount={cost}
                            period={period}
                          />
                        </td>
                      </AddOnRow>
                    );
                  }
                )}
              </tbody>
            </StyledTable>
            <ContentContainer $isCollapsed={showDetailsOnly}>
              {/** subtotal */}
              {isNumber(subtotal) && (
                <Wrapper>
                  <Text $isCollapsed={showDetailsOnly} lightGray>
                    {TEXT.subtotal}
                  </Text>
                  <PriceDisplay
                    amount={subtotal}
                    period={period}
                    isCollapsed={showDetailsOnly}
                  />
                </Wrapper>
              )}

              {/** tax percent */}
              {isNumber(taxPercent) && isNumber(tax) && (
                <Wrapper>
                  <TaxPercentWrapper>
                    <Text $isCollapsed={showDetailsOnly} lightGray>
                      {TEXT.taxPercent(
                        tax,
                        taxPercent,
                        isTaxExempt,
                        countryNameFromAbbreviation
                      )}
                    </Text>

                    {shouldShowMaybeHasErrorValidatingVat && (
                      <Tooltip placement="top" title={TEXT.toolTip}>
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <IconInfo
                            style={{
                              width: '16px',
                              height: '16px',
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </TaxPercentWrapper>
                  <PriceDisplay
                    amount={tax}
                    period={period}
                    isCollapsed={showDetailsOnly}
                  />
                </Wrapper>
              )}

              {!showDetailsOnly && <Separator />}

              {/* doesnt show if not billed immediately */}
              {isNumber(total) && isDueNow && (
                <Wrapper>
                  <Text $isCollapsed={showDetailsOnly}>{TEXT.total}</Text>
                  <PriceDisplay
                    amount={total}
                    period={period}
                    isCollapsed={showDetailsOnly}
                  />
                </Wrapper>
              )}

              {/* after free trial */}
              {showTrialTotal && isNumber(total) && (
                <Wrapper>
                  <Text $isCollapsed={showDetailsOnly}>
                    {TEXT.afterFreeTrial(promotionExpiresAt)}
                  </Text>
                  <PriceDisplay
                    amount={total}
                    period={period}
                    isCollapsed={showDetailsOnly}
                  />
                </Wrapper>
              )}

              {/* due now */}
              {isNumber(dueNow) && !showDetailsOnly && (
                <>
                  <Wrapper>
                    <Text $isCollapsed={showDetailsOnly}>
                      {TEXT.dueNow(isOnTrial, isDueNow, isFreePlan)}
                    </Text>
                    <Text bold $isCollapsed={showDetailsOnly}>
                      {formatCents(dueNow, true)}
                    </Text>
                  </Wrapper>
                  {showCancelDisclaimer && (
                    <Text small bold $isCollapsed={showDetailsOnly}>
                      {TEXT.currentPlanCanceled}
                    </Text>
                  )}
                </>
              )}
            </ContentContainer>
            {/*  disclaimers */}
            {!showDetailsOnly && (
              <DisclaimerContainer>
                {showDueNowDisclaimer && (
                  <Text lightGray small>
                    {TEXT.dueNowDisclaimer}
                  </Text>
                )}
                <Text lightGray small>
                  {TEXT.confirmationDisclaimer({
                    period,
                    isOnTrial,
                    isDueNow,
                    total,
                    tax,
                    isTaxExempt,
                    isBillingCountryVatExemptEligible,
                    selectedPlanData,
                  })}
                </Text>
              </DisclaimerContainer>
            )}
          </>
        )}
      </Container>

      {showCtas && (
        <ButtonContainer>
          <StyledButton
            onClick={() => {
              onCancel();
            }}
          >
            {TEXT.cancel}
          </StyledButton>

          <StyledButton
            disabled={isSubmitting}
            primary
            onClick={() => {
              onCtaClick();
              continueFlow(selectedPlan);
            }}
          >
            {TEXT.agreeAndSubscribe}
          </StyledButton>
        </ButtonContainer>
      )}
    </>
  );
};

UpgradedPlanSummary.defaultProps = {
  accountId: undefined,
  isOnTrial: false,
  isSubmitting: false,
};

UpgradedPlanSummary.propTypes = {
  accountId: Proptypes.string,
  continueFlow: Proptypes.func,
  isOnTrial: Proptypes.bool,
  isFreePlan: Proptypes.bool,
  isSubmitting: Proptypes.bool,
  promotionExpiresAt: Proptypes.string,
  selectedPlan: Proptypes.object.isRequired,
  restartFlow: Proptypes.func,
};

export const testExports = { BackButton };
export default UpgradedPlanSummary;
