/**
 * Returns a timestamp for X months from now
 *
 * @param {number} months - number of months to add to the current date
 * @return {number} - a timestamp of the date X months from now
 */
export const getXMonthsFromNow = (months = 0) => {
  const now = new Date();
  return new Date(now.setMonth(now.getMonth() + months)).getTime();
};
