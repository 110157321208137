/* eslint-disable no-param-reassign */
import { keyBy, cloneDeep } from 'lodash';
import { defaultPlansForPlanSelector } from '@frameio/core/src/plans/selectors';
import { planLineItemsForPlanSelector } from '@frameio/core/src/planLineItems/selectors';
import {
  currentAccountWithSubscriptionAndPlanSelector,
  currentAccountEntitySelector,
} from 'selectors/accounts';
import {
  STORAGE_LIMIT,
  ARCHIVED_STORAGE_LIMIT,
  MEMBER_LIMIT,
  USER_LIMIT,
} from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import {
  MEMBER_COUNT,
  USER_COUNT,
  STORAGE,
  ARCHIVED_STORAGE,
} from '@frameio/core/src/accounts/utils/usageTypes';
import {
  planData,
  planIds as legacyPlanNames,
  monthlyItemCost,
} from 'utils/plans/plansHelpers';
import { subscriptionLineItemSelector } from 'components/ManageSubscription/selectors';
import { createSelector } from 'reselect';
import {
  isYearlyPlanForbiddenInCountry,
  isYearlyPlanForbiddenInUsaState,
} from 'utils/isYearlyPlanForbidden';
import { planEntityForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { v8PricingOnlyEnabled } from 'utils/featureFlags';
import basePlansByTier, {
  defaultPlanVersion,
  generateLegacyFeatures,
  getEnterprisePlanCopy,
  getLegacyPlanIds,
  LEGACY_PLAN_TIERS,
  PLAN_TIERS,
} from './PlanDisplay';

const transformUsageValues = (account) => ({
  [MEMBER_LIMIT]: account[MEMBER_COUNT],
  [USER_LIMIT]: account[USER_COUNT],
  [STORAGE_LIMIT]: account[STORAGE],
  [ARCHIVED_STORAGE_LIMIT]: account[ARCHIVED_STORAGE],
});

export const selectPlanSelector = (state) => state.selectPlan || {};

export const isFetchingPlanSelector = (state) =>
  selectPlanSelector(state).isFetching;

export const selectedPlanSelector = (state) =>
  selectPlanSelector(state).selectedPlan;

export const isFreePlanWithUserMaxSelectedSelector = (state) => {
  const selectedPlan = selectedPlanSelector(state);
  if (!selectedPlan) return false;
  return selectedPlan.tier === PLAN_TIERS.FREE && selectedPlan.version >= 5;
};

const planTierSortOrder = {
  [PLAN_TIERS.FREE]: 0,
  [PLAN_TIERS.PRO]: 1,
  [PLAN_TIERS.TEAM]: 2,
  [PLAN_TIERS.ENTERPRISE]: 3,
};

function sortByPeriodThenTier(a, b) {
  if (a.period < b.period) {
    return -1;
  }
  if (a.period > b.period) {
    return 1;
  }
  if (planTierSortOrder[a.tier] < planTierSortOrder[b.tier]) {
    return -1;
  }
  if (planTierSortOrder[a.tier] > planTierSortOrder[b.tier]) {
    return 1;
  }
  return 0;
}

const defaultEligibility = 'everyone';

export const plansWithDisplaySelector = (
  state,
  { id, isAdobeBridgeOfferEligible, version }
) => {
  const arev8PricingOnlyEnabled = v8PricingOnlyEnabled(state);

  const planVersionForPlanSelect = arev8PricingOnlyEnabled
    ? defaultPlanVersion
    : version;

  const combinedPlans = defaultPlansForPlanSelector(state, { id })
    .sort(sortByPeriodThenTier)
    .reduce((combinedPlansAccumulator, plan, index, allPlans) => {
      const {
        id: planId,
        tier,
        period,
        eligibility = defaultEligibility,
      } = plan;

      const expectedEligibility = isAdobeBridgeOfferEligible
        ? 'adobe_bridge_offer'
        : defaultEligibility;

      // the endpoint will include plans from all accounts you are associated with
      // we need to filter out plans that are not within the same version and eligibility
      // as the user's current plan
      if (
        plan.version !== planVersionForPlanSelect ||
        eligibility !== expectedEligibility
      ) {
        return combinedPlansAccumulator;
      }

      const lineItems = planLineItemsForPlanSelector(state, { planId });

      const basePlan = basePlansByTier(
        plan,
        keyBy(lineItems, 'modifier'),
        allPlans[index - 1],
        {
          isAdobeBridgeOfferEligible,
        }
      );

      if (!basePlan) {
        return combinedPlansAccumulator;
      }

      if (!combinedPlansAccumulator[period]) {
        combinedPlansAccumulator[period] = {};
      }

      // we add these to an object so we can leave out plan tier/period duplicates
      // we want to use the last plan we see for a given tier/period
      combinedPlansAccumulator[period][tier] = basePlan;

      return combinedPlansAccumulator;
    }, {});

  if (combinedPlans.monthly) {
    // the plans endpoint does not include enterprise plans
    combinedPlans.monthly[PLAN_TIERS.ENTERPRISE] = cloneDeep(
      basePlansByTier({
        version: planVersionForPlanSelect,
        tier: PLAN_TIERS.ENTERPRISE,
      })
    );

    combinedPlans.monthly = Object.values(combinedPlans.monthly);
  }

  if (combinedPlans.yearly) {
    combinedPlans.yearly[PLAN_TIERS.ENTERPRISE] = cloneDeep(
      basePlansByTier({
        version: planVersionForPlanSelect,
        tier: PLAN_TIERS.ENTERPRISE,
      })
    );

    combinedPlans.yearly = Object.values(combinedPlans.yearly);
  }

  return combinedPlans;
};

export const generateSummaryLineItem = ({
  accountUsages,
  modifier,
  plan,
  planLineItem,
}) => {
  const usage = accountUsages[modifier] || 0;
  // calculate how many additional line items the user will need beyond the plan's built in limit
  const neededLineItemQuantity = Math.max(0, usage - plan[modifier]);
  // pick the higher between new plan lower limit and the current number of seats
  let quantity = Math.max(usage, plan[modifier]);
  // calculating how much the new line items are going to cost
  let cost =
    Math.ceil(neededLineItemQuantity / planLineItem.increment) *
    planLineItem.cost;
  switch (modifier) {
    case ARCHIVED_STORAGE_LIMIT:
    case STORAGE_LIMIT:
      quantity = Math.max(quantity, 0);
      break;
    case USER_LIMIT:
    case MEMBER_LIMIT:
      // Plan's cost is assumed to be a member limit cost
      cost += plan.cost;
      break;
    default:
      cost += 0;
  }
  return { quantity, cost };
};

export const updatedLineItemInfoByModifierSelector = (
  state,
  { modifiers, plan, account }
) => {
  const accountUsages = transformUsageValues(account);

  return planLineItemsForPlanSelector(state, { planId: plan.id })
    .filter(({ modifier }) => modifiers.includes(modifier))
    .reduce((combinedLineItems, planLineItem) => {
      const { modifier } = planLineItem;
      combinedLineItems[modifier] = generateSummaryLineItem({
        accountUsages,
        modifier,
        plan,
        planLineItem,
      });

      return combinedLineItems;
    }, {});
};

export const legacyPlansWithDisplaySelector = (state) => {
  const { plan: currentPlan } = currentAccountWithSubscriptionAndPlanSelector(
    state
  );
  const isCurrentPlanMonthly = currentPlan.period === 'monthly';

  const legacyPlanIds = getLegacyPlanIds();

  return (
    Object.keys(LEGACY_PLAN_TIERS)
      .map((planTier) => {
        const planName = LEGACY_PLAN_TIERS[planTier];
        return {
          ...planData[planName],

          // `planData` in `plansHelpers.js` do NOT return the `name` and the `title` of the plan so
          // let's create them here while we have access to the `planName`.
          name: legacyPlanNames[planName.toUpperCase()], // e.g. 20181119-01-starter
          title: planName,
        };
      })

      // This is necessary to "massage" the legacy plans data so we can display the legacy plans in
      // <SelectPlan />.
      .reduce((formattedLegacyPlans, plan) => {
        // when plan is enterprise, return the default enterprise plan created in `planDisplay.js`
        if (plan.title === 'Enterprise') {
          formattedLegacyPlans[plan.title] = {
            monthly: getEnterprisePlanCopy(2, true),
            yearly: getEnterprisePlanCopy(2),
          };
          return formattedLegacyPlans;
        }
        const planNameMonthly = `${plan.name}-mo`; // e.g. 20181119-01-starter-mo
        const planNameYearly = `${plan.name}-yr`; // e.g. 20181119-01-starter-yr

        const shouldAdjustMonthlyPlan =
          currentPlan.title === plan.title && isCurrentPlanMonthly;
        const shouldAdjustYearlyPlan =
          currentPlan.title === plan.title && !isCurrentPlanMonthly;
        const title = plan.title === 'Professional' ? 'Pro' : plan.title; // e.g. Starter / Pro / Team / Business / Enterprise

        formattedLegacyPlans[plan.title] = {
          monthly: {
            ...plan,
            id: legacyPlanIds[planNameMonthly],

            // GROW-510 – use the current plan price rather than the listed price.
            // This ensures that the customer always sees the price they are paying.
            cost: shouldAdjustMonthlyPlan
              ? currentPlan.cost
              : plan.price.monthly,
            features: generateLegacyFeatures(plan),

            // When the account is on a V1 plan, we need to ensure that the user sees the current plan
            // as being the current selected plan in `<SelectPlan />`.
            name: shouldAdjustMonthlyPlan ? currentPlan.name : planNameMonthly,
            period: 'monthly',
            selfServe: true,
            subtitle: '',

            // Renaming the current plan title only when it's a Pro plan so that when the account is
            // on a legacy plan, the user sees the current plan as being the current selected plan in
            // `<SelectPlan />`.
            title,
          },
          yearly: {
            ...plan,
            id: legacyPlanIds[planNameYearly],

            // GROW-510 – use the current plan price rather than the listed price.
            // This ensures that the customer always sees the price they are paying.

            // For annual legacy plans, the cost is the total amount due every year (e.g. Starter plan
            // is $13/month when billed annually - the legacy plan would show a cost of $156 ($13 * 12
            // months)). We're changing it here so it shows the adjusted monthly cost in
            // `<SelectPlan />`.
            cost: shouldAdjustYearlyPlan
              ? currentPlan.cost / 12
              : plan.price.yearly,
            features: generateLegacyFeatures(plan),

            // When the account is on a V1 plan, we need to ensure that the user sees the current plan
            // as being the current selected plan in `<SelectPlan />`.
            name: shouldAdjustYearlyPlan ? currentPlan.name : planNameYearly,
            period: 'yearly',
            selfServe: true,
            subtitle: '',

            // Renaming the current plan title only when it's a Pro plan so that when the account is
            // on a legacy plan, the user sees the current plan as being the current selected plan in
            // `<SelectPlan />`.
            title,
          },
        };
        return formattedLegacyPlans;
      }, {})
  );
};

export const archivalStorageAddOnForLegacyPlanSelector = (state) => {
  const {
    account,
    subscription,
    plan,
  } = currentAccountWithSubscriptionAndPlanSelector(state);
  const archivalStorageLineItem = subscriptionLineItemSelector(state, {
    accountId: account.id,
    plan,
    modifier: ARCHIVED_STORAGE_LIMIT,
  });
  const archivalStorageLineItemCost = monthlyItemCost(
    plan.period,
    archivalStorageLineItem.totalCost
  );

  if (archivalStorageLineItemCost === 0) return {};
  return {
    cost: archivalStorageLineItemCost,
    quantity: subscription.total_archived_storage_limit,
  };
};

// Check the address that is currently stored in Stripe database
export const isYearlyPlanForbiddenSelector = createSelector(
  currentAccountEntitySelector,
  (currentAccountEntity) =>
    isYearlyPlanForbiddenInCountry(currentAccountEntity.address?.country) ||
    (currentAccountEntity.address?.country === 'US' &&
      isYearlyPlanForbiddenInUsaState(currentAccountEntity.address?.state))
);

export const hasYearlyPlanSelector = createSelector(
  currentAccountEntitySelector,
  (state) => state.selectPlan?.selectedPlan,
  (state) => state,
  (currentAccountEntity = {}, selectedPlan, state) => {
    const yearlyPlanPeriod = 'yearly';
    const { id: accountId } = currentAccountEntity;
    const plan = planEntityForAccountIdSelector(state, { accountId });

    const isCurrentPlanYearlyAndNewPlanIsNotSelected =
      !selectedPlan?.period && plan?.period === yearlyPlanPeriod;

    // The selected plan is the one the user is planning to switch to,
    // it is not yet assigned to the account.
    const isSelectedPlanYearly = selectedPlan?.period === yearlyPlanPeriod;

    return isCurrentPlanYearlyAndNewPlanIsNotSelected || isSelectedPlanYearly;
  }
);

export const selectedPlanQuote = (state) => state.selectPlan.planQuote || {};
