export const CANCELLATION_REASON_URL = '/StartCancellation';
export const CANCELLATION_FEEDBACK_URL = '/CancellationFeedback';
export const CANCELLATION_SAVE_OFFER_URL = '/SaveOffer';
export const CANCELLATION_CONFIRMATION_URL = '/EndCancellation';

export const SHARED_TEXT = {
  REASON_OPTIONS: {
    MISSING_FEATURE: {
      BUTTON_TEXT: 'It’s missing a feature I need',
      DESCRIPTION_TEXT:
        'What feature(s) are you missing?  We’ll do our best to improve based on your feedback.',
      TRACKING_TEXT: 'missing feature',
    },
    DIFFERENT_SOLUTION: {
      BUTTON_TEXT: 'I prefer a different solution',
      DESCRIPTION_TEXT:
        'What solution(s) do you prefer over Frame.io?  We’ll do our best to improve based on your feedback.',
      TRACKING_TEXT: 'prefer different solution',
    },
    FINISHED_PROJECT: {
      BUTTON_TEXT: 'I finished my current project',
      DESCRIPTION_TEXT: 'When do you expect your next project to begin?',
      OPTIONS: {
        ONE_TO_TWO_MONTHS: '1-2 months',
        THREE_TO_SIX_MONTHS: '3-6 months',
        SEVEN_TO_TWELVE_MONTHS: '7-12 months',
        NEXT_YEAR: 'Next year',
        NOT_SURE: 'I’m not sure',
      },
      TRACKING_TEXT: 'finished project',
    },
    TOO_EXPENSIVE: {
      BUTTON_TEXT: 'It’s too expensive',
      DESCRIPTION_TEXT:
        'What monthly price point would be more reasonable?  We’ll do our best to improve based on your feedback.',
      TRACKING_TEXT: 'too expensive',
    },
    OTHER: {
      BUTTON_TEXT: 'Other',
      DESCRIPTION_TEXT:
        'Can you share more detail about why you’re cancelling?  We really appreciate your feedback.',
      TRACKING_TEXT: 'other',
    },
  },
};
