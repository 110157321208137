import React, { useEffect } from 'react';
import styled from 'styled-components';
import { formatShortDate } from '@frameio/components/src/utils/datetimeHelpers';
import { formatMoney } from 'formatters/money';
import { getXMonthsFromNow } from 'utils/dates';
import CancelAccountFlowModal from '../CancelAccountFlowModal';
import { getTermsAndConditionsUrlForTier } from './utils';
import ConstellationIcon from './constellationIcon.svg';
import { useSaveOfferTracking } from './hooks/useSaveOfferTracking';

const PERIOD_TEXT = {
  monthly: 'mo.',
  semiannually: 'six months',
  yearly: 'yr.',
};

const TEXT = {
  actions: {
    primary: 'Continue to Claim Offer',
    secondary: 'Continue to Cancel',
  },
  currentPrice: (period, currentPrice) => (
    <Group gap={4}>
      <StrikeThroughText>
        {formatMoney(currentPrice)} /{PERIOD_TEXT[period]} per user
      </StrikeThroughText>
      (current plan price)
    </Group>
  ),
  disclaimer: (tier, offerDurationInMonths) => {
    const termsAndConditionsUrl = getTermsAndConditionsUrlForTier(tier);
    return (
      <Group gap={4}>
        Your discount will end{' '}
        {formatShortDate(getXMonthsFromNow(offerDurationInMonths))}.
        <Link href={termsAndConditionsUrl} target="_blank">
          See terms
        </Link>
      </Group>
    );
  },
  pill: (offerDurationInMonths) => `${offerDurationInMonths} Month Discount`,
  pricePeriod: (period) => `/${PERIOD_TEXT[period]} (+ tax) per user`,
  title: 'Before you go, here’s a special offer',
};

const SaveOffer = ({
  accountId,
  claimOffer,
  continueCancel,
  currentPlan,
  currentStep,
  discountPlan,
  onClose,
  source,
}) => {
  const tracking = useSaveOfferTracking({
    accountId,
    currentPlan,
    discountPlan,
  });

  const handleClaimOffer = () => {
    const discountOffer = {
      discountOfferDurationMonths:
        currentPlan.discount_offer_duration_months ?? 0,
      isDiscountOffer: true,
      planId: discountPlan.id,
    };

    claimOffer(accountId, discountOffer, source);

    tracking.trackOfferAccepted();
  };

  const handleContinueCancel = () => {
    continueCancel();
    tracking.trackOfferDeclined();
  };

  useEffect(() => {
    tracking.trackOfferShown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CancelAccountFlowModal
      autoHeight
      currentStep={currentStep}
      headerText={TEXT.title}
      onClose={onClose}
      primaryAction={handleClaimOffer}
      primaryButtonText={TEXT.actions.primary}
      secondaryAction={handleContinueCancel}
      secondaryButtonText={TEXT.actions.secondary}
    >
      <Card>
        <Stack gap={12}>
          <Group gap={8}>
            <HeadingText>{discountPlan.title}</HeadingText>

            <Pill gap={4}>
              <ConstellationIcon />

              <PillText>
                {TEXT.pill(currentPlan.discount_offer_duration_months)}
              </PillText>
            </Pill>
          </Group>

          <Stack gap={6}>
            <DeemphasizedText gap={2}>
              {TEXT.currentPrice(currentPlan.period, currentPlan.cost)}
            </DeemphasizedText>

            <BaseLine gap={4}>
              <HeadingText>{formatMoney(discountPlan.cost, false)}</HeadingText>

              <DeemphasizedText>
                {TEXT.pricePeriod(discountPlan.period)}
              </DeemphasizedText>
            </BaseLine>
          </Stack>

          <Separator />

          <Text>
            {TEXT.disclaimer(
              discountPlan.tier,
              currentPlan.discount_offer_duration_months
            )}
          </Text>
        </Stack>
      </Card>
    </CancelAccountFlowModal>
  );
};

const Card = styled.div`
  background-color: ${(p) => p.theme.color.coldWhite};
  border-radius: ${(p) => p.theme.radius.large};
  margin-bottom: 16px;
  padding: 16px;
`;

const Group = styled.div`
  display: flex;
  gap: ${(p) => p.gap ?? 0}px;
`;

const Stack = styled(Group)`
  flex-direction: column;
`;

const BaseLine = styled(Group)`
  align-items: baseline;
  display: inline-flex;
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
`;

const HeadingText = styled.span`
  color: ${(p) => p.theme.color.darkGray};
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
`;

const PillText = styled.p`
  color: ${(p) => p.theme.color.white};
  font-size: ${(p) => p.theme.fontSize[0]};
  line-height: 16px;
  font-weight: 500;
`;

const DeemphasizedText = styled(Group)`
  font-size: ${(p) => p.theme.fontSize[1]};
  line-height: 16px;
  font-weight: 500;
  color: ${(p) => p.theme.color.gray};
`;

const StrikeThroughText = styled.span`
  text-decoration: line-through;
`;

const Pill = styled(Group)`
  align-items: center;
  background-color: ${(p) => p.theme.color.success};
  border-radius: 16px;
  max-height: fit-content;
  min-width: fit-content;
  padding: 6px 8px;
`;

const Link = styled.a`
  color: ${(p) => p.theme.color.brand};
  text-decoration: none;
`;

const Separator = styled.div`
  background-color: ${(p) => p.theme.color.lightGray};
  height: 1px;
`;

export default SaveOffer;
