import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import IconCircle, {
  iconCircleColors,
} from '@frameio/components/src/styled-components/IconCircle';
import { buttonReset } from '@frameio/components/src/mixins';
import { radius } from '@frameio/components/src/theme/darkTheme';
import CheckIcon from '@frameio/components/src/svgs/icons/check.svg';
import DropArrowIcon from '@frameio/components/src/svgs/icons/caron.svg';

export const StyledFlex = styled(Flex)`
  cursor: pointer;
  height: ${(p) => p.theme.spacing.units(5)};
  border-radius: ${(p) => p.theme.radius.default};
  white-space: initial;
  height: ${(p) => p.theme.spacing.units(7)};
  padding: ${(p) => p.theme.spacing.small};
  &:hover {
    background-color: ${(p) => p.theme.color.coldWhite};
  }
`;

const WrapperButton = styled.button`
  ${buttonReset()};
  display: flex;
  align-items: center;

  > :first-child {
    min-width: 38px;
  }
`;

const TextContainer = styled.div`
  margin-left: ${(p) => p.theme.spacing.small};
  padding-top: 5px;
  text-align: left;
`;

const TitleLabel = styled(Flex)`
  color: black;
  font-size: 14px;
  font-weight: ${(p) => p.theme.fontWeight.bold};
  text-align: left;
  width: fit-content;
  border-radius: ${(p) => p.theme.radius.default};
  &:hover {
    background-color: ${(p) => p.theme.color.coldWhite};
  }

  // Add padding for hover state and offset with negative margin
  padding-left: ${(p) => p.theme.spacing.micro};
  margin-left: -${(p) => p.theme.spacing.micro};
`;

const SubtitleLabel = styled.div`
  color: ${(p) => p.theme.color.gray};
  ${(p) => p.theme.fontStyle.bodyS};
`;

const StyledCheckIcon = styled(CheckIcon)`
  margin-left: auto;
  padding-left: ${(p) => p.theme.spacing.tiny};
  color: ${(p) => p.theme.color.brand};
`;

const StyledDropArrowIcon = styled(DropArrowIcon)`
  color: ${(p) => p.theme.color.gray};
`;

export default function LinkAccessDropdownButton({
  mode,
  isSelected,
  hasCarat,
}) {
  let icon;
  let iconSize;
  let color;
  let titleLabel;
  let subtitleLabel;

  switch (mode) {
    case 'public':
      icon = 'globe';
      color = iconCircleColors.GREEN;
      titleLabel = 'Public';
      subtitleLabel = 'Visible to anyone on the internet with the link';
      break;
    case 'private':
      icon = 'person';
      color = iconCircleColors.ORANGE;
      titleLabel = 'Invite only';
      subtitleLabel = 'Logged in users can access';
      break;
    case 'disabled':
      icon = 'x_small';
      color = iconCircleColors.RED;
      titleLabel = 'Disabled';
      subtitleLabel = 'No one can access this link';
      break;
    default:
      break;
  }

  return (
    <StyledFlex alignCenter>
      <WrapperButton autoFocus iconSize={iconSize}>
        <IconCircle
          color={color}
          cornerRadius={radius.large}
          icon={icon}
          iconSize={iconSize}
        />
        <TextContainer>
          <TitleLabel alignCenter data-testid={`link-access-mode-${mode}`}>
            {titleLabel}
            {hasCarat && <StyledDropArrowIcon height={16} width={16} />}
          </TitleLabel>
          <SubtitleLabel>{subtitleLabel}</SubtitleLabel>
        </TextContainer>
        {isSelected && <StyledCheckIcon />}
      </WrapperButton>
    </StyledFlex>
  );
}

LinkAccessDropdownButton.defaultProps = {
  isSelected: false,
  hasCarat: false,
};

LinkAccessDropdownButton.propTypes = {
  mode: PropTypes.oneOf(['public', 'private', 'disabled']).isRequired,
  isSelected: PropTypes.bool,
  hasCarat: PropTypes.bool,
};
