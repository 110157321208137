import { connect } from 'react-redux';
import { currentAccountSelector } from 'selectors/accounts';
import { currentTeamEntitySelector } from 'selectors/teams';
import SetupAccountFlow from './SetupAccountFlow';
import { startFlow, endFlow, continueFlow } from './actions';

const mapDispatchToProps = {
  startFlow,
  endFlow,
  continueFlow,
};

function mapStateToProps(state, props) {
  const { id: accountId } = currentAccountSelector(state) || {};
  const { id: teamId } = currentTeamEntitySelector(state) || {};
  return {
    accountId,
    teamId,
    ...props,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupAccountFlow);
