import {
  call,
  cancel,
  fork,
  take,
  takeLatest,
  put,
  select,
} from 'redux-saga/effects';
import track from 'analytics';
import { MODAL } from 'components/Modal/actions';
import { PAYMENT_FLOW } from 'components/PaymentFlow/actions';
import {
  updateSubscriptionLineItem,
  fetchPlanAndSubscriptionLineItemsForModifier,
} from 'components/ManageSubscription/sagas';
import { getSubscriptionByAccount } from '@frameio/core/src/subscriptions/sagas';
import { isUpdatingSubscription } from 'components/ManageSubscription/actions';
import {
  SELECT_PLAN_FLOW,
  setPlanQuote,
} from 'components/SelectPlanFlow/actions';
import { PLAN_SUMMARY_URL } from 'components/SelectPlanFlow/SelectPlanFlow';
import { getPlanQuote } from '@frameio/core/src/invoices/services';
import { accountPlanIdSelector } from 'selectors/accounts';
import { checkoutFlowV2Enabled } from 'utils/featureFlags';
import { showErrorToast } from 'actions/toasts/index';
import {
  ADD_STORAGE_URL,
  PAYMENT_FLOW_URL,
  CONFIRM_STORAGE_ADDED_URL,
} from './AddStorageFlow';
import history from './history';
import { ADD_STORAGE_FLOW } from './actions';

function* handleFail() {
  yield put(
    showErrorToast({
      header: 'An error occurred',
    })
  );
}

export function* watchStorageFlow(accountId, trackingProps, type) {
  // Step 1: Show limit reached info screen
  yield call(history.push, ADD_STORAGE_URL);

  // Step 2: User has selected number of storage blocks they want to add
  const {
    payload: { updatedLimit, totalStorageAdded, incrementTotal },
  } = yield take(ADD_STORAGE_FLOW.CONTINUE);
  track('line-item-modal-submitted', {
    ...trackingProps,
    quantity: totalStorageAdded,
    type,
  });

  // Step 3: Take the user to the payment flow
  yield call(history.push, PAYMENT_FLOW_URL, {
    onPaymentMethodReady: () => updateSubscriptionLineItem(type, updatedLimit),
  });

  // Step 4: Payment is succesful
  yield take(PAYMENT_FLOW.REPORT_PAYMENT_SUCCESS);

  const isCheckoutFlowV2Enabled = yield select(checkoutFlowV2Enabled);

  if (isCheckoutFlowV2Enabled) {
    const selectedPlanId = yield select(accountPlanIdSelector, { accountId });

    const addOns = [{ modifier: type, quantity: incrementTotal }];

    const result = yield call(getPlanQuote, accountId, selectedPlanId, addOns);

    // set results
    yield put(setPlanQuote(result));
    // go to plan summary
    yield call(history.push, PLAN_SUMMARY_URL);

    yield take(SELECT_PLAN_FLOW.CONTINUE);

    const { success } = yield call(
      updateSubscriptionLineItem,
      type,
      updatedLimit
    );

    if (success) {
      yield call(history.push, CONFIRM_STORAGE_ADDED_URL, {
        totalStorageAdded,
        type,
      });
    } else {
      yield call(handleFail);
    }
  }

  if (!isCheckoutFlowV2Enabled) {
    // Step 5: Show confirmation screen with total GB of storage added
    yield call(history.push, CONFIRM_STORAGE_ADDED_URL, {
      totalStorageAdded,
      type,
    });
  }
  // Step 6: Get the updated subscription for the account
  yield call(getSubscriptionByAccount, accountId);
  yield put(isUpdatingSubscription(false));
}

export function* startAddStorageFlow(accountId, type, trackingProps) {
  // Setup: make sure we have access to the plan and subscription line items
  yield call(fetchPlanAndSubscriptionLineItemsForModifier, accountId, type);

  const task = yield fork(watchStorageFlow, accountId, trackingProps, type);
  track('line-item-modal-shown', {
    ...trackingProps,
    type,
  });
  yield take(MODAL.CLOSE);

  yield cancel(task);
}

export default [
  takeLatest(
    ADD_STORAGE_FLOW.START,
    ({ payload: { accountId, type, trackingProps } }) =>
      startAddStorageFlow(accountId, type, trackingProps)
  ),
];
