import React from 'react';
import styled from 'styled-components';
import Raven from 'raven-js';
import { get, flattenDeep } from 'lodash';
import {
  takeLatest,
  call,
  all,
  put,
  select,
  race,
  spawn,
} from 'redux-saga/effects';
import Flex, { FlexItem } from 'styled-flex-component';
import { v2Instance as v2Api } from '@frameio/core/src/shared/services/api';
import { getEntityFromNormalizedResponse } from '@frameio/core/src/shared/utils/entities';
import {
  ASSET as CORE_ASSET,
  batchDeleteAssets,
  createAsset,
  extendDeletionDateOfAsset,
  updateAsset,
  batchMoveAssets as batchMoveAssetsAction,
  batchCopyAssets,
  patchAssets,
} from '@frameio/core/src/assets/actions';
import { getAssetChildren, deleteAsset } from '@frameio/core/src/assets/sagas';
import { getHighestDownloadUrlForAsset } from '@frameio/core/src/assets/helpers/utils';
import {
  takeSuccessWithEntityId,
  takeFailureWithEntityId,
} from '@frameio/core/src/shared/sagas/helpers';
import {
  hydratedAssetEntitySelector,
  hydratedAssetEntitiesByAssetIdsSelector,
  assetEntitiesInclDeletedSelector,
  assetEntitySelector,
} from '@frameio/core/src/assets/selectors';
import { type as AssetType } from '@frameio/core/src/assets/helpers/constants';
import {
  daysLeftBeforeDeletedByLifecyclePolicySelector,
  teamLifeCyclePolicyForAssetSelector,
} from '@frameio/core/src/lifecyclePolicy/selectors';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';
import { projectEntityForAssetIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import FolderSVG from '@frameio/components/src/svgs/icons/folder.svg';
import PrivateFolderSVG from '@frameio/components/src/svgs/icons/private-folder.svg';

import track, { trackButtonClick } from 'analytics';
import {
  confirmDelete,
  error,
  confirm,
  prompt,
} from 'components/Dialog/SimpleDialog/sagas';
import { ASSET } from 'actions/assets';
import history from 'browserHistory';
import ConfirmDeleteAssets, {
  getConfirmHeader,
} from 'components/AssetActions/DeleteAssets/ConfirmDeleteAssets';
import DialogAssetThumb from 'components/DialogAssetThumb';
import { selectNameOnFocus } from 'components/EditableText';
import { JTFileReference, JTVersionStack } from 'core/JTDynamoObject';
import { downloadInAppIfRequired } from 'pages/ProjectContainer/DesktopApp/sagas';
import { selectAssets } from 'pages/ProjectContainer/actions';
import { showSuccessToast, showErrorToast } from 'actions/toasts';
import { currentProjectSelector } from 'selectors/projects';
import {
  permittedActionsForProjectSelector,
  permittedActionsForAssetsSelector,
} from 'selectors/permissions';
import {
  downloadUrlsAsFiles,
  downloadSerially,
  resolutionToResolutionField,
} from 'utils/downloads';
import { getProjectUrl } from 'URLs';

export const copyErrors = {
  NO_PERMISSION:
    "Ask your team's administrator to add you as a team member or grant you downloading permissions.",
  DIFFERENT_ACCOUNTS:
    'For security reasons, we no longer allow assets to be copied to other Frame.io accounts.',
};

// Use this instead of the core service to avoid normalizing the response,
// causing duplicates due to flattening the cover assets
function getAssetChildrenService(assetId, options) {
  const { page = 1, pageSize = 500 } = options;
  return v2Api.get(`/assets/${assetId}/children`, {
    page,
    page_size: pageSize,
  });
}

async function getPaginatedAssetChildren(folderId) {
  let allAssets = [];
  let totalPages;
  let page = 1;
  do {
    // eslint-disable-next-line no-await-in-loop
    await getAssetChildrenService(folderId, { page }).then(
      // eslint-disable-next-line no-loop-func
      (response) => {
        const assets = response.data || [];
        totalPages = response.headers.totalPages || 1;
        allAssets = allAssets.concat(assets);
      }
    );
    page += 1;
  } while (page <= totalPages);
  return allAssets;
}

// Recursively get all child non-folder assets from the given folder
async function getChildAssetsFromFolder(folder) {
  const assets = await getPaginatedAssetChildren(folder.id);
  return Promise.all(
    assets.map((asset) =>
      asset.type === AssetType.FOLDER ? getChildAssetsFromFolder(asset) : asset
    )
  );
}

// Add up to 10 files to the browser download queue at once, with 3-second gap
function bufferDownloadUrlsAsFiles(assetUrls) {
  const queuedUrls = assetUrls.splice(0, 10);
  downloadUrlsAsFiles(queuedUrls);
  if (assetUrls.length > 0)
    setInterval(() => bufferDownloadUrlsAsFiles(assetUrls), 3000);
}

/**
 * Downloads only the cover assets of version stacks, or files, and not folders.
 * @param {string[]} assetIds
 * @param {Boolean} downloadAll A flag when the "Download All" button was pressed in a Review Link
 */
export function* seriallyDownloadBatchFiles(assetIds, downloadAll) {
  const assets = yield select(hydratedAssetEntitiesByAssetIdsSelector, {
    assetIds,
  });
  const hasFolder = !!assets.find((asset) => asset.type === AssetType.FOLDER);
  const { isDownloadingInApp, isCanceled } = yield call(
    downloadInAppIfRequired,
    assetIds,
    null,
    hasFolder,
    downloadAll
  );
  if (isDownloadingInApp || isCanceled) return;

  // Traverse folders and collect all child asset IDs and download them
  const folders = assets.filter(({ type }) => type === AssetType.FOLDER);

  // eslint-disable-next-line no-restricted-syntax
  for (const folder of folders) {
    const response = yield call(getChildAssetsFromFolder, folder);
    const flattenedAssetUrls = flattenDeep(response)
      .map(getHighestDownloadUrlForAsset)
      .filter((url) => url);
    bufferDownloadUrlsAsFiles(flattenedAssetUrls);
  }

  // Download all top-level assets
  const allAssetUrls = assets
    .filter(({ type }) => type !== AssetType.FOLDER)
    .map(getHighestDownloadUrlForAsset)
    .filter((url) => url);
  yield call(downloadUrlsAsFiles, allAssetUrls);
}

function* trackDownloadAll(projectId, folderId) {
  yield spawn(track, 'download-all', {
    project_id: projectId,
    folder_id: folderId,
  });
}

/**
 * Download everything in the folder/project (recursively), if the total size is
 * within limits. If the total size is over the limit, provide the option to
 * download all files with the transfer app.
 */
export function* confirmDownloadAll(projectId, folderId) {
  try {
    const folder = yield select(hydratedAssetEntitySelector, {
      assetId: folderId,
    });
    yield call(
      seriallyDownloadBatchFiles,
      folder.children.map((asset) => asset.id)
    );
    yield spawn(trackDownloadAll, projectId, folderId);
  } catch (e) {
    Raven.captureException(e);
    yield put(
      showErrorToast({
        header: 'There was a problem downloading your files. Please try again.',
      })
    );
  }
}

function* trackBatchDownload(assetIds) {
  yield spawn(track, 'download', { count: assetIds.length });
}

// Get download object for asset.
function getDownloadForAsset(asset, resolution) {
  // Empty downloads will be ignored.
  if (!asset) return null;

  // We will try to download a specific resolution if it is specified.
  const resKey = resolution && resolutionToResolutionField[resolution];

  // Check the downloads.pdf field explicitly as Massdriver is determining the download logic
  // for watermerked document types. The field will be null for non-document/non-watermarked assets.
  const pdfDownloadUrl = asset.downloads?.pdf;
  if (pdfDownloadUrl) {
    return {
      url: pdfDownloadUrl,
      name: asset.name,
    };
  }

  // If we cannot resolve the resolution URL we fall back to highest available download including original.
  const url = get(
    asset,
    `downloads.${resKey}`,
    getHighestDownloadUrlForAsset(asset)
  );

  return {
    url,
    name: asset.name,
  };
}

export function* downloadAsset({ payload: { assetId, resolution } }) {
  const asset = yield select(assetEntitySelector, { assetId });

  let downloads;
  if (asset.bundle) {
    // Asset children may not exist in context of the project view.
    yield call(getAssetChildren, assetId);

    const childIds = Object.keys(asset.layout || {});
    const { isDownloadingInApp } = yield call(
      downloadInAppIfRequired,
      childIds,
      resolution
    );
    if (isDownloadingInApp) return;

    const childAssets = yield all(
      childIds.map((childAssetId) =>
        select(assetEntitySelector, { assetId: childAssetId })
      )
    );
    downloads = childAssets.map((childAsset) =>
      getDownloadForAsset(childAsset, resolution)
    );
  } else {
    const { isDownloadingInApp } = yield call(
      downloadInAppIfRequired,
      [assetId],
      resolution
    );
    if (isDownloadingInApp) return;

    downloads = [getDownloadForAsset(asset, resolution)];
  }
  yield call(downloadSerially, downloads);
}

// todo(joelkang - CORE-1201): refactor downloadBatch saga
export function* downloadBatch(assetIds) {
  yield call(seriallyDownloadBatchFiles, assetIds);
  yield spawn(trackBatchDownload, assetIds);
}

function* trackDeleteAttempt(didDelete, trackingPage, trackingPosition) {
  yield spawn(
    trackButtonClick,
    didDelete ? 'delete-assets-confirm' : 'delete-assets-cancel',
    trackingPage,
    trackingPosition
  );
}

function* trackDeleteSuccess(assetIds) {
  yield spawn(track, 'items-deleted-client', { count: assetIds.length });
}

export function* confirmDeleteAssets(assetIds, trackingPage, trackingPosition) {
  const assets = yield select(hydratedAssetEntitiesByAssetIdsSelector, {
    assetIds,
  });
  const { canDelete } = yield select(permittedActionsForAssetsSelector, {
    assetIds,
    projectId: assets[0].project_id,
  });

  if (!canDelete) {
    yield call(
      error,
      'Cannot delete assets',
      'As a collaborator, you may only delete assets that you uploaded. Please contact a team administrator to delete these assets.'
    );
    return false;
  }

  const shouldDelete = yield call(
    confirmDelete,
    getConfirmHeader(assets),
    <ConfirmDeleteAssets assets={assets} />
  );

  if (shouldDelete) {
    yield put(
      patchAssets(
        assets.map((asset) => ({
          ...asset,
          deleted_at: new Date().toISOString(),
        }))
      )
    );
    yield put(batchDeleteAssets(assets.map(({ id }) => id)));
    yield put(selectAssets([]));
  }

  yield spawn(trackDeleteAttempt, shouldDelete, trackingPage, trackingPosition);

  return shouldDelete;
}

/**
 * Opens dialog to confirm deleting an asset.
 * @param   {Object}    action - Action object.
 */
export function* confirmDeleteAsset(action) {
  const { id } = action.payload;
  const assets = yield select(hydratedAssetEntitiesByAssetIdsSelector, {
    assetIds: [id],
  });
  const asset = assets[0];

  // TODO(joel): Ideally this delegates to confirmDeleteAssets, however the player page components
  // do not currently check for whether the file is present, causing it to explode when it tries
  // to render a deleted asset. So we redirect to the dashboard first then delete the asset.
  const shouldDelete = yield call(
    confirmDelete,
    getConfirmHeader(assets),
    <ConfirmDeleteAssets assets={assets} />
  );
  if (shouldDelete) {
    const projectId = asset.project_id;
    const folderId = asset.parent_id;
    yield call(history.replace, getProjectUrl(projectId, folderId));
    const { failure } = yield call(deleteAsset, id);
    if (failure) {
      yield put(
        showErrorToast({
          header: 'Whoops, something went wrong while deleting this asset.',
        })
      );
      return;
    }
    yield spawn(trackDeleteSuccess, assets);
  }

  yield spawn(trackDeleteAttempt, shouldDelete, 'player', 'nav');
}

export function* getConfirmExtendDeletionMessage(assetIds) {
  const assetId = assetIds[0];
  const teamLifecycle = yield select(teamLifeCyclePolicyForAssetSelector, {
    assetId,
  });
  const daysLeftBeforeDeleted =
    assetIds.length > 1
      ? 0
      : yield select(daysLeftBeforeDeletedByLifecyclePolicySelector, {
          assetId,
        });

  const days = daysLeftBeforeDeleted === 1 ? 'day' : 'days';
  if (assetIds.length === 1) {
    const asset = yield select(assetEntitySelector, { assetId });
    const assetName = (asset || {}).name || 'This file';
    return (
      <span>
        <strong>{assetName}</strong> is scheduled to be deleted in{' '}
        {daysLeftBeforeDeleted} {days}. Resetting its lifecycle will keep the
        asset online for {teamLifecycle} more days.
      </span>
    );
  }
  return (
    <span>
      Resetting the files‘ lifecycle will keep the assets online for{' '}
      {teamLifecycle} more days.
    </span>
  );
}

export function* confirmExtendDeletionDate(
  assetIds,
  trackingPage,
  trackingPosition
) {
  if (!assetIds.length) return;
  const message = yield call(getConfirmExtendDeletionMessage, assetIds);
  const title = `Reset lifecyle of ${
    assetIds.length === 1 ? 'asset' : 'assets'
  }?`;
  const shouldExtendDeletionDate = yield call(confirm, title, message, {
    primaryText: 'Reset',
  });
  if (shouldExtendDeletionDate) {
    yield all(
      assetIds.map((assetId) => put(extendDeletionDateOfAsset(assetId)))
    );
    yield call(
      trackButtonClick,
      'reset-asset-lifecycle-confirm',
      trackingPage,
      trackingPosition,
      {
        item_count: assetIds.length,
      }
    );
  } else {
    yield call(
      trackButtonClick,
      'reset-asset-lifecycle-cancel',
      trackingPage,
      trackingPosition,
      {
        item_count: assetIds.length,
      }
    );
  }
}

/**
 * @param {Object} response
 */
export function* onExtendDeletionDateSuccess(response) {
  /**
   * {TODO} (yisusans) - Delete after [Dashboard 2.0] released.
   * Need to update the 3 dots menu in the popover.  Hacky yes...but how else but to update
   * the legacy 3 dots popover?
   */
  const {
    payload: { entityId },
  } = response;

  const asset = yield select(assetEntitySelector, { assetId: entityId });

  const isVersionStack = asset.type === AssetType.VERSION_STACK;

  if (isVersionStack) {
    const legacyVersionStack = JTVersionStack.find_by_id(entityId);
    if (legacyVersionStack) {
      legacyVersionStack.set_attrs({ archive_from: asset.archive_from });
    }
  } else {
    const legacyAsset = JTFileReference.find_by_id(entityId);
    if (legacyAsset) {
      legacyAsset.set_attrs({ archive_from: asset.archive_from });
    }
  }

  // Keep this toast.
  yield put(
    showSuccessToast({
      header: `Successfully reset ${asset.name}‘s lifecycle policy.`,
    })
  );
}

/**
 * Add a toast to show the asset failed to extend delete.
 */
export function* onExtendDeletionDateFailure(response) {
  // {TODO}(yisusans) - [Dashboard 2.0] - When asset exists in the store, replace JTDynamoObject.
  const {
    payload: { entityId },
  } = response;

  const versionStack = JTVersionStack.find_by_id(entityId);
  const asset = JTFileReference.find_by_id(entityId);

  let assetName;
  if (versionStack) {
    assetName = 'version stack';
  } else {
    assetName = asset.name();
  }

  yield put(
    showErrorToast({
      header: `Resetting the ${assetName} lifecycle method was not successful.`,
    })
  );
}

/**
 * After a successful undelete - reload the legacy dashboard to show the toast
 * @param {Object} action - API action.
 */
export function* onUndeleteSuccess(action) {
  const {
    payload: { entityId: assetId },
  } = action;

  const assetEntity = yield select(assetEntitySelector, { assetId });
  const project = yield select(projectEntityForAssetIdSelector, { assetId });
  const currentProject = yield select(currentProjectSelector);

  if (currentProject.id !== project.id) {
    yield call(history.push, getProjectUrl(project.id));
  }

  yield put(
    showSuccessToast({
      header: `Successfully restored ${assetEntity.name} to ${project.name}`,
    })
  );
}

export function* onUndeleteFailure(response) {
  const {
    payload: { entityId: assetId },
  } = response;
  const assetEntities = yield select(assetEntitiesInclDeletedSelector);
  const assetEntity = assetEntities[assetId];

  yield put(
    showErrorToast({
      header: `Restoring ${assetEntity.name} was not successful.`,
    })
  );
}

function renderNamePromptInput(thumb) {
  return (input) => (
    <Flex full alignCenter>
      <FlexItem noShrink grow={0}>
        {thumb}
      </FlexItem>
      <FlexItem grow={1}>
        <label htmlFor="asset-name-input">
          {React.cloneElement(input, {
            onFocus: selectNameOnFocus,
            id: 'asset-name-input',
            autoFocus: true,
            maxLength: 255,
          })}
        </label>
      </FlexItem>
    </Flex>
  );
}

export function* promptRenameAsset(assetId, trackingPage, trackingPosition) {
  const asset = yield select(hydratedAssetEntitySelector, { assetId });
  const isFolder = asset.type === AssetType.FOLDER;
  const renderInput = yield call(
    renderNamePromptInput,
    <DialogAssetThumb asset={asset} />
  );
  const name = yield call(
    prompt,
    isFolder ? 'Rename folder' : 'Rename file',
    renderInput,
    asset.name
  );

  if (name) {
    yield put(updateAsset(assetId, { name }));
  }

  yield spawn(
    trackButtonClick,
    name ? 'rename-asset-confirm' : 'rename-asset-cancel',
    trackingPage,
    trackingPosition
  );
}

/**
 * Folder name is set to default if no name passed.
 *
 */
export const DEFAULT_FOLDER_NAME = 'Untitled Folder';

export function* createNewFolder(
  parentFolderId,
  isPrivate,
  index,
  name = DEFAULT_FOLDER_NAME
) {
  const data = {
    type: AssetType.FOLDER,
    name,
    private: isPrivate,
    index,
  };
  yield put(createAsset(parentFolderId, data));
}

const FolderIcon = styled(FolderSVG).attrs(() => ({
  width: 32,
}))`
  margin-left: ${(p) => p.theme.spacing.micro};
  margin-right: ${(p) => p.theme.spacing.small};
`;
const PrivateFolderIcon = FolderIcon.withComponent(PrivateFolderSVG);

export function* promptNewFolderName(parentFolderId, isPrivate, index) {
  const Icon = isPrivate ? PrivateFolderIcon : FolderIcon;
  const renderInput = yield call(renderNamePromptInput, <Icon />);
  const name = yield call(
    prompt,
    isPrivate ? 'New private folder' : 'New folder',
    renderInput,
    DEFAULT_FOLDER_NAME
  );
  if (!name) return;

  yield call(createNewFolder, parentFolderId, isPrivate, index, name);
}

export function* copyToProject(assetIds, projectId) {
  const currentProject = yield select(currentProjectSelector);
  const destinationProject = yield select(projectEntitySelector, { projectId });
  const { canCopyToProject } = yield select(
    permittedActionsForProjectSelector,
    {
      projectId: currentProject.id,
    }
  );

  if (!canCopyToProject) {
    // Copying assets between projects should adhere to the same permissioms as
    // downloading assets as it's essentially a work around here.
    yield call(error, 'Whoops', copyErrors.NO_PERMISSION);
    return assetIds;
  }

  const { root_asset_id: folderId, name } = destinationProject;
  const action = batchCopyAssets(assetIds, folderId);
  yield put(action);
  const { success } = yield race({
    success: takeSuccessWithEntityId(action, assetIds, folderId),
    failure: takeFailureWithEntityId(action, assetIds, folderId),
  });

  let failedAssetIds;

  if (success) {
    const {
      payload: {
        response: { error: responseError },
      },
    } = success;
    failedAssetIds = Object.keys(responseError);
  } else {
    failedAssetIds = assetIds;
  }

  const successCount = assetIds.length - failedAssetIds.length;

  if (!successCount) {
    yield put(
      showErrorToast({
        header: 'Whoops, something went wrong while copying these assets.',
        subHeader: 'Please try again.',
      })
    );
    return assetIds;
  }

  if (failedAssetIds.length) {
    const assets = yield select(hydratedAssetEntitiesByAssetIdsSelector, {
      assetIds: failedAssetIds,
    });

    yield all(
      assets.map((asset) =>
        put(
          showErrorToast({
            header: (
              <span>
                Whoops, something went wrong while copying{' '}
                <strong>
                  {asset.type === AssetType.VERSION_STACK
                    ? asset.cover_asset.name
                    : asset.name}
                </strong>
                .
              </span>
            ),
            subHeader: 'Please try again.',
          })
        )
      )
    );
  }

  yield put(
    showSuccessToast({
      header:
        successCount === 1 ? (
          <span>
            Copied 1 item to <strong>{name}</strong>
          </span>
        ) : (
          <span>
            Copied {successCount} items to <strong>{name}</strong>
          </span>
        ),
    })
  );
  return failedAssetIds;
}

/**
 * Moves a set of assets into a folder and refresh the folder.
 * @param {string[]} assetIds - The ids of the assets to move.
 * @param {string} folderId - The id of the folder to move them into.
 * @returns {string[]} Returns an array of ids for which the move failed.
 */
export function* batchMoveAssets(assetIds, folderId) {
  const action = batchMoveAssetsAction(assetIds, folderId);
  yield put(action);
  const { success } = yield race({
    success: takeSuccessWithEntityId(action, assetIds, folderId),
    failure: takeFailureWithEntityId(action, assetIds, folderId),
  });

  let failedAssetIds;

  if (success) {
    const {
      payload: {
        response: { error: responseError },
      },
    } = success;
    failedAssetIds = Object.keys(responseError);
  } else {
    failedAssetIds = assetIds;
  }

  const successCount = assetIds.length - failedAssetIds.length;

  if (!successCount) {
    yield put(
      showErrorToast({
        header: 'Whoops, something went wrong while moving these assets.',
        subHeader: 'Please try again.',
      })
    );
    return assetIds;
  }

  if (failedAssetIds.length) {
    const failedAssets = yield select(hydratedAssetEntitiesByAssetIdsSelector, {
      assetIds: failedAssetIds,
    });

    yield all(
      failedAssets.map((asset) =>
        put(
          showErrorToast({
            header: (
              <span>
                Whoops, something went wrong while moving{' '}
                <strong>
                  {asset.type === AssetType.VERSION_STACK
                    ? asset.cover_asset.name
                    : asset.name}
                </strong>
                .
              </span>
            ),
            subHeader: 'Please try again.',
            autoCloseDelay: 5000,
          })
        )
      )
    );
  }
  return failedAssetIds;
}

function* trackFolderCreated(response) {
  // "private" is a reserved keyword
  const { private: isPrivate, type } = getEntityFromNormalizedResponse(
    response
  );
  if (type === AssetType.FOLDER) {
    yield spawn(track, 'create-folder', { private: isPrivate });
  }
}

/**
 * Typically, this only fires a single tracking event per batch action because
 * the UI only allows a one action at a time, even though the implementation
 * here handles multiple types of updates.
 */
function* trackBatchUpdate(updates) {
  function add(accum, name) {
    const count = (accum[name] || 0) + 1;
    return {
      ...accum,
      [name]: count,
    };
  }

  const eventNameToCount = updates.reduce((accum, asset) => {
    // "private" is a reserved keyword
    const { private: isPrivate } = asset;
    if (isPrivate === true) {
      return add(accum, 'made-private');
    }
    if (isPrivate === false) {
      return add(accum, 'made-unprivate');
    }
    return accum;
  }, {});

  const calls = Object.keys(eventNameToCount).map((eventName) => {
    const count = eventNameToCount[eventName];
    return spawn(track, eventName, { count });
  });
  yield all(calls);
}

function* trackVersionStackCreated(response) {
  const { id: assetId } = getEntityFromNormalizedResponse(response);
  const {
    cover_asset: { filetype },
  } = yield select(hydratedAssetEntitySelector, { assetId });
  yield spawn(track, 'media-version-created', { filetype });
}

export const testExports = {
  getChildAssetsFromFolder,
  trackDeleteSuccess,
  trackBatchDownload,
  trackBatchUpdate,
  trackDeleteAttempt,
  trackDownloadAll,
  trackFolderCreated,
  trackVersionStackCreated,
  renderNamePromptInput,
};

export default [
  takeLatest(ASSET.CONFIRM_DELETE, confirmDeleteAsset),
  takeLatest(
    ASSET.CONFIRM_EXTEND_DELETION_DATE,
    ({ payload: { assetIds, trackingPage, trackingPosition } }) =>
      confirmExtendDeletionDate(assetIds, trackingPage, trackingPosition)
  ),
  takeLatest(
    ASSET.CONFIRM_DELETE_BATCH,
    ({ payload: { assetIds, trackingPage, trackingPosition } }) =>
      confirmDeleteAssets(assetIds, trackingPage, trackingPosition)
  ),
  takeLatest(
    ASSET.CONFIRM_DOWNLOAD_ALL,
    ({ payload: { projectId, folderId } }) =>
      confirmDownloadAll(projectId, folderId)
  ),
  takeLatest(ASSET.DOWNLOAD_BATCH, ({ payload: { assetIds } }) =>
    downloadBatch(assetIds)
  ),
  takeLatest(ASSET.DOWNLOAD, downloadAsset),
  takeLatest(
    ASSET.BATCH_COPY_TO_PROJECT,
    ({ payload: { assetIds, projectId } }) => copyToProject(assetIds, projectId)
  ),
  takeLatest(ASSET.BATCH_MOVE, ({ payload: { assetIds, folderId } }) =>
    batchMoveAssets(assetIds, folderId)
  ),
  takeLatest(CORE_ASSET.BATCH_DELETE.BASE, ({ payload: { assetIds } }) =>
    trackDeleteSuccess(assetIds)
  ),
  takeLatest(CORE_ASSET.BATCH_UPDATE.BASE, ({ payload: { updates } }) =>
    trackBatchUpdate(updates)
  ),
  takeLatest(CORE_ASSET.CREATE.SUCCESS, ({ payload: { response } }) =>
    trackFolderCreated(response)
  ),
  takeLatest(
    CORE_ASSET.EXTEND_DELETION_DATE.SUCCESS,
    onExtendDeletionDateSuccess
  ),
  takeLatest(
    CORE_ASSET.EXTEND_DELETION_DATE.FAILURE,
    onExtendDeletionDateFailure
  ),
  takeLatest(CORE_ASSET.UNDELETE.SUCCESS, onUndeleteSuccess),
  takeLatest(CORE_ASSET.UNDELETE.FAILURE, onUndeleteFailure),
  takeLatest(CORE_ASSET.UNVERSION.BASE, () => {
    track('unversion');
  }),
  takeLatest(CORE_ASSET.VERSION.SUCCESS, ({ payload: { response } }) =>
    trackVersionStackCreated(response)
  ),
  takeLatest(
    ASSET.PROMPT_RENAME,
    ({ payload: { assetId, trackingPage, trackingPosition } }) =>
      promptRenameAsset(assetId, trackingPage, trackingPosition)
  ),
  takeLatest(
    ASSET.CREATE_NEW_FOLDER,
    ({ payload: { parentFolderId, isPrivate, index, name } }) =>
      createNewFolder(parentFolderId, isPrivate, index, name)
  ),
  takeLatest(
    ASSET.PROMPT_NEW_FOLDER_NAME,
    ({ payload: { parentFolderId, isPrivate, index } }) =>
      promptNewFolderName(parentFolderId, isPrivate, index)
  ),
];
