import React, { memo } from 'react';
import Container from '../shared/Container';
import Input from '../shared/Input';
import Title from '../shared/Title';

const TaxId = ({ handleBlur, handleChange, valueAddedTax }) => {
  const placeHolderText = valueAddedTax || 'Enter Tax ID Number';
  return (
    <>
      <Title>Tax Id</Title>
      <Container marginBottom>
        <Input
          name="taxId"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeHolderText}
        />
      </Container>
    </>
  );
};

export default memo(TaxId);
