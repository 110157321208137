import { isEmpty } from 'lodash';
import { currentAccountEntitySelector } from 'selectors/accounts';

export const savedAddressSelector = (state) =>
  state.creditCardFormElements.address;

export const billingAddressForAccountSelector = (state) => {
  const account = currentAccountEntitySelector(state);
  if (!account) return {};
  const address = account?.address;

  // Address entered in the credit card form. We save it so the User doesn't
  // need to re-entered it when re-opening the form or when going from the
  // collapsed to the extended version of the form.
  const savedAddress = savedAddressSelector(state);

  if (isEmpty(savedAddress)) {
    return address;
  }

  return savedAddress;
};

export const saveTaxIdSelector = (state) => {
  return state?.creditCardFormElements?.taxId;
};
