import React from 'react';
import V4MigrationModal from './V4MigrationModal';
import V4MigrationModalVariantA from './V4MigrationModalVariantA';

const MigrationModal = ({ isV4MigrationModalVariantAEnabled, ...props }) => {
  if (isV4MigrationModalVariantAEnabled) {
    return <V4MigrationModalVariantA {...props} />;
  }

  return <V4MigrationModal {...props} />;
};

export default MigrationModal;
