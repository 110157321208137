import {
  STORAGE_LIMIT,
  ARCHIVED_STORAGE_LIMIT,
  USER_LIMIT,
} from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';

const Modifier = {
  STORAGE_LIMIT,
  ARCHIVED_STORAGE_LIMIT,
  USER_LIMIT,
};

export const storageModifers = new Set([
  Modifier.STORAGE_LIMIT,
  Modifier.ARCHIVED_STORAGE_LIMIT,
]);

export const addOnLabel = {
  [Modifier.ARCHIVED_STORAGE_LIMIT]: 'Archival storage',
  [Modifier.STORAGE_LIMIT]: 'Storage',
  [Modifier.USER_LIMIT]: 'User',
};

export const periodText = {
  MONTHLY: 'mo.',
  SEMIANNUALLY: 'six months',
  YEARLY: 'yr.',
};

export const modifierSortOrderIndices = {
  plan: 0,
  user_limit: 1,
  storage_limit: 2,
  archived_storage_limit: 3,
};
