/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { forOwn, get, partition, sortBy } from 'lodash';

import { accountEntitiesSelector } from '@frameio/core/src/accounts/selectors';

/**
 * Gets account entities that have teams
 * @returns {Object[]} - Array of account entities.
 */
export const accountsWithTeamsSelector = createSelector(
  accountEntitiesSelector,
  (accountEntities) => {
    const accountsWithTeams = [];
    forOwn(accountEntities, (account) => {
      /*
        NOTE(CORE-1375): We are explicitly filtering out all accounts with no teams --
        including the account associated with a given user’s `account_id` -- from being
        displayed in the Account Switcher. A separate flow/CTA unrelated to the Account
        Switcher will prompt the user to setup their own account as needed.
      */
      if (account.team_count > 0) accountsWithTeams.push(account);
    });
    return accountsWithTeams;
  }
);

/**
 * Gets an array of accounts sorted by display name
 * @param {Object} - Redux store state.
 * @returns {Object[]} - Array of account objects.
 */
export const accountsSortedByNameSelector = createSelector(
  accountsWithTeamsSelector,
  (accounts = []) => {
    const [accountsWithNames, accountsWithoutNames] = partition(
      accounts,
      (account) => account.display_name
    );
    /*
      Accounts without a display name defined should be sorted to
      the end of the returned array.
    */
    const sortedAccountsWithNames = sortBy(accountsWithNames, [
      (account) => account.display_name.toLowerCase(),
    ]);

    return [...sortedAccountsWithNames, ...accountsWithoutNames];
  }
);

/**
 * Gets an array of post-sort account ids to be rendered in the Account Switcher.
 * @param {Object} - Redux store state.
 * @returns {string[]} - Array of account ids.
 */
export const accountIdsForAccountSwitcherSelector = createSelector(
  accountsSortedByNameSelector,
  (accounts) => accounts.map((account) => account.id)
);

/**
 * Gets user versions to be rendered in the Account Switcher.
 * @param {Object} - Redux store state.
 * @returns {string[]} - Array of user version numbers
 */
export const versionForAccountSwitcherSelector = createSelector(
  accountsSortedByNameSelector,
  (accounts) => accounts.map((account) => account.version)
);

/**
 * Helper function to find the first Team entity with at least one project
 * belonging to a given Account
 * @param {String} accountId - Account id
 * @param {Array} teamsWithProjects - Array of Team entities
 * @returns {Object} Team entity
 */
export function getFirstTeamWithProjects(accountId, teamsWithProjects) {
  return teamsWithProjects.find(
    (team) => team.account_id === accountId && get(team, 'projects.0.id')
  );
}
