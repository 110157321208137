import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { writeText } from 'clipboard-polyfill';
import {
  BasicTextField,
  StaticColorIcon,
  DynamicColorIcon,
  PrivateSwitch,
  BigSwitch,
} from '@frameio/components';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import ClockIcon from '@frameio/components/src/svgs/icons/clock.svg';
import { ENTER } from 'utils/keyCodes';
import ProjectFormSubheader from 'components/ProjectForms/ProjectFormSubheader';
import ModalHeader from 'components/Modal/ModalHeader';
import RowWithSwitch from 'components/Modal/RowWithSwitch';
import 'styles/_modal.scss';
import { normalizeV2ProjectData, getPrivacySwitchText } from '../utils';
import EditProjectFooter from './EditProjectFooter';
import RestrictedEditProjectFooter from './RestrictedEditProjectFooter';

/* eslint-disable react/jsx-no-bind */

/**
 * In case of fields changing, make corresponding changes to Edit Restricted
 *  Project & Create Project forms.
 */
class EditProjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormValid: true,
      isProjectShared: this.props.isProjectShared,
      isPrivate: this.props.isPrivate,
      projectName: this.props.projectName,
      hasSlackNotificationsEnabled: this.props.hasSlackNotificationsEnabled,
      canCollaboratorDownload: this.props.canCollaboratorDownload,
      canCollaboratorInvite: this.props.canCollaboratorInvite,
      canCollaboratorShare: this.props.canCollaboratorShare,
      canEmailForNewCommentMe: this.props.canEmailForNewCommentMe,
      canEmailForNewVideoMe: this.props.canEmailForNewVideoMe,
      canEmailForNewPersonMe: this.props.canEmailForNewPersonMe,
      canEmailForNewCommentEveryone: this.props.canEmailForNewCommentEveryone,
      canEmailForNewVideoEveryone: this.props.canEmailForNewVideoEveryone,
      canEmailForNewPersonEveryone: this.props.canEmailForNewPersonEveryone,
      canEmailForUpdatedLabelEveryone: this.props
        .canEmailForUpdatedLabelEveryone,
      canEmailForUpdatedLabelMe: this.props.canEmailForUpdatedLabelMe,
      c2cConnectionsEnabled: this.props.c2cConnectionsEnabled,
      enableLifeCyclePolicy: this.props.enableLifeCyclePolicy,
      teamId: this.props.teamId,
    };
  }

  /**
   * Focus on the modal to close the popover.
   */
  componentDidMount() {
    this.props.isRestricted && this.bodyContainer.focus();
  }

  /**
   * Sets the project name in state.
   * @param {string} value - The project name.
   */
  onProjectNameChange = (value) => {
    this.setState({ projectName: value });
  };

  /**
   * @param   {SyntheticEvent} evt - Keydown event.
   */
  onEnterSubmit = (evt) => {
    if (evt.key === ENTER) {
      this.onClickSaveProject();
    }
  };

  /**
   * Sets the the value of the switch on change in state.
   * @param {string} switchName - The name of the switch changing.
   */
  onSwitchChange = (switchName) => {
    this.setState({ [switchName]: !this.state[switchName] });
  };

  /**
   * Sets slack switch.
   */
  onSlackSwitchChange = () => {
    const isEnabled = !this.state.hasSlackNotificationsEnabled;
    this.setState({ hasSlackNotificationsEnabled: isEnabled });

    const { authOnSlack, hasSlackWebhook, projectId, teamId } = this.props;

    // Pass projectId to reopen the edit project settings modal; pass in
    // teamId for `slack_responses/enable` call
    if (isEnabled && !hasSlackWebhook) {
      authOnSlack({ projectId, teamId });
    }
  };

  /**
   * Dispatch to delete project.
   */
  onClickDeleteProject = () => {
    const { confirmDeleteProject, projectId, projectName } = this.props;
    confirmDeleteProject(projectId, projectName);
  };

  /**
   * Dispatch to update project.
   */
  onClickSaveProject = () => {
    if (!this.state.projectName) {
      this.bodyContainer.scrollTop = 0;
    } else {
      const { updateProject, projectId } = this.props;
      const formData = normalizeV2ProjectData(this.state);
      updateProject(projectId, formData);
    }
  };

  /**
   * Shows the input for sharing.
   * @returns {ReactElement} - BasicTextField input to show link.
   */
  getSharingLink() {
    const { projectShareLink, showSuccessToast } = this.props;
    return (
      <TextInput
        value={projectShareLink}
        autoFocus
        truncate
        readOnly
        onFocus={(e) => e.currentTarget.select()}
        onClick={() => {
          writeText(projectShareLink);
          showSuccessToast({ header: 'Link copied to clipboard!' });
        }}
      />
    );
  }

  /**
   * @returns {ReactElement} - Footer for Edit Project form.  Different permissions
   * for a restricted vs non-restricted edit project form.
   */
  getFooter = () => {
    if (this.props.isRestricted) {
      return (
        <RestrictedEditProjectFooter
          onSaveButtonClick={this.onClickSaveProject}
          isWaiting={this.props.isFetching}
        />
      );
    }
    return (
      <EditProjectFooter
        onSaveButtonClick={this.onClickSaveProject}
        isWaiting={this.props.isFetching}
        onDeleteButtonClick={this.onClickDeleteProject}
      />
    );
  };

  render() {
    const privacyTooltipText = this.props.isPrivacyDisabled
      ? 'Only the project creator can edit the privacy.'
      : '';
    const {
      isRestricted,
      closeModal,
      canIntegrateSlack,
      canToggleDownloads,
      canToggleAutomaticallyDeletingAssets,
      canToggleInviteCollaborators,
      canToggleSharePresentations,
      canUsePrivateProjects,
      canUseC2CConnections,
      assetLifecyclePolicy,
      newProjectInviteLinksEnabled,
    } = this.props;

    const canToggleSlackNotifications =
      !this.state.isPrivate && canIntegrateSlack;

    const slackTooltipText = this.state.isPrivate
      ? 'Slack integrations are disabled for private projects.'
      : "You don't have permission to perform this action. Please contact your admin.";

    // each of the collaborator permissions can be (in)visible based on settings.
    // only show the subheader if any of those options are visible
    const shouldShowPermissionsHeader =
      canToggleDownloads ||
      canToggleInviteCollaborators ||
      canToggleSharePresentations;

    const { isPrivate } = this.state;

    const { privacyHeaderText, privacySubHeaderText } = getPrivacySwitchText(
      isPrivate
    );

    return (
      <div
        className="font-regular fio-black relative w-100 h-100 button-reset"
        onKeyDown={this.onEnterSubmit}
        role="button"
        tabIndex="-1"
        ref={(bodyContainer) => {
          this.bodyContainer = bodyContainer;
        }}
      >
        <ModalHeader text="Edit Project" onClickClose={closeModal} />
        <div
          className="relative vh-100 form-modal-height-ns form-modal-width-ns overflow-y-scroll"
          ref={(bodyContainer) => {
            this.bodyContainer = bodyContainer;
          }}
        >
          <div className="modal-body-spacing">
            <div className="pv3-5 ph4 bb b--fio-gray">
              {!this.state.isFormValid && (
                <div className="red tc mb3"> Project Name is Required.</div>
              )}
              <BasicTextField
                autoFocus
                defaultValue={this.state.projectName}
                onChange={this.onProjectNameChange}
                validationFunctions={[(val) => val.length > 0]}
                runValidation={this.state.isFormValid}
                isDisabled={isRestricted}
                tabIndex={0}
              />
            </div>
            <div className="bg-fio-lightest-gray">
              {!isRestricted && (
                <>
                  {canUsePrivateProjects && (
                    <RowWithSwitch
                      data-test-id="private-project-switch"
                      text={privacyHeaderText}
                      subtext={privacySubHeaderText}
                      switchComponent={
                        <PrivateSwitch
                          onChange={() => {
                            if (!this.state.isPrivate) {
                              /* [BUGS-2275] - When the Project is set to
                              Private, we turn off the Slack Notifications by
                              default. */
                              this.setState({
                                hasSlackNotificationsEnabled: false,
                              });
                            }
                            this.onSwitchChange('isPrivate');
                          }}
                          isOn={!this.state.isPrivate}
                        />
                      }
                      icon={
                        <DynamicColorIcon
                          className="svg-fio-mid-gray"
                          kind={this.state.isPrivate ? 'lock' : 'globe'}
                          size={22}
                        />
                      }
                      isDisabled={this.props.isPrivacyDisabled}
                      tooltipText={privacyTooltipText}
                      hasBorder
                    />
                  )}
                  {canUseC2CConnections && (
                    <RowWithSwitch
                      data-test-id="c2c-connections-switch"
                      text="C2C Connections"
                      subtext="Enable connections to upload media"
                      switchComponent={
                        <BigSwitch
                          onChange={this.onSwitchChange.bind(
                            this,
                            'c2cConnectionsEnabled'
                          )}
                          isOn={this.state.c2cConnectionsEnabled}
                        />
                      }
                      icon={
                        <DynamicColorIcon
                          className="svg-fio-mid-gray"
                          kind="connected"
                          size={22}
                        />
                      }
                      hasBorder
                    />
                  )}

                  <RowWithSwitch
                    data-test-id="slack"
                    text="Enable Slack Notifications"
                    switchComponent={
                      <BigSwitch
                        onChange={() =>
                          canToggleSlackNotifications
                            ? this.onSlackSwitchChange()
                            : noop
                        }
                        isOn={
                          !this.state.isPrivate &&
                          this.state.hasSlackNotificationsEnabled
                        }
                      />
                    }
                    icon={<StaticColorIcon kind="slack" size={22} />}
                    hasBorder
                    isDisabled={!canToggleSlackNotifications}
                    tooltipText={
                      !canToggleSlackNotifications ? slackTooltipText : ''
                    }
                  />

                  {!newProjectInviteLinksEnabled && (
                    <RowWithSwitch
                      data-test-id="project-sharing"
                      text="Project Sharing"
                      switchComponent={
                        <BigSwitch
                          onChange={this.onSwitchChange.bind(
                            this,
                            'isProjectShared'
                          )}
                          isOn={this.state.isProjectShared}
                        />
                      }
                      icon={
                        <DynamicColorIcon
                          className="svg-fio-mid-gray"
                          kind="people"
                          size={22}
                        />
                      }
                      subtext="Sharing a project will make it joinable via a unique URL."
                      expansionPack={
                        this.state.isProjectShared && this.getSharingLink()
                      }
                      hasBorder={!!canToggleAutomaticallyDeletingAssets}
                    />
                  )}
                  {canToggleAutomaticallyDeletingAssets && (
                    <RowWithSwitch
                      text="Enable Lifecycle Policy"
                      subtext={`Assets in this project will be deleted ${assetLifecyclePolicy} days after their upload date`}
                      switchComponent={
                        <BigSwitch
                          isOn={this.state.enableLifeCyclePolicy}
                          onChange={this.onSwitchChange.bind(
                            this,
                            'enableLifeCyclePolicy'
                          )}
                        />
                      }
                      // hardcoding color value because legacy color is not in theme
                      icon={
                        <ClockIcon width={24} height={24} color="#A2A9BA" />
                      }
                    />
                  )}
                  {shouldShowPermissionsHeader && (
                    <ProjectFormSubheader text="Collaborator Permissions" />
                  )}
                  {canToggleDownloads && (
                    <RowWithSwitch
                      data-test-id="can-download"
                      text="Can Download Files"
                      switchComponent={
                        <BigSwitch
                          onChange={this.onSwitchChange.bind(
                            this,
                            'canCollaboratorDownload'
                          )}
                          isOn={this.state.canCollaboratorDownload}
                        />
                      }
                      hasBorder
                    />
                  )}
                  {canToggleInviteCollaborators && (
                    <RowWithSwitch
                      text="Can Invite Collaborators"
                      switchComponent={
                        <BigSwitch
                          onChange={this.onSwitchChange.bind(
                            this,
                            'canCollaboratorInvite'
                          )}
                          isOn={this.state.canCollaboratorInvite}
                        />
                      }
                      hasBorder
                    />
                  )}
                  {canToggleSharePresentations && (
                    <RowWithSwitch
                      text="Can Share Presentations"
                      switchComponent={
                        <BigSwitch
                          onChange={this.onSwitchChange.bind(
                            this,
                            'canCollaboratorShare'
                          )}
                          isOn={this.state.canCollaboratorShare}
                        />
                      }
                    />
                  )}
                </>
              )}
              <ProjectFormSubheader text="My Email and iOS Push Notifications" />
              <RowWithSwitch
                text="New Comment"
                switchComponent={
                  <BigSwitch
                    onChange={this.onSwitchChange.bind(
                      this,
                      'canEmailForNewCommentMe'
                    )}
                    isOn={this.state.canEmailForNewCommentMe}
                  />
                }
                hasBorder
              />
              <RowWithSwitch
                text="New Media Uploads"
                switchComponent={
                  <BigSwitch
                    onChange={this.onSwitchChange.bind(
                      this,
                      'canEmailForNewVideoMe'
                    )}
                    isOn={this.state.canEmailForNewVideoMe}
                  />
                }
                hasBorder
              />
              <RowWithSwitch
                text="New Person Joins"
                switchComponent={
                  <BigSwitch
                    onChange={this.onSwitchChange.bind(
                      this,
                      'canEmailForNewPersonMe'
                    )}
                    isOn={this.state.canEmailForNewPersonMe}
                  />
                }
                hasBorder
              />
              <RowWithSwitch
                text="Media Status Updated"
                switchComponent={
                  <BigSwitch
                    onChange={this.onSwitchChange.bind(
                      this,
                      'canEmailForUpdatedLabelMe'
                    )}
                    isOn={this.state.canEmailForUpdatedLabelMe}
                  />
                }
              />

              {!isRestricted && (
                <div>
                  <ProjectFormSubheader text="Default Email and iOS Push Notifications for New Project Members" />
                  <RowWithSwitch
                    className="ee-new-comment"
                    text="New Comment"
                    switchComponent={
                      <BigSwitch
                        onChange={this.onSwitchChange.bind(
                          this,
                          'canEmailForNewCommentEveryone'
                        )}
                        isOn={this.state.canEmailForNewCommentEveryone}
                      />
                    }
                    hasBorder
                  />
                  <RowWithSwitch
                    className="ee-new-media-uploads"
                    text="New Media Uploads"
                    switchComponent={
                      <BigSwitch
                        onChange={this.onSwitchChange.bind(
                          this,
                          'canEmailForNewVideoEveryone'
                        )}
                        isOn={this.state.canEmailForNewVideoEveryone}
                      />
                    }
                    hasBorder
                  />
                  <RowWithSwitch
                    className="ee-new-person-joins"
                    text="New Person Joins"
                    switchComponent={
                      <BigSwitch
                        onChange={this.onSwitchChange.bind(
                          this,
                          'canEmailForNewPersonEveryone'
                        )}
                        isOn={this.state.canEmailForNewPersonEveryone}
                      />
                    }
                    hasBorder
                  />
                  <RowWithSwitch
                    text="Media Status Updated"
                    switchComponent={
                      <BigSwitch
                        onChange={this.onSwitchChange.bind(
                          this,
                          'canEmailForUpdatedLabelEveryone'
                        )}
                        isOn={this.state.canEmailForUpdatedLabelEveryone}
                      />
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {this.getFooter()}
      </div>
    );
  }
}

EditProjectForm.defaultProps = {
  isFetching: false,
  hasSlackWebhook: false,

  isRestricted: false,
  enableLifeCyclePolicy: false,
  /**
   * Toggles the asset lifecycle method on the project.
   */
  canToggleAutomaticallyDeletingAssets: false,
  assetLifecyclePolicy: 0,
};

EditProjectForm.propTypes = {
  projectId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  projectShareLink: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  isPrivacyDisabled: PropTypes.bool.isRequired,
  isProjectShared: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  hasSlackNotificationsEnabled: PropTypes.bool.isRequired,
  canCollaboratorDownload: PropTypes.bool.isRequired,
  canCollaboratorInvite: PropTypes.bool.isRequired,
  canCollaboratorShare: PropTypes.bool.isRequired,
  canEmailForNewCommentMe: PropTypes.bool.isRequired,
  canEmailForNewVideoMe: PropTypes.bool.isRequired,
  canEmailForNewPersonMe: PropTypes.bool.isRequired,
  canEmailForNewCommentEveryone: PropTypes.bool.isRequired,
  canEmailForNewVideoEveryone: PropTypes.bool.isRequired,
  canEmailForNewPersonEveryone: PropTypes.bool.isRequired,
  canEmailForUpdatedLabelEveryone: PropTypes.bool.isRequired,
  canEmailForUpdatedLabelMe: PropTypes.bool.isRequired,
  canToggleDownloads: PropTypes.bool.isRequired,
  hasSlackWebhook: PropTypes.bool,
  canToggleInviteCollaborators: PropTypes.bool.isRequired,
  canToggleSharePresentations: PropTypes.bool.isRequired,
  isRestricted: PropTypes.bool,
  enableLifeCyclePolicy: PropTypes.bool,
  /**
   * Toggles the asset lifecycle method on the project.
   */
  canToggleAutomaticallyDeletingAssets: PropTypes.bool,
  assetLifecyclePolicy: PropTypes.number,
  authOnSlack: PropTypes.func.isRequired,
  confirmDeleteProject: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  showSuccessToast: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default EditProjectForm;
