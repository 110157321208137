import { connect } from 'react-redux';
import { status } from '@frameio/core/src/shared/reducers/factories';
import { getCardsByAccount } from '@frameio/core/src/cards/actions';
import { subscriptionEntityByAccountIdSelector } from 'selectors/subscriptions';
import convertCountdownToDays from 'utils/plans/convertCountdownToDays';
import {
  currentAccountSelector,
  isAccountEnterpriseSelector,
} from 'selectors/accounts';
import {
  firstCardEntityByAccountIdSelector,
  cardsByAccountFetchStatusSelector,
} from '@frameio/core/src/cards/selectors';
import PlanUpgradeCTA from 'components/AccountCTA/PlanUpgradeCTA/PlanUpgradeCTA';
import {
  addPayment,
  upgradeAccount,
} from 'components/AccountCTA/PlanUpgradeCTA/actions';
import { checkoutFlowV2Enabled } from 'utils/featureFlags';

const mapStateToProps = (state) => {
  const accountId = currentAccountSelector(state).id;
  const cardsFetchStatus = cardsByAccountFetchStatusSelector(state)[accountId];
  const hasFetchedCards = [status.SUCCESS, status.FAILURE].includes(
    cardsFetchStatus
  );
  const subscription =
    subscriptionEntityByAccountIdSelector(state, accountId) || {};
  const trialExpiration = subscription.promotion_expires_at;
  const countdown = convertCountdownToDays(trialExpiration);
  const onTrial = !!subscription.on_trial;
  const card = firstCardEntityByAccountIdSelector(state, { accountId });
  const hasCard = !!card;
  const isCheckoutFlowV2Enabled = checkoutFlowV2Enabled(state);

  return {
    accountId,
    countdown,
    hasCard,
    hasFetchedCards,
    isEnterprise: isAccountEnterpriseSelector(state),
    onTrial,
    isCheckoutFlowV2Enabled,
  };
};

const mapDispatchToProps = {
  getCardsByAccount,
  addPayment,
  upgradeAccount,
};

export const testExports = { mapDispatchToProps, mapStateToProps };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanUpgradeCTA);
