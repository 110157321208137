import React, { Fragment } from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import Button from '@frameio/components/src/styled-components/Button';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import {
  STORAGE_LIMIT,
  ARCHIVED_STORAGE_LIMIT,
} from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import { formatMoney } from 'formatters/money';
import AddSubtractButton from './AddSubtractButton';

const DIGIT_WIDTH = 42;
const MARGIN = 32;

export const StyledDialog = styled(Dialog)`
  width: 635px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing.medium} 0 0`};
`;

const Number = styled.input`
  color: ${(p) => p.theme.color.brand};
  background-color: transparent;
  border: none;
  min-width: ${DIGIT_WIDTH + MARGIN}px;
  max-width: 400px;
  width: ${({ value }) => {
    const length = value.toString().length;
    return `${MARGIN + length * DIGIT_WIDTH}px`;
  }};
  height: 84px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 70px;
  font-weight: 900;

  &:hover {
    cursor: ${({ disabled }) => !disabled && 'pointer'};
    border: ${({ disabled, theme }) =>
      !disabled && `1px solid ${theme.color.lightGray}`};
  }
  &:focus {
    outline: none;
    border: 1px solid ${(p) => p.theme.color.brand};
  }
`;

const CurrentLimit = styled.div`
  color: ${(p) => p.theme.color.brand};
  display: inline;
  margin: 0 20px;
  text-align: center;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'auto')};
`;

const BottomLine = styled.div`
  padding-top: 12px;
`;

const Details = styled.div`
  background-color: ${(p) => p.theme.color.coldWhite};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px auto 0 auto;
  padding: 16px;
  text-align: center;
  width: 100%;
`;

const periodText = {
  monthly: 'mo.',
  yearly: 'yr.',
};

export default class ManageSubscriptionModal extends React.Component {
  constructor(props) {
    super(props);
    const increment = this.getIncrement();

    this.state = {
      // When showTotalLineItem is true, show the current limit for the account.
      // When showTotalLineItem is false, show the corresponding increment value for the line item.
      // For TM seat add-on, updatedLimit will start at 1 (increment or decrement by one seat).
      // For storage add-on, updatedLimit will start at 250 (increment or decrement by 250 GB).
      updatedLimit: props.showTotalLineItem ? props.currentLimit : increment,
    };
  }

  getIncrement = () => {
    const { planLineItem } = this.props;
    const { increment } = planLineItem;
    return increment;
  };

  isSubmissionDisabled = () => {
    const { isSubmitting, currentLimit, showTotalLineItem } = this.props;
    const { updatedLimit } = this.state;

    if (!showTotalLineItem) return false;
    if (isSubmitting) {
      return true;
    }
    if (!this.isNewLimitAllowed(updatedLimit)) {
      return true;
    }
    if (updatedLimit === currentLimit) {
      return true;
    }
    return false;
  };

  updateLimitValue = (isIncreasing, event) => {
    const increment = this.getIncrement();
    const { maxValue, blockInput } = this.props;
    // If typing in
    if (event) {
      const newVal = event.target.value ? parseInt(event.target.value, 10) : '';
      if (blockInput || (maxValue && newVal > maxValue)) {
        return;
      }
      this.setState({ updatedLimit: newVal });
      return;
    }
    // If clicking +/- buttons
    let newLimit = this.state.updatedLimit || 0;
    newLimit = isIncreasing ? (newLimit += increment) : (newLimit -= increment);
    if (this.canUpdateInput(isIncreasing)) {
      this.setState({ updatedLimit: newLimit });
    }
  };

  isNewLimitAllowed = (newLimit, isIncreasing) => {
    const { planLimit, maxValue } = this.props;
    if (
      (!isIncreasing && newLimit < planLimit) ||
      (maxValue && newLimit > maxValue)
    ) {
      return false;
    }
    return true;
  };

  canUpdateInput = (isIncreasing) => {
    const increment = this.getIncrement();
    let newLimit = this.state.updatedLimit || 0;
    newLimit = isIncreasing ? (newLimit += increment) : (newLimit -= increment);
    return this.isNewLimitAllowed(newLimit, isIncreasing);
  };

  newLineItemQuantity = () => {
    if (this.props.showTotalLineItem) {
      return (
        (this.state.updatedLimit - this.props.currentLimit) /
        this.getIncrement()
      );
    }
    return this.state.updatedLimit / this.getIncrement();
  };

  generateStorageLineItemDecrement = (
    incrementTotal,
    incrementUnit,
    changeCount
  ) => (
    <>
      {Math.abs(incrementTotal * changeCount)} {incrementUnit}
    </>
  );

  render() {
    const {
      blockInput,
      closeModal,
      continueFlow,
      currentLimit,
      generateTotalAndUnit,
      header,
      minWidth,
      planLineItem,
      planPeriod,
      toolTipText,
      updateItem,

      // When showTotalLineItem is set to true, it should initialize the modal
      // with the total limit for a given line item (used in billing overview).
      // When showTotalLineItem is set to false, it should initialize the modal
      // with the minimum increment for a given line item (used in contextual flows
      // like accepting a join request or adding more storage when the limit has been reached):
      // - 1 for TM line item
      // - 250 for storage line item
      showTotalLineItem,
    } = this.props;

    const { total, unit } = generateTotalAndUnit(
      this.state.updatedLimit,
      showTotalLineItem
    );
    const { increment, cost: rate, modifier } = planLineItem;
    const changeCount = this.newLineItemQuantity();
    const { total: incrementTotal, unit: incrementUnit } = generateTotalAndUnit(
      increment,
      showTotalLineItem
    );

    const cost = changeCount * rate;
    const rawTotalLineItemAdded = increment * changeCount;
    const isStorageLineItem =
      modifier === STORAGE_LIMIT || modifier === ARCHIVED_STORAGE_LIMIT;

    // Update subscription line item endpoint needs to receive the new limit of a given line item.
    // When showTotalLineItem is false, updatedLimit starts at the minimum increment for a given
    // line item so we need to add currentLimit to get the new limit.
    // When showTotalLineItem is true, updatedLimit is updated internally
    // by ManageSubscriptionModal (see this.updateLimitValue).
    const updatedLimit = showTotalLineItem
      ? this.state.updatedLimit
      : this.state.updatedLimit + currentLimit;

    const planLimit = this.props.planLimit;

    const {
      total: currentLimitFormatted,
      unit: currentLimitUnit,
    } = generateTotalAndUnit(currentLimit - planLimit);

    const {
      total: formattedPlanLimit,
      unit: planLimitUnit,
    } = generateTotalAndUnit(planLimit);

    const showBottomLine = updatedLimit !== planLimit;

    const planQuoteCount = (this.state.updatedLimit - planLimit) / increment;

    const bottomLine =
      cost === 0 ? (
        <BottomLine>
          <p>
            <b>
              You have {currentLimitFormatted} {currentLimitUnit} of extra
              storage
            </b>
          </p>
          <p>
            {formatMoney(Math.abs(planLineItem?.cost), { decimal: true })} /
            {periodText[planPeriod]} per {incrementTotal} {incrementUnit}
          </p>
        </BottomLine>
      ) : (
        <BottomLine>
          {cost > 0 ? (
            <Fragment>
              <p>
                <b>
                  Adding{' '}
                  {isStorageLineItem &&
                    this.generateStorageLineItemDecrement(
                      incrementTotal,
                      incrementUnit,
                      changeCount
                    )}{' '}
                  extra storage (
                  {formatMoney(Math.abs(planLineItem?.cost), { decimal: true })}{' '}
                  /{periodText[planPeriod]} per {incrementTotal} {incrementUnit}
                  ){' '}
                </b>
              </p>
              <p>
                Your plan cost will increase by{' '}
                {formatMoney(Math.abs(cost), {
                  decimal: true,
                })}{' '}
                /{periodText[planPeriod]} (plus tax)
              </p>
            </Fragment>
          ) : (
            <Fragment>
              <p>
                <b>
                  Removing{' '}
                  {isStorageLineItem &&
                    this.generateStorageLineItemDecrement(
                      incrementTotal,
                      incrementUnit,
                      changeCount
                    )}{' '}
                  extra storage (
                  {formatMoney(Math.abs(planLineItem?.cost), { decimal: true })}{' '}
                  /{periodText[planPeriod]} per {incrementTotal} {incrementUnit}
                  ){' '}
                </b>
              </p>
              <p>
                Your plan cost will decrease by{' '}
                {formatMoney(Math.abs(cost), {
                  decimal: true,
                })}{' '}
                /{periodText[planPeriod]} (plus tax)
              </p>
            </Fragment>
          )}
        </BottomLine>
      );

    return (
      <StyledDialog
        type={dialogTypes.NONE}
        header={<div className="complex-header">{header}</div>}
        primaryButton={
          <Button
            disabled={this.isSubmissionDisabled()}
            // TODO(gabrielmessager): refactor so there is only one flow
            // in ProjectContainer and AccountSettings
            onClick={() =>
              continueFlow
                ? continueFlow(
                    updatedLimit,
                    rawTotalLineItemAdded,
                    planQuoteCount
                  )
                : updateItem(updatedLimit)
            }
          >
            Continue
          </Button>
        }
        secondaryButton={<Button onClick={closeModal}>Cancel</Button>}
      >
        <Row>
          <AddSubtractButton
            onClick={this.updateLimitValue}
            canUpdateInput={this.canUpdateInput}
            toolTipText={toolTipText}
          />
          <CurrentLimit minWidth={minWidth}>
            <Number
              disabled={blockInput}
              type="text"
              value={`${total} ${unit}`}
              onChange={(e) => this.updateLimitValue(false, e)}
            />
          </CurrentLimit>
          <AddSubtractButton
            onClick={this.updateLimitValue}
            isPositive
            canUpdateInput={this.canUpdateInput}
            toolTipText={toolTipText}
          />
        </Row>
        <p
          style={{
            fontSize: '11px',
            textAlign: 'center',
          }}
        >
          Your plan includes {formattedPlanLimit} {planLimitUnit}
        </p>
        {showBottomLine && <Details>{bottomLine}</Details>}
      </StyledDialog>
    );
  }
}

ManageSubscriptionModal.defaultProps = {
  blockInput: false,
  continueFlow: undefined,
  maxValue: null,
  minWidth: null,
  planLimit: 0,
  showTotalLineItem: true,
  toolTipText: null,
  updatedLimit: null,
};

ManageSubscriptionModal.propTypes = {
  blockInput: Proptypes.bool,
  closeModal: Proptypes.func.isRequired,
  continueFlow: Proptypes.func,
  currentLimit: Proptypes.number.isRequired,
  generateTotalAndUnit: Proptypes.func.isRequired,
  header: Proptypes.string.isRequired,
  isSubmitting: Proptypes.bool.isRequired,
  maxValue: Proptypes.number,
  minWidth: Proptypes.number,
  planLimit: Proptypes.number,
  planLineItem: Proptypes.shape({
    cost: Proptypes.number.isRequired,
    increment: Proptypes.number.isRequired,
  }).isRequired,
  planPeriod: Proptypes.string.isRequired,
  showTotalLineItem: Proptypes.bool,
  toolTipText: Proptypes.string,
  updateItem: Proptypes.func.isRequired,
};
