import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import { planDataSelector } from 'selectors/accounts';
import { discountPlanDataSelector } from 'selectors/plans';
import SaveOffer from './SaveOffer';
import { claimOffer, continueCancel } from '../actions';

const mapDispatchToProps = { claimOffer, closeModal, continueCancel };

const mapStateToProps = (state) => ({
  currentPlan: planDataSelector(state),
  discountPlan: discountPlanDataSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveOffer);
