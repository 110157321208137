import { discountPlanDataSelector } from 'selectors/plans';
import { saveOfferCancellationFlowEnabled } from 'utils/featureFlags';

export const cancelAccountFlowFetchingStatusSelector = (state) =>
  state.cancelAccountFlow.isFetching;

export const canShowSaveOfferSelector = (state) => {
  const discountPlan = discountPlanDataSelector(state);
  const saveOfferStepEnabled = saveOfferCancellationFlowEnabled(state);

  return Boolean(saveOfferStepEnabled && discountPlan);
};
