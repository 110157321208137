import { connect } from 'react-redux';
import { compact } from 'lodash';
import ProgressDots from './ProgressDots';
import {
  CANCELLATION_REASON_URL,
  CANCELLATION_FEEDBACK_URL,
  CANCELLATION_SAVE_OFFER_URL,
  CANCELLATION_CONFIRMATION_URL,
} from '../constants';
import { canShowSaveOfferSelector } from '../selectors';

const mapStateToProps = (state) => {
  const canShowSaveOffer = canShowSaveOfferSelector(state);

  const steps = compact([
    CANCELLATION_REASON_URL,
    CANCELLATION_FEEDBACK_URL,
    canShowSaveOffer && CANCELLATION_SAVE_OFFER_URL,
    CANCELLATION_CONFIRMATION_URL,
  ]);

  return { steps };
};

export default connect(mapStateToProps)(ProgressDots);
