import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import ReasonSelector from './ReasonSelector';
import Feedback from './Feedback';
import Confirmation from './Confirmation';
import SaveOffer from './SaveOffer';
import {
  CANCELLATION_REASON_URL,
  CANCELLATION_FEEDBACK_URL,
  CANCELLATION_SAVE_OFFER_URL,
  CANCELLATION_CONFIRMATION_URL,
} from './constants';

const CancelAccountFlow = ({
  accountId,
  startFlow,
  endFlow,
  source,
  onClose,
}) => {
  const [reasonKey, setReasonKey] = useState(null);
  const [trackingReason, setTrackingReason] = useState(null);
  const [trackingFeedback, setTrackingFeedback] = useState(null);

  useEffect(() => {
    startFlow(accountId);
    return () => {
      setReasonKey(null);
      endFlow();
    };
  }, [accountId, endFlow, startFlow]);

  return (
    <Router history={history}>
      <Switch>
        <Route
          path={CANCELLATION_REASON_URL}
          render={({ location }) => (
            <ReasonSelector
              currentStep={location.pathname}
              setReasonKey={setReasonKey}
              setTrackingReason={setTrackingReason}
              source={source}
              onClose={onClose}
            />
          )}
        />
        <Route
          path={CANCELLATION_FEEDBACK_URL}
          render={({ location }) => (
            <Feedback
              currentStep={location.pathname}
              reasonKey={reasonKey}
              setTrackingFeedback={setTrackingFeedback}
              trackingReason={trackingReason}
              source={source}
              onClose={onClose}
            />
          )}
        />
        <Route
          path={CANCELLATION_SAVE_OFFER_URL}
          render={({ location }) => (
            <SaveOffer
              accountId={accountId}
              currentStep={location.pathname}
              onClose={onClose}
              source={source}
            />
          )}
        />
        <Route
          path={CANCELLATION_CONFIRMATION_URL}
          render={({ location }) => (
            <Confirmation
              currentStep={location.pathname}
              trackingReason={trackingReason}
              trackingFeedback={trackingFeedback}
              source={source}
              onClose={onClose}
            />
          )}
        />
      </Switch>
    </Router>
  );
};

CancelAccountFlow.propTypes = {
  accountId: PropTypes.string.isRequired,
  startFlow: PropTypes.func.isRequired,
  endFlow: PropTypes.func.isRequired,
  continueFlow: PropTypes.func.isRequired,
};

CancelAccountFlow.defaultProps = {};

export default CancelAccountFlow;
