import { planEntitySelector } from '@frameio/core/src/plans/selectors';
import { planEntityForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { planDataSelector, seatLimitPropertyName } from 'selectors/accounts';

export const isAutoScalingPlanSelector = (state, { accountId }) => {
  if (!accountId) return false;
  const plan = planEntityForAccountIdSelector(state, { accountId });
  return !!plan.autoscaling;
};

export const isUnlimitedSeatsPlanSelector = (state, { accountId }) => {
  const plan = planEntityForAccountIdSelector(state, { accountId });
  const seatLimitType = seatLimitPropertyName(state);

  return plan[seatLimitType] === null;
};

export const discountPlanSelector = (state) => {
  const plan = planDataSelector(state);
  return plan?.discount_plan_id;
};

export const discountPlanDataSelector = (state) => {
  const planId = discountPlanSelector(state);
  return planEntitySelector(state, { planId });
};
