import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { absoluteFill } from '@frameio/components/src/mixins';
import LogoSpinner from 'components/LogoSpinner';

const Container = styled(Flex)`
  ${absoluteFill()}
`;

const Text = styled.div`
  color: ${(p) => p.theme.color.gray};
  font-size: ${(p) => p.theme.fontSize[2]};
  margin-top: ${(p) => p.theme.spacing.medium};
`;

const PaymentProcessing = ({
  isFreePlanWithUserMaxSelected,
  isCheckoutFlowV2Enabled,
}) => (
  <Container column center>
    <LogoSpinner />
    <Text>
      {isFreePlanWithUserMaxSelected || isCheckoutFlowV2Enabled
        ? 'Processing…'
        : 'Payment processing…'}
    </Text>
  </Container>
);

export default PaymentProcessing;
