import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import CloseButton from '@frameio/components/src/components/Modal/CloseButton';
import Button from '@frameio/components/src/styled-components/Button';
import { CANCELLATION_CONFIRMATION_URL } from '../constants';
import ProgressDots from '../ProgressDots';

const TEXT = {
  primary: 'Next',
  secondary: 'Don’t cancel',
};

const CancelAccountFlowModal = ({
  autoHeight = false,
  children,
  currentStep,
  descriptionText,
  headerText,
  isDestructiveToProceed = false,
  onClose,
  primaryAction,
  primaryButtonText = TEXT.primary,
  secondaryAction = onClose,
  secondaryButtonText = TEXT.secondary,
  submitDisabled,
}) => {
  const isLastStep = currentStep === CANCELLATION_CONFIRMATION_URL;

  return (
    <Container autoHeight={autoHeight} column>
      <Header>{headerText}</Header>
      {descriptionText && <Description>{descriptionText}</Description>}
      <StyledCloseButton onClick={onClose} />
      {children}
      <BottomNav>
        <Flex justifyBetween>
          <ProgressDots currentStep={currentStep} />
          <ButtonContainer>
            <Button secondary text onClick={secondaryAction}>
              {secondaryButtonText}
            </Button>
            <Button
              primary={!isDestructiveToProceed}
              destructive={isDestructiveToProceed}
              type="submit"
              onClick={primaryAction}
              disabled={submitDisabled}
              error={isLastStep}
            >
              {primaryButtonText}
            </Button>
          </ButtonContainer>
        </Flex>
      </BottomNav>
    </Container>
  );
};

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: ${(p) => p.theme.spacing.tiny};
  right: ${(p) => p.theme.spacing.tiny};
`;

const Header = styled.span`
  display: flex;
  font-weight: bold;
  font-size: ${(p) => p.theme.fontSize[3]};
  color: ${(p) => p.theme.color.coolBlack};
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
`;

const Description = styled.p`
  ${(p) => p.theme.fontStyle.body};
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
`;

const Container = styled(Flex)`
  height: ${(p) => (p.autoHeight ? 'auto' : p.theme.spacing.units(52))};
  width: ${(p) => p.theme.spacing.units(59)};
  padding: ${(p) => p.theme.spacing.medium};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: ${(p) => p.theme.spacing.units(1)};
`;

const BottomNav = styled.div`
  margin-top: auto;
`;

export default CancelAccountFlowModal;
