import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { formatBytes } from 'shared/filesizeHelpers';

export function ItemCount({ count, ...props }) {
  let text = 'No items';
  if (count > 1) text = `${count.toLocaleString()} items`;
  if (count === 1) text = '1 item';
  return <span {...props}>{text}</span>;
}

ItemCount.propTypes = {
  count: PropTypes.number.isRequired,
};

export function Size({ size }) {
  return formatBytes(size);
}

export default function FolderSize({ folder }) {
  if (!folder) return 'Calculating size…';

  const count = get(folder, 'sm_file_count', 0);
  const size = get(folder, 'sm_billable_active_storage_bytes', 0);

  return (
    <React.Fragment>
      <ItemCount count={count} />, <Size size={size} />
    </React.Fragment>
  );
}

FolderSize.propTypes = {
  folder: PropTypes.shape({
    count: PropTypes.number,
    filesize: PropTypes.number,
    usage: PropTypes.shape({
      count: PropTypes.number,
      filesize: PropTypes.number,
    }),
  }),
};

FolderSize.defaultProps = {
  folder: undefined,
};
