import { connect } from 'react-redux';
import { checkoutFlowV2Enabled } from 'utils/featureFlags';
import AddCreditCard from './AddCreditCard';
import { errorMessageSelector } from '../../selectors';

function mapStateToProps(state) {
  const isCheckoutFlowV2Enabled = checkoutFlowV2Enabled(state);

  return {
    isCheckoutFlowV2Enabled,
    errorMessage: errorMessageSelector(state),
  };
}

export default connect(mapStateToProps)(AddCreditCard);
