import React from 'react';
import styled from 'styled-components';
import PlanSummary from 'components/SelectPlanFlow/PlanSummary';
import PlanSummaryFooter from 'components/SelectPlanFlow/PlanSummaryFooter';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import AddCreditCard from '../AddCreditCard';

const Container = styled.div`
  max-width: calc(108 * 8px);
  width: 100%;
`;

export default function AddCreditCardWithPlanSummary({
  closeModal,
  isCheckoutFlowV2Enabled,
}) {
  const [showPlanSummary, setShowPlanSummary] = React.useState(true);

  if (showPlanSummary && !isCheckoutFlowV2Enabled) {
    return (
      <Container>
        <ModalCloseButton onClick={closeModal} />
        <PlanSummary
          onCtaClick={() => setShowPlanSummary(false)}
          shouldDisplayCurrentPlan
        />
        <PlanSummaryFooter />
      </Container>
    );
  }

  return <AddCreditCard />;
}
