import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import track from 'analytics';
import { highestScopedAccountRoleSelector } from 'selectors/roles';

const TRACKING = {
  OFFER_ACCEPTED: 'offer-accepted',
  OFFER_DECLINED: 'offer-declined',
  OFFER_SHOWN: 'offer-shown',
};

const formatCurrentPlan = (currentPlan) => ({
  current_plan_cost: currentPlan.cost,
  current_plan_id: currentPlan.id,
  current_plan_is_offer: false,
  current_plan_is_trial: false,
  current_plan_name: currentPlan.name,
  current_plan_period: capitalize(currentPlan.period),
  current_plan_tier: capitalize(currentPlan.tier),
  current_plan_version: currentPlan.version,
});

const formatDiscountPlan = (discountPlan) => ({
  selected_plan_cost: discountPlan.cost,
  selected_plan_id: discountPlan.id,
  selected_plan_is_offer: true,
  selected_plan_is_trial: false,
  selected_plan_name: discountPlan.name,
  selected_plan_period: capitalize(discountPlan.period),
  selected_plan_tier: capitalize(discountPlan.tier),
  selected_plan_version: discountPlan.version,
});

const useGetAccountRole = (accountId) => {
  return useSelector((state) =>
    highestScopedAccountRoleSelector(state, { accountId })
  );
};

export const useSaveOfferTracking = ({
  accountId,
  currentPlan,
  discountPlan,
}) => {
  const accountRole = useGetAccountRole(accountId);

  const getTrackingParams = () => ({
    account_role: accountRole,
    ...formatCurrentPlan(currentPlan),
    ...formatDiscountPlan(discountPlan),
  });

  const trackOfferShown = () => {
    track(TRACKING.OFFER_SHOWN, getTrackingParams());
  };

  const trackOfferAccepted = () => {
    track(TRACKING.OFFER_ACCEPTED, getTrackingParams());
  };

  const trackOfferDeclined = () => {
    track(TRACKING.OFFER_DECLINED, getTrackingParams());
  };

  return { trackOfferAccepted, trackOfferDeclined, trackOfferShown };
};
