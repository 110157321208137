import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';
import { isFetchingActionFactory } from '../../factories/isFetching';

export const SELECT_PLAN_FLOW = generateActionTypes('SELECT_PLAN_FLOW', [
  'CHAT_WITH_OUR_TEAM',
  'CONTINUE',
  'END',
  'IS_FETCHING',
  'OPEN_MODAL',
  'REPORT_PLAN_CHANGE_SUCCESS',
  'START',
  'START_WITH_SELECTED_PLAN',
  'SET_PLAN_QUOTE',
]);

export const startFlow = createAction(
  SELECT_PLAN_FLOW.START,
  (accountId, trackingProps) => ({ accountId, trackingProps })
);

export const endFlow = createAction(SELECT_PLAN_FLOW.END, () => ({}));

// TODO(yuval): change this action so it puts the selected plan id in the payload
export const continueFlow = createAction(
  SELECT_PLAN_FLOW.CONTINUE,
  (selectedPlan) => ({ selectedPlan })
);

export const isFetching = isFetchingActionFactory(SELECT_PLAN_FLOW.IS_FETCHING);

export const chatWithOurTeam = createAction(
  SELECT_PLAN_FLOW.CHAT_WITH_OUR_TEAM,
  () => ({})
);

export const reportPlanChangeSuccess = createAction(
  SELECT_PLAN_FLOW.REPORT_PLAN_CHANGE_SUCCESS,
  () => ({})
);

export const openSelectPlanFlowModal = createAction(
  SELECT_PLAN_FLOW.OPEN_MODAL,
  (accountId, trackingProps) => ({ accountId, trackingProps })
);

export const setPlanQuote = createAction(
  SELECT_PLAN_FLOW.SET_PLAN_QUOTE,
  (planQuote) => ({ planQuote })
);

export const startFlowWithSelectedPlan = createAction(
  SELECT_PLAN_FLOW.START_WITH_SELECTED_PLAN,
  (accountId, selectedPlanId, trackingProps) => ({
    accountId,
    selectedPlanId,
    trackingProps,
  })
);
