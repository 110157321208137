import React, { Fragment, useCallback, useEffect } from 'react';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { Tooltip } from '@frameio/vapor';
import InfoIcon from '@frameio/components/src/svgs/icons/24/info-filled.svg';
import Password from '@frameio/components/src/styled-components/Password';
import DatePicker from '@frameio/components/src/styled-components/DatePicker';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import { allFeatures } from 'planFeatures';
import track from 'analytics';
import SharePanelSettingsSwitchRow, {
  renderLabelWithStatus,
  upgradeToChange,
} from '../SharePanel/SharePanelSettingsSwitchRow';
import { SettingsSubHeader } from './ReviewLinkEditor';
import SharePanelSettingsSortDropdown from '../SharePanel/DropDown/SharePanelSettingsSortDropdown';
import { SORT_CONST } from '../SharePanel/DropDown/sortOptions';

const DisabledBox = styled.div`
  color: ${(p) => p.theme.color.coldWhite};
`;

const DisabledMessage = styled.p`
  color: ${(p) => p.theme.color.slateGray};
`;

const StyledSpan = styled.span`
  display: inline-flex;
  margin-top: -2px;
  margin-left: ${(p) => p.theme.spacing.tiny};
  color: ${(p) => p.theme.color.slateGray};
`;

const ReviewLinkEditorSettingsForm = ({
  canDisableShareComments,
  canDisableShareDownloads,
  canDisableSharePreviousVersions,
  canEnableDownloads,
  canShareWithoutDrm,
  canToggleComments,
  canUseCustomSort,
  canUseShareLinkExpiration,
  canUsePasswordProtectedShares,
  formik: {
    setFieldValue,
    values: {
      allowApprovals,
      allowComments,
      allowDownloads,
      disableDrm,
      expiresAt,
      password,
      showAllVersions,
      showLinkExpiration,
      showPassword,
      sortDirection,
      sortType,
    },
  },
  isAdmin,
  isDrmSettingsEnabled,
  onChange,
  onSelectSortBy,
  plan,
  reviewLinkId,
  upgradePlan,
}) => {
  const maybeStartUpgradePlanFlow = useCallback(
    (feature) => {
      if (isAdmin) {
        track('feature-gate-clicked-client', { _limit: feature });
        upgradePlan(reviewLinkId, feature);
      }
    },
    [isAdmin, reviewLinkId, upgradePlan]
  );
  const downloadsToggle = {
    disabled: !canEnableDownloads,
    disabledTooltipMessage:
      'Only account admins can change download permissions',
  };

  useEffect(() => {
    if (!showLinkExpiration) {
      setFieldValue('expiresAt', null);
    }
  }, [showLinkExpiration, setFieldValue]);
  useEffect(() => {
    if (!showPassword) {
      setFieldValue('password', null);
    }
  }, [showPassword, setFieldValue]);

  const planVersion = plan?.version;
  const isPlanVersion8 = planVersion === 8;

  return (
    <Fragment>
      <Fragment>
        <SettingsSubHeader>Assets</SettingsSubHeader>
        <SharePanelSettingsSortDropdown
          canUseCustomSort={canUseCustomSort}
          isDescending={sortDirection === SORT_CONST.DESC}
          label="Sort by"
          maybeStartUpgradePlanFlow={maybeStartUpgradePlanFlow}
          onChange={onChange}
          onSelectSortBy={onSelectSortBy}
          sortBy={sortType}
        />
      </Fragment>
      <SettingsSubHeader>Reviewer Permissions</SettingsSubHeader>
      {(canEnableDownloads || allowDownloads) && (
        <SharePanelSettingsSwitchRow
          checked={allowDownloads}
          label={renderLabelWithStatus(
            'Allow downloads',
            allowDownloads,
            !canDisableShareDownloads
          )}
          name="allowDownloads"
          onToggle={
            canDisableShareDownloads
              ? onChange
              : () =>
                  maybeStartUpgradePlanFlow(allFeatures.disableShareDownloads)
          }
          isAdmin={isAdmin}
          featureLocked={
            !canDisableShareDownloads && allFeatures.disableShareDownloads
          }
          featureGateLabel={upgradeToChange}
          {...downloadsToggle}
        />
      )}
      {canToggleComments && (
        <SharePanelSettingsSwitchRow
          checked={allowComments}
          label={renderLabelWithStatus(
            'Allow comments',
            allowComments,
            !canDisableShareComments
          )}
          name="allowComments"
          onToggle={
            canDisableShareComments
              ? onChange
              : () =>
                  maybeStartUpgradePlanFlow(allFeatures.disableShareComments)
          }
          isAdmin={isAdmin}
          featureLocked={
            !canDisableShareComments && allFeatures.disableShareComments
          }
          featureGateLabel={upgradeToChange}
        />
      )}
      <SharePanelSettingsSwitchRow
        checked={allowApprovals}
        label="Allow approvals"
        name="allowApprovals"
        onToggle={onChange}
      />
      <SharePanelSettingsSwitchRow
        checked={showAllVersions}
        label={renderLabelWithStatus(
          'Show all versions',
          showAllVersions,
          !canDisableSharePreviousVersions
        )}
        name="showAllVersions"
        onToggle={
          canDisableSharePreviousVersions
            ? onChange
            : () =>
                maybeStartUpgradePlanFlow(
                  allFeatures.disableSharePreviousVersions
                )
        }
        isAdmin={isAdmin}
        featureLocked={
          !canDisableSharePreviousVersions &&
          allFeatures.disableSharePreviousVersions
        }
        featureGateLabel={upgradeToChange}
      />

      <SettingsSubHeader>Link Settings</SettingsSubHeader>
      {isDrmSettingsEnabled && (
        <SharePanelSettingsSwitchRow
          label={
            <Flex>
              Apply DRM
              <Tooltip
                shouldUsePortal
                placement="top"
                title={
                  <Fragment>
                    Apply Digital Rights Management content protection for
                    proxies being shared.
                  </Fragment>
                }
                variant="dark"
              >
                <StyledSpan>
                  <InfoIcon width={24} height={24} />
                </StyledSpan>
              </Tooltip>
            </Flex>
          }
          checked={canShareWithoutDrm ? !disableDrm : true}
          disabled={!canShareWithoutDrm && isDrmSettingsEnabled}
          disabledTooltipMessage={
            <DisabledBox>
              You don't have permission to
              <br /> perform this action
              <DisabledMessage>Please contact your admin.</DisabledMessage>
            </DisabledBox>
          }
          name="disableDrm"
          onToggle={(evt) => {
            onChange({
              target: {
                name: 'disableDrm',
                value: !evt.target.checked,
              },
            });
          }}
        />
      )}
      <SharePanelSettingsSwitchRow
        checked={showPassword}
        label={renderLabelWithStatus(
          'Passphrase protect',
          showPassword,
          !canUsePasswordProtectedShares
        )}
        name="showPassword"
        onToggle={
          canUsePasswordProtectedShares
            ? onChange
            : () =>
                maybeStartUpgradePlanFlow(allFeatures.passwordProtectedShares)
        }
        isAdmin={isAdmin}
        featureLocked={
          !canUsePasswordProtectedShares && allFeatures.passwordProtectedShares
        }
        featureGateLabel={upgradeToChange}
      >
        {showPassword && (
          <Password
            autoComplete="new-password"
            name="password"
            value={password || ''}
            onChange={onChange}
          />
        )}
      </SharePanelSettingsSwitchRow>
      <SharePanelSettingsSwitchRow
        checked={showLinkExpiration}
        label={renderLabelWithStatus(
          'Link expiration',
          showLinkExpiration,
          !canUseShareLinkExpiration
        )}
        name="showLinkExpiration"
        onToggle={
          canUseShareLinkExpiration
            ? onChange
            : () => maybeStartUpgradePlanFlow(allFeatures.shareLinkExpiration)
        }
        isAdmin={isAdmin}
        featureLocked={
          !canUseShareLinkExpiration && allFeatures.shareLinkExpiration
        }
        featureGateLabel={upgradeToChange}
        requiresUpgradeToPro={isPlanVersion8}
      >
        <DatePicker
          name="expiresAt"
          shouldCloseOnChange={false}
          onChange={(isoDate) =>
            onChange({ target: { name: 'expiresAt', value: isoDate } })
          }
          value={expiresAt || ''}
          minDate="today"
        >
          {({ value }) => (
            <TextInput
              autoComplete="off"
              placeholder="Set expiration date"
              readOnly
              value={value}
            />
          )}
        </DatePicker>
      </SharePanelSettingsSwitchRow>
    </Fragment>
  );
};

ReviewLinkEditorSettingsForm.propTypes = {
  canDisableShareComments: PropTypes.bool.isRequired,
  canEnableDownloads: PropTypes.bool.isRequired,
  canSetDefaultTeamSessionWatermarkId: PropTypes.bool.isRequired,
  canToggleComments: PropTypes.bool.isRequired,
  formik: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.shape({
      allowApprovals: PropTypes.bool.isRequired,
      allowComments: PropTypes.bool.isRequired,
      allowDownloads: PropTypes.bool.isRequired,
      expiresAt: PropTypes.string,
      password: PropTypes.string,
      showLinkExpiration: PropTypes.bool.isRequired,
      showPassword: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  isLinkWatermarkable: PropTypes.bool.isRequired,
  isSessionBasedWatermarkingEnabled: PropTypes.bool.isRequired,
  onClickSetInviteOnly: PropTypes.func.isRequired,
  sessionWatermarkTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setDefaultSessionWatermarkTemplateId: PropTypes.func,
  showCustomWatermarkSubmit: PropTypes.bool,
  setCustomWatermarkSubmit: PropTypes.func,
};

ReviewLinkEditorSettingsForm.defaultProps = {
  canSetDefaultTeamSessionWatermarkId: false,
  canToggleComments: false,
  isSessionBasedWatermarkingEnabled: false,
  sessionWatermarkTemplates: [],
};

export default connect(ReviewLinkEditorSettingsForm);
