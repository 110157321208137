import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import PaymentFlow from 'components/PaymentFlow';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import { LARGE } from 'utils/mediaQueries';
import history from './history';
import SelectPlan from './SelectPlan';
import PlanSummary from './PlanSummary';
import ConfirmPlanChange from './ConfirmPlanChange';
import PlanSummaryFooter from './PlanSummaryFooter';

export const MAX_HEIGHT = 770;

export const SELECT_PLAN_URL = '/SelectPlan';
export const PLAN_SUMMARY_URL = '/PlanSummary';
export const PAYMENT_FLOW_URL = '/PaymentFlow';
export const PLAN_CONFIRMATION_URL = '/PlanConfirmation';

const Container = styled.div`
  @media ${LARGE} {
    width: ${({ theme, isAccountOnPlanWithUserMax }) =>
      isAccountOnPlanWithUserMax
        ? theme.spacing.units(108)
        : theme.spacing.units(115)};
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

class SelectPlanFlow extends React.Component {
  componentDidMount() {
    const {
      accountId,
      selectedPlanData,
      startFlow,
      startFlowWithSelectedPlan,
      trackingProps,
    } = this.props;

    if (selectedPlanData?.planId) {
      startFlowWithSelectedPlan(
        accountId,
        selectedPlanData?.planId,
        trackingProps
      );
    } else {
      startFlow(accountId, trackingProps);
    }
  }

  componentWillUnmount() {
    this.props.endFlow();
  }

  render() {
    const {
      accountId,
      onCancel,
      onComplete,
      trackingProps,
      isAccountOnPlanWithUserMax,
      isCheckoutFlowV2Enabled,
      selectedPlanData,
    } = this.props;

    return (
      <React.Fragment>
        <ModalCloseButton onClick={onCancel} />
        <Router history={history}>
          <Switch>
            <Route
              path={SELECT_PLAN_URL}
              render={() => (
                <Container
                  isAccountOnPlanWithUserMax={isAccountOnPlanWithUserMax}
                >
                  <SelectPlan />
                </Container>
              )}
            />
            <Route
              path={PLAN_SUMMARY_URL}
              render={(props) => (
                <Container
                  isAccountOnPlanWithUserMax={isAccountOnPlanWithUserMax}
                >
                  <PlanSummary
                    {...props}
                    onCancel={onCancel}
                    selectedPlanData={selectedPlanData}
                  />
                  {!isCheckoutFlowV2Enabled && <PlanSummaryFooter />}
                </Container>
              )}
            />
            <Route
              path={PAYMENT_FLOW_URL}
              render={(props) => (
                <PaymentFlow
                  accountId={accountId}
                  onCancel={onCancel}
                  trackingProps={trackingProps}
                  {...props}
                />
              )}
            />
            <Route
              path={PLAN_CONFIRMATION_URL}
              render={(props) => (
                <ConfirmPlanChange onDoneClick={onComplete} {...props} />
              )}
            />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

SelectPlanFlow.propTypes = {
  endFlow: Proptypes.func.isRequired,
  startFlow: Proptypes.func.isRequired,
  onCancel: Proptypes.func.isRequired,
  onComplete: Proptypes.func,
  accountId: Proptypes.string.isRequired,
  trackingProps: Proptypes.object,
};

SelectPlanFlow.defaultProps = {
  onComplete: null,
  trackingProps: {},
};

export default SelectPlanFlow;
