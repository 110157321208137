import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import Button from '@frameio/components/src/styled-components/Button';
import { redirectToNext } from 'utils/router';
import StartAccountIllustration from '../StartAccount/start-account-setup.svg';

const TEXT = {
  headline: `You're ready for V4`,
  message: `If you'd like to navigate to your new V4 Account, click the button below. Otherwise, 
              it's accessible on the left side of the screen at any time.`,
  stayHere: 'Stay here',
  v4CTA: 'Go to V4',
};

const StyledDialog = styled(Dialog)`
  height: ${(p) => p.theme.spacing.units(50)};
  padding: ${(p) => p.theme.spacing.medium};
`;

const StartAccountIllustrationContainer = styled(Flex)`
  padding-top: ${(p) => p.theme.spacing.tiny};
  padding-bottom: ${(p) => p.theme.spacing.medium};
`;

const Header = styled.span`
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: ${(p) => p.theme.fontSize[3]};
  color: ${(p) => p.theme.color.coolBlack};
  padding-bottom: ${(p) => p.theme.spacing.units(2)};
`;

const Copy = styled.p`
  color: ${(p) => p.theme.color.gray};
  text-align: center;
  padding: 0 10px;
`;

export default function GoToV4({ closeModal }) {
  return (
    <StyledDialog
      type={dialogTypes.NONE}
      primaryButton={
        <Button primary onClick={redirectToNext}>
          {TEXT.v4CTA}
        </Button>
      }
      secondaryButton={
        <Button text onClick={closeModal}>
          {TEXT.stayHere}
        </Button>
      }
    >
      <StartAccountIllustrationContainer justifyCenter>
        <StartAccountIllustration />
      </StartAccountIllustrationContainer>
      <Header>{TEXT.headline}</Header>
      <Copy>{TEXT.message}</Copy>
    </StyledDialog>
  );
}
