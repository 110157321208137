import moment from 'moment';
import { get, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { cardEntitiesSelector } from '@frameio/core/src/cards/selectors';
import { firstSubscriptionEntityForAccountIdSelector } from '@frameio/core/src/subscriptions/selectors';
import { firstPlanEntityForAccount } from '@frameio/core/src/plans/selectors';
import ROLES from '@frameio/core/src/roles/helpers/constants';
import {
  accountEntitiesSelector,
  accountEntitySelector,
} from '@frameio/core/src/accounts/selectors';
import {
  planEntityForAccountIdSelector,
  subscriptionEntityForAccountIdSelector,
  teamEntitiesForAccountIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import {
  MEMBER_LIMIT,
  USER_LIMIT,
} from '@frameio/core/src/subscriptionLineItems/utils/modifierTypes';
import {
  MEMBER_COUNT,
  USER_COUNT,
} from '@frameio/core/src/accounts/utils/usageTypes';
import { planLineItemForModifierAndPlanSelector } from '@frameio/core/src/planLineItems/selectors';

import {
  projectByTeamFetchStatusSelector,
  sharedProjectsFetchStatusSelector,
} from '@frameio/core/src/projects/selectors';
import { teamsByAccountFetchStatusSelector } from '@frameio/core/src/teams/selectors';
import { status } from '@frameio/core/src/shared/reducers/factories';

import { accountIdsForAccountSwitcherSelector } from 'pages/LayoutWithNav/AccountSwitcher/selectors';
import {
  highestAccountOrTeamRoleForAccountIdSelector,
  isAccountAdminSelector,
  isTeamMemberForTeamSelector,
  isAccountOwnerSelector,
} from 'selectors/roles';
import { shouldAutoScaleTeamMembersAllocation } from 'selectors/subscriptions';
import { PLAN_TIERS } from 'components/SelectPlanFlow/PlanDisplay';
import { LATEST_BILLING_VERSION } from 'config';
import { planIds } from 'utils/plans/plansHelpers';

const MAX_DAYS = 6;

/**
 * @param   {Object} state - Redux store state.
 * @returns {Object} - State's currentAccount.
 */
export const currentAccountSelector = (state) => state.currentAccount;

export const planDataSelector = (state) => {
  const accountId = (currentAccountSelector(state) || {}).id;
  return planEntityForAccountIdSelector(state, { accountId });
};

export const hasUnlimitedTeamsSelector = (state) => {
  return planDataSelector(state).team_limit === null;
};

export const hasTeamLimitGreaterThanOneSelector = (state) => {
  return (
    hasUnlimitedTeamsSelector(state) || planDataSelector(state).team_limit > 1
  );
};

/**
 * @returns {Object} - Current account card entities
 */
export const currentAccountCardEntitiesSelector = createSelector(
  [currentAccountSelector, cardEntitiesSelector],
  (currentAccount, cards) =>
    Object.values(cards).reduce((acc, card) => {
      if (card.account_id === (currentAccount && currentAccount.id)) {
        acc[card.id] = card;
      }
      return acc;
    }, {})
);

/**
 * @returns {Object} - Current account's v2 account entity.
 */
export const currentAccountEntitySelector = createSelector(
  currentAccountSelector,
  accountEntitiesSelector,
  (currentAccount, accountEntities) => accountEntities[currentAccount.id]
);

export const isDrmEnabledForAccountSelector = createSelector(
  currentAccountEntitySelector,
  (currentAccountEntity) => Boolean(currentAccountEntity?.drm_configuration_id)
);

/**
 * @returns {Boolean} - Presentations disabled for current account entity.
 */
export const currentAccountPresentationsDisabledSelector = createSelector(
  currentAccountEntitySelector,
  (account) => account?.account_settings?.disable_presentations
);

export const daysToAccountLockoutSelector = createSelector(
  [currentAccountEntitySelector],
  (currentAccountEntity) => {
    if (!currentAccountEntity || !currentAccountEntity.delinquent_at) {
      return MAX_DAYS;
    }

    const daysToLockout = Math.ceil(
      moment(currentAccountEntity.delinquent_at).diff(moment(), 'days')
    );

    return Math.max(daysToLockout, 0);
  }
);

/**
 * @returns {Boolean} - If currentAccount is free.
 */
export function isAccountFreeSelector(state, { accountId }) {
  if (!accountId) return false;
  const plan = planEntityForAccountIdSelector(state, { accountId });

  return [
    planIds.FREE_LEGACY,
    planIds.FREE_LIFETIME_FILE_LIMIT,
    planIds.FREE_PRICING_V2,
    planIds.FREE_PRICING_V3,
    planIds.FREE_PRICING_V4,
    planIds.FREE_PRICING_V5,
    planIds.FREE_YEARLY,
  ].includes(plan.name);
}

export const isAccountEnterpriseSelector = (state) => {
  const accountId = (currentAccountSelector(state) || {}).id;
  const plan = planEntityForAccountIdSelector(state, { accountId }) || {};
  return !!plan.enterprise;
};

export const currentAccountWithSubscriptionAndPlanSelector = (state) => {
  const currentAccountId = (currentAccountSelector(state) || {}).id;
  const account =
    accountEntitySelector(state, { accountId: currentAccountId }) || {};
  const subscription = firstSubscriptionEntityForAccountIdSelector(state, {
    accountId: currentAccountId,
  });
  const plan = firstPlanEntityForAccount(state, {
    accountId: currentAccountId,
  });
  return { account, subscription, plan };
};

export const accountPlanVersionSelector = (state, { accountId }) => {
  const { version } = firstPlanEntityForAccount(state, { accountId }) || {};
  return version || LATEST_BILLING_VERSION;
};

export const accountPlanIdSelector = (state, { accountId }) => {
  const { id } = firstPlanEntityForAccount(state, { accountId }) || {};
  return id;
};

export const isAccountAdobeBridgeOfferEligible = (state, { accountId }) => {
  const { eligibility } = firstPlanEntityForAccount(state, { accountId }) || {};
  return eligibility === 'adobe_bridge_offer';
};

export const isAccountOnFreePlan = (state, { accountId }) => {
  const { tier } = firstPlanEntityForAccount(state, { accountId }) || {};
  return tier === PLAN_TIERS.FREE;
};

export const isAccountOnLegacyPlanSelector = (state, { accountId }) => {
  const plan = firstPlanEntityForAccount(state, { accountId });
  if (!accountId || !plan) return false;
  return plan.version < LATEST_BILLING_VERSION;
};

export function hasFileCountToUploadSelector(
  state,
  { accountId, numOfItemsToUpload }
) {
  const { lifetime_file_count: count } = accountEntitySelector(state, {
    accountId,
  });
  const { lifetime_file_limit: limit } = planEntityForAccountIdSelector(state, {
    accountId,
  });
  const proposedFileCount = count + numOfItemsToUpload;
  return limit === null || proposedFileCount <= limit;
}

function isCurrentAccountPlanV4(state) {
  const accountId = (currentAccountSelector(state) || {}).id;
  return accountPlanVersionSelector(state, { accountId }) === 4;
}

export function isAccountOnV5Plan(state) {
  const accountId = (currentAccountSelector(state) || {}).id;
  return accountPlanVersionSelector(state, { accountId }) === 5;
}

export function isAccountOnV6PlanOrGreater(state) {
  const accountId = (currentAccountSelector(state) || {}).id;
  return accountPlanVersionSelector(state, { accountId }) >= 6;
}

/**
 * Determines if the account is on a plan that has storage hard blocks.
 * Refers to the new (v5+), non-legacy hard block for storage.
 * Returns true for all v5 pricing plans and Free v6 plans.
 * @param   {Object} state - Redux store state.
 * @returns {boolean} - whether or not the account's plan has storage hard blocks.
 */
export function shouldShowStorageHardBlock(state) {
  const accountId = (currentAccountSelector(state) || {}).id;
  const { title, version } = planEntityForAccountIdSelector(state, {
    accountId,
  });
  if (!title || !version) return false;

  const isAccountOnV6FreePlan =
    isAccountOnV6PlanOrGreater(state) && title === 'Free';
  return isAccountOnV5Plan(state) || isAccountOnV6FreePlan;
}

/**
 * Determines if the account is on a Free v5 or v6 pricing plan since
 * having a user max differentiates them from older plans.
 * @param   {Object} state - Redux store state.
 * @returns {boolean} - whether or not the account is on a Free v5 or v6 pricing plan.
 */
export function isAccountOnFreePlanWithUserMaxSelector(state) {
  const accountId = (currentAccountSelector(state) || {}).id;
  if (!accountId) return false;
  const { title, version } = planEntityForAccountIdSelector(state, {
    accountId,
  });
  if (!title || !version) return false;
  return version >= 5 && title === 'Free';
}

/**
 * Determines if the account is on a v5 or v6 pricing plan since
 * having a user max differentiates them from older plans.
 * @param   {Object} state - Redux store state.
 * @returns {boolean} - whether or not the account is on a v5 or v6 pricing plan.
 */
export function isAccountOnPlanWithUserMaxSelector(state) {
  return isAccountOnV5Plan(state) || isAccountOnV6PlanOrGreater(state);
}

export function isOnPaidCollaboratorsPlanSelector(state) {
  return (
    isCurrentAccountPlanV4(state) ||
    isAccountOnV5Plan(state) ||
    isAccountOnV6PlanOrGreater(state)
  );
}

export function seatLimitPropertyName(state) {
  const isPaidCollaboratorsPlan = isOnPaidCollaboratorsPlanSelector(state);

  return isPaidCollaboratorsPlan ? USER_LIMIT : MEMBER_LIMIT;
}

export function getSeatUnit(state) {
  const isPaidCollaboratorsPlan = isOnPaidCollaboratorsPlanSelector(state);
  return isPaidCollaboratorsPlan ? 'seat' : 'team member seat';
}

export function seatCountPropertyName(state) {
  const isPaidCollaboratorsPlan = isOnPaidCollaboratorsPlanSelector(state);
  return isPaidCollaboratorsPlan ? USER_COUNT : MEMBER_COUNT;
}

export const getAvailableSeatsCountSelector = (state, { accountId }) => {
  if (!accountId) return null;

  const subscription = subscriptionEntityForAccountIdSelector(state, {
    accountId,
  });
  const seatLimitType = seatLimitPropertyName(state);
  const totalSeats = subscription[`total_${seatLimitType}`];

  if (totalSeats === null) return Infinity;

  const account = accountEntitySelector(state, { accountId });
  const seatCountType = seatCountPropertyName(state);
  const seatsUsed = account[seatCountType];

  return totalSeats - seatsUsed;
};

/**
 * Gets available seat count for v5+ pricing plans.
 * Pricing plans for v5 and v6 have a user_max property that other plans don't use.
 * @param {string} accountId - Account id.
 * @returns {number} - Available seat count for the plan.
 */
export const getAvailableSeatsCountForPlansWithUserMaxSelector = (
  state,
  { accountId }
) => {
  if (!accountId) return null;

  const plan = planEntityForAccountIdSelector(state, {
    accountId,
  });
  // a null value for limits means unlimited in our API
  const totalSeats = plan.user_max === null ? Infinity : plan.user_max;

  const account = accountEntitySelector(state, { accountId });
  const seatCountType = seatCountPropertyName(state);
  const seatsUsed = account[seatCountType];

  return totalSeats - seatsUsed;
};

// used by v5 and v6 pricing
export const seatsToAddToReachPlanMinUserLimitSelector = (
  state,
  { accountId }
) => {
  const isOnPlanWithUserMax = isAccountOnPlanWithUserMaxSelector(state);
  if (!isOnPlanWithUserMax || !accountId) return true;
  const plan = planEntityForAccountIdSelector(state, {
    accountId,
  });
  const minUserLimit = plan.user_limit;
  const account = accountEntitySelector(state, { accountId });
  const seatCountType = seatCountPropertyName(state);
  const seatsUsed = account[seatCountType];
  return minUserLimit > seatsUsed ? minUserLimit - seatsUsed : 0;
};

export const getIndividualSeatCostSelector = (state, { accountId }) => {
  const isPaidCollaboratorsPlan = isOnPaidCollaboratorsPlanSelector(state);
  if (!accountId || !isPaidCollaboratorsPlan) return null;
  const seatLimitType = seatLimitPropertyName(state);
  const plan = planEntityForAccountIdSelector(state, { accountId });
  const planId = plan.id;
  const planPeriod = plan.period;
  const isMonthlyPayment = planPeriod === 'monthly';
  const seatLineItem = planLineItemForModifierAndPlanSelector(state, {
    modifier: seatLimitType,
    planId,
  });
  return isMonthlyPayment
    ? get(seatLineItem, 'cost')
    : Math.floor(get(seatLineItem, 'cost') / 12);
};

export const lastViewedAccountIdSelector = (state) => state.lastViewedAccountId;

export const shouldShowAddSeatsFlow = (state, { accountId, invitesCount }) => {
  const isLegacyPlan = isAccountOnLegacyPlanSelector(state, { accountId });
  const shouldAutoScaleTeamMembersUsage = shouldAutoScaleTeamMembersAllocation(
    state,
    { accountId }
  );
  const isEnterprise = isAccountEnterpriseSelector(state);

  if (isLegacyPlan || shouldAutoScaleTeamMembersUsage || isEnterprise)
    return false;

  const availableSeatsCount = getAvailableSeatsCountSelector(state, {
    accountId,
  });

  return invitesCount > availableSeatsCount;
};

export const hasAccountReachedUserCountLimitSelector = createSelector(
  accountEntitySelector,
  subscriptionEntityForAccountIdSelector,
  seatCountPropertyName,
  seatLimitPropertyName,
  isAccountOnPlanWithUserMaxSelector,
  planEntityForAccountIdSelector,
  (
    currentAccount,
    subscription,
    seatCountType,
    seatLimitType,
    isAccountOnPlanWithUserMax,
    plan
  ) => {
    if (!currentAccount) return false;
    const seatCount = currentAccount[seatCountType];
    const totalSeatCount = isAccountOnPlanWithUserMax
      ? plan.user_max
      : subscription[`total_${seatLimitType}`];

    return seatCount >= totalSeatCount;
  }
);

export const totalSeatLimitForAccountSelector = createSelector(
  seatLimitPropertyName,
  subscriptionEntityForAccountIdSelector,
  (seatLimitType, subscription) => subscription[`total_${seatLimitType}`]
);

export const totalStorageLimitForAccountSelector = createSelector(
  subscriptionEntityForAccountIdSelector,
  firstPlanEntityForAccount,
  isAccountOnLegacyPlanSelector,
  isAccountOnV5Plan,
  isAccountOnFreePlanWithUserMaxSelector,
  (subscription, plan, isLegacy, isOnV5Plan, isOnFreePlanWithUserMax) =>
    isLegacy || isOnV5Plan || isOnFreePlanWithUserMax
      ? plan.storage_limit
      : subscription.total_storage_limit
);

export const totalCollaboratorSeatCountForAccountSelector = createSelector(
  firstPlanEntityForAccount,
  isOnPaidCollaboratorsPlanSelector,
  (plan, isOnPaidCollaboratorsPlan) => {
    if (isOnPaidCollaboratorsPlan) return null;
    return plan.collaborator_limit;
  }
);

export const hasAccountWithTeamRoleAtMinimumSelector = (state) => {
  const teamMembershipRoles = [
    ROLES.OWNER,
    ROLES.ADMIN,
    ROLES.TEAM_MANAGER,
    ROLES.TEAM_MEMBER,
  ];
  const userAccountIds = accountIdsForAccountSwitcherSelector(state);
  return userAccountIds.some((accountId) => {
    const role = highestAccountOrTeamRoleForAccountIdSelector(state, {
      accountId,
    });
    return teamMembershipRoles.includes(role);
  });
};

export const ownedAccountSelector = createSelector(
  accountIdsForAccountSwitcherSelector,
  (state) => state,
  (accountIds, state) =>
    accountIds.find((accountId) => isAccountOwnerSelector(state, { accountId }))
);

export const hasAccountWithOwnerRoleSelector = createSelector(
  accountIdsForAccountSwitcherSelector,
  (state) => state,
  (accountIds, state) =>
    accountIds.some((accountId) => isAccountOwnerSelector(state, { accountId }))
);

export const hasAccountsSelector = createSelector(
  accountIdsForAccountSwitcherSelector,
  (state) => state,
  (accountIds) => accountIds.length > 0
);

/**
 * @param {string} accountId - Account id.
 * Returns the default team for a given account, which is the first sorted team
 * that a user is an Admin or Team Member of that has at least one project.
 */
export const defaultTeamForAccountIdSelector = createSelector(
  teamEntitiesForAccountIdSelector,
  (state, { accountId }) => accountId,
  (state) => state,
  (teamEntities, accountId, state) =>
    teamEntities.find((team) => {
      const isAdmin = isAccountAdminSelector(state, { accountId });
      const isTeamMember = isTeamMemberForTeamSelector(state, {
        teamId: team.id,
      });
      return (isAdmin || isTeamMember) && team.project_count > 0;
    })
);

/**
 * @param {string} accountId - Account id.
 * @returns {string[]} - An array of team ids belonging to a given account id,
 * sorted ascending by team name.
 */
export const sortedTeamIdsForAccountIdSelector = createSelector(
  teamEntitiesForAccountIdSelector,
  (teamEntities = []) => teamEntities.map((team) => team.id)
);

/**
 * @param {string} accountId - Account id.
 * @returns {string[]} - An array of team ids for an account id of which the
 * current user is an account admin or team member.
 */
export const teamIdsWithAdminOrMemberRoleForAccountIdSelector = createSelector(
  sortedTeamIdsForAccountIdSelector,
  (state, { accountId }) => accountId,
  (state) => state,
  (teamIds, accountId, state) => {
    const teamIdsWithAdminOrMemberRole = [];
    teamIds.forEach((teamId) => {
      const isAdmin = isAccountAdminSelector(state, { accountId });
      const isTeamMember = isTeamMemberForTeamSelector(state, { teamId });
      if (isAdmin || isTeamMember) teamIdsWithAdminOrMemberRole.push(teamId);
    });
    return teamIdsWithAdminOrMemberRole;
  }
);

/**
 * ⚠️ Please read before using this selector! ⚠️
 * This selector derives team membership based on a given _team entity_
 * and NOT from a _role entity_.
 * @param {string} accountId - Account id.
 * @returns {string[]} - An array of team ids for an account id of which the
 * current user is an account admin or team member.
 */
export const teamIdsWithAdminOrMemberRoleFromEntitySelector = createSelector(
  teamEntitiesForAccountIdSelector,
  isAccountAdminSelector,
  (teamEntities, isAdmin) => {
    const teamsWithAdminOrMemberRole = [];
    teamEntities.forEach((team) => {
      const teamRole = get(team, 'user_role.team_role');
      const isTeamManager = teamRole === ROLES.TEAM_MANAGER;
      const isTeamMember = teamRole === ROLES.TEAM_MEMBER;

      if (isAdmin || isTeamManager || isTeamMember)
        teamsWithAdminOrMemberRole.push(team);
    });

    return sortBy(
      teamsWithAdminOrMemberRole,
      ({ name }) => name && name.toLowerCase()
    ).map((team) => team.id);
  }
);

/**
 * @param {string} - Account id.
 * @returns {boolean} - Whether teams and project entities have
 * been fetched for a given account.
 */
export const hasFetchedAccountDataSelector = createSelector(
  teamsByAccountFetchStatusSelector,
  teamIdsWithAdminOrMemberRoleForAccountIdSelector,
  projectByTeamFetchStatusSelector,
  sharedProjectsFetchStatusSelector,
  (state, { accountId }) => accountId,
  (
    teamsByAccountFetchStatus,
    sortedTeamIdsForAcccountId,
    projectByTeamFetchStatus,
    sharedProjectsFetchStatus,
    accountId
  ) => {
    const projectsForTeamsFetched = sortedTeamIdsForAcccountId.every(
      (teamId) =>
        projectByTeamFetchStatus[teamId] === status.SUCCESS ||
        projectByTeamFetchStatus[teamId] === status.FAILURE
    );

    const teamsForAccountFetched =
      teamsByAccountFetchStatus === status.SUCCESS ||
      teamsByAccountFetchStatus === status.FAILURE;

    const sharedProjectsForAccountFetched =
      sharedProjectsFetchStatus[accountId] === status.SUCCESS ||
      sharedProjectsFetchStatus[accountId] === status.FAILURE;

    return !!(
      teamsForAccountFetched &&
      projectsForTeamsFetched &&
      sharedProjectsForAccountFetched
    );
  }
);

export function shouldShowUserHardBlockSelector(state) {
  const accountId = currentAccountSelector(state).id;
  const isEnterprise = isAccountEnterpriseSelector(state);
  const isPlanWithUserMax = isAccountOnPlanWithUserMaxSelector(state);
  const availableSeats = getAvailableSeatsCountForPlansWithUserMaxSelector(
    state,
    { accountId }
  );
  return isPlanWithUserMax && !isEnterprise && availableSeats === 0;
}

export function isTaxLocationDeterminedSelector(state, { accountId }) {
  if (!accountId) return true;
  const account = accountEntitySelector(state, { accountId });
  // default prevents tax banner or modal from showing before request to stripe is finished
  return account?.tax_location_determined ?? true;
}

/**
 * @param   {Object} state - Redux store state.
 * @returns {Array} - An array of allowed domains for the current account.
 */
export const allowedDomainsSelector =
  createSelector(
    currentAccountEntitySelector,
    (currentAccountEntity) => currentAccountEntity?.allowed_domains
  ) || [];

/**
 * @param   {Object} state - Redux store state.
 * @returns {Boolean} - Whether the current account has allowed domains enabled.
 */
export const isAllowedDomainsEnabled = createSelector(
  currentAccountEntitySelector,
  (currentAccountEntity) =>
    Boolean(currentAccountEntity?.available_features?.allowed_domains)
);

export const accountMigrationEligibilitySelector = (state) =>
  state.accountMigrationEligibility;

export const currentAccountSettingsPermissionsSelector = createSelector(
  currentAccountSelector,
  (state) => state,
  (currentAccount, state) =>
    state.accountSettingsPermissions[(currentAccount?.id)]
);
