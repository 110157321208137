import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Router, Route, Switch } from 'react-router-dom';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import { preload as preloadLogoSpinner } from 'components/LogoSpinner';
import AddCreditCard from './CreditCardForms/AddCreditCard';
import history from './history';
import PaymentProcessing from './PaymentProcessing';

export const UPDATE_CARD_URL = '/UpdateCreditCard';
export const PAYMENT_PROCESSING_URL = '/PaymentProcessing';

const Container = styled.div`
  position: relative;
  width: 400px;
  min-height: 350px;
  padding: 16px;
  background-color: ${(p) => p.theme.color.white};
  border: solid 1px ${(p) => p.theme.color.lightGray};
  border-radius: ${(p) => p.theme.radius.large};
  box-shadow: ${(p) => p.theme.shadow.smallSubtle};
  max-height: 100vh;
  overflow-y: scroll;
`;

export default class PaymentFlow extends React.Component {
  componentDidMount() {
    const {
      accountId,
      startFlow,
      trackingProps,
      location: {
        state: { onPaymentMethodReady },
      },
    } = this.props;
    startFlow(accountId, onPaymentMethodReady, trackingProps);

    // Load the gif the for spinner now, so it shows up seamlessly when
    // `PaymentProcessing` shows up later.
    preloadLogoSpinner();
  }

  componentWillUnmount() {
    const { endFlow, setPaymentError } = this.props;
    endFlow();
    setPaymentError(null);
  }

  render() {
    const {
      accountId,
      continueFlow,
      isFreePlanWithUserMaxSelected,
      isCheckoutFlowV2Enabled,
      onCancel,
      trackingProps,
      canCloseModal,
    } = this.props;
    return (
      <Container>
        {canCloseModal && <ModalCloseButton onClick={onCancel} />}
        <Router history={history}>
          <Switch>
            <Route
              path={UPDATE_CARD_URL}
              render={(props) => (
                <AddCreditCard
                  accountId={accountId}
                  onContinue={continueFlow}
                  onCancel={onCancel}
                  trackingProps={trackingProps}
                  {...props}
                />
              )}
            />
            <Route
              path={PAYMENT_PROCESSING_URL}
              component={(props) => (
                <PaymentProcessing
                  isFreePlanWithUserMaxSelected={isFreePlanWithUserMaxSelected}
                  isCheckoutFlowV2Enabled={isCheckoutFlowV2Enabled}
                  {...props}
                />
              )}
            />
          </Switch>
        </Router>
      </Container>
    );
  }
}

PaymentFlow.defaultProps = {
  canCloseModal: false,
};

PaymentFlow.propTypes = {
  accountId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  continueFlow: PropTypes.func.isRequired,
  startFlow: PropTypes.func.isRequired,
  endFlow: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  trackingProps: PropTypes.object.isRequired,
  canCloseModal: PropTypes.bool,
};

PaymentFlow.defaultProps = {};

export const testExports = {};
