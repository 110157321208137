import React from 'react';
import {
  call,
  spawn,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { get } from 'lodash';
import history from 'browserHistory';
import track from 'analytics';
import Raven from 'raven-js';
import {
  upsertAccountAndTeamForMe,
  getAccount,
  updateAccount,
} from '@frameio/core/src/accounts/sagas';
import {
  applyPromotionToAccount as applyPromotion,
  applyStarterTrialToAccount as applyStarterTrial,
  fetchPromotion,
} from '@frameio/core/src/promotions/sagas';
import { updateTeam as updateTeamSaga } from '@frameio/core/src/teams/sagas';
import { AUTHED_USER, updateUser } from '@frameio/core/src/users/actions';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { userEntitySelector } from '@frameio/core/src/users/selectors';
import { getEntityFromNormalizedResponse } from '@frameio/core/src/shared/utils/entities';
import {
  cloneProject,
  getProjectsForTeam,
} from '@frameio/core/src/projects/sagas';
import { projectByTeamFetchStatusSelector } from '@frameio/core/src/projects/selectors';
import { status } from '@frameio/core/src/shared/reducers/factories';

import {
  createPendingAction,
  createSuccessAction,
  createFailureAction,
} from '@frameio/core/src/shared/actions/helpers';
import config from 'config';
import { getTeamUrl } from 'URLs';
import { redirectToProject } from 'sagas/projects';
import { selectFilesFromInput } from 'sagas/shared';
import {
  currentAccountSelector,
  ownedAccountSelector,
} from 'selectors/accounts';
import { currentUserEntitySelector } from 'selectors/users';
import { currentTeamEntitySelector } from 'selectors/teams';
import { setCurrentAccount } from 'actions/accounts';
import { setCurrentTeam } from 'actions/teams';
import {
  showErrorToast as showErrorToastAction,
  showSuccessToast,
} from 'actions/toasts';
import { MODAL, closeModal, openModal } from 'components/Modal/actions';
import { ADD_TEAM_MEMBERS_MODAL } from 'components/AddTeamMembers/actions';
import ConnectedCreateProjectForm from 'components/ProjectForms/CreateProject';
import { setTeamId } from 'components/ProjectForms/CreateProject/actions';
import { selectAndUploadImage } from 'pages/SettingsContainer/BrandingContainer/ImageWithUpload/sagas';
import { syncAccountData } from 'sagas/accounts';
import {
  SETUP_ACCOUNT_FLOW,
  continueFlow,
  goToV4,
  isApplyingPromotion,
  endFlow,
} from './actions';
import flowHistory from './history';
import {
  ADD_TEAM_MEMBERS_URL,
  START_SETUP_ACCOUNT_URL,
  START_SETUP_ACCOUNT_OR_GO_TO_V4_URL,
  NAME_TEAM_URL,
  SUCCESS_URL,
  UPLOAD_TEAM_IMAGE_URL,
  UPLOAD_IMAGE_FOR_ME_URL,
  GO_TO_V4_URL,
} from './SetupAccountFlow';
import { START_ACCOUNT_STEP } from './StartAccount/StartAccount';
import { NAME_YOUR_TEAM_STEP } from './NameTeam/NameTeam';
import { UPLOAD_USER_IMAGE_STEP } from './UploadImageForMe/ConnectedUploadImageForMe';
import { UPLOAD_TEAM_IMAGE_STEP } from './UploadTeamImage/ConnectedUploadTeamImage';
import { SETUP_ACCOUNT_SUCCESS_STEP } from './Success/Success';
// used for analytics
const SETUP_ACCOUNT_FLOW_SHOWN = 'setup-account-flow-shown';

const CHANGE_TEAM_NAME_ERROR =
  'Failed to change team name, please try again later';
const CHANGE_ACCOUNT_NAME_ERROR =
  'Failed to change account name, please try again later';
const UPLOAD_IMAGE_ERROR = 'Failed to upload image, please try again later';
const REMOVE_IMAGE_ERROR = 'Failed to delete image, please try again later';
const REMOVE_IMAGE_SUCCESS = 'Image successfully deleted';
const CREATE_DEMO_PROJECT_ERROR =
  'Failed to create a demo project, please try again later';
const CREATE_ACCOUNT_ERROR =
  'Failed to create your own account, please try again later';
const TOAST_DELAY = 3000;

function* showErrorToast(errorMessage) {
  yield put(
    showErrorToastAction({
      header: errorMessage,
      autoCloseDelay: TOAST_DELAY,
    })
  );
}

function* endSetupAccountFlow() {
  yield put(closeModal());
  yield put(endFlow());
}

function* createAndRedirectToDemoProject(teamId) {
  const { success, failure } = yield call(cloneProject, config.demoProjectId);

  if (failure) {
    yield call(showErrorToast, CREATE_DEMO_PROJECT_ERROR);
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to create and redirect to demo project in Setup Account Flow',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
    return;
  }

  const projectId = success.payload.response.result;

  // need to fetch the projects for the team so the sidebar updates with the demo project included
  const projectsFetchStatus = yield select(projectByTeamFetchStatusSelector);
  if (projectsFetchStatus[teamId] !== status.SUCCESS) {
    yield call(getProjectsForTeam, teamId);
  }
  yield call(redirectToProject, projectId);
  yield call(endSetupAccountFlow);
}

function* createProject(teamId) {
  yield put(setTeamId(teamId));
  yield put(openModal(<ConnectedCreateProjectForm />));
}

function* watchSetupAccountFlow() {
  // Step 1: Show initial screen to create an account, apply default promotion and create new team
  yield call(flowHistory.push, START_SETUP_ACCOUNT_URL);
  yield spawn(track, SETUP_ACCOUNT_FLOW_SHOWN, { step: START_ACCOUNT_STEP });
  yield take(SETUP_ACCOUNT_FLOW.CONTINUE);

  const { success, failure } = yield call(upsertAccountAndTeamForMe);

  if (failure) {
    yield call(showErrorToast, CREATE_ACCOUNT_ERROR);
    yield call(endSetupAccountFlow());
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to create default team for account in Setup Account Flow',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
    return;
  }

  const accountId = get(success, 'payload.response.result');
  const account = yield select(accountEntitySelector, { accountId });
  yield put(setCurrentAccount(accountId));

  const teamId = account.teams[0];
  yield put(setCurrentTeam(teamId));

  // Redirect to the newly created team so we can guarantee that `currentAccount` and `currentTeam`
  // correspond to the newly created account and created team
  const teamUrl = getTeamUrl(accountId, teamId);
  yield call(history.push, teamUrl);

  // Step 2: Show screen to name team
  yield call(flowHistory.push, NAME_TEAM_URL);
  yield spawn(track, SETUP_ACCOUNT_FLOW_SHOWN, { step: NAME_YOUR_TEAM_STEP });
  yield take(SETUP_ACCOUNT_FLOW.CONTINUE);

  // Step 3: Show screen to invite members
  yield call(flowHistory.push, ADD_TEAM_MEMBERS_URL);
  yield spawn(track, SETUP_ACCOUNT_FLOW_SHOWN, { step: 'add members' });
  // user decides to add team members or skip the step
  yield take([
    ADD_TEAM_MEMBERS_MODAL.ADD_TEAM_MEMBERS,
    SETUP_ACCOUNT_FLOW.CONTINUE,
  ]);

  // Step 4: Show screen to upload team image
  yield call(flowHistory.push, UPLOAD_TEAM_IMAGE_URL);
  yield spawn(track, SETUP_ACCOUNT_FLOW_SHOWN, {
    step: UPLOAD_TEAM_IMAGE_STEP,
  });
  yield take(SETUP_ACCOUNT_FLOW.CONTINUE);

  // Step 5: Show screen to upload user image
  yield call(flowHistory.push, UPLOAD_IMAGE_FOR_ME_URL);
  yield spawn(track, SETUP_ACCOUNT_FLOW_SHOWN, {
    step: UPLOAD_USER_IMAGE_STEP,
  });
  yield take(SETUP_ACCOUNT_FLOW.CONTINUE);

  // Step 6: Show success screen
  yield call(flowHistory.push, SUCCESS_URL);
  yield spawn(track, SETUP_ACCOUNT_FLOW_SHOWN, {
    step: SETUP_ACCOUNT_SUCCESS_STEP,
  });

  // Fetch the newly created account so the account switcher becomes available to the user.
  yield call(getAccount, accountId);

  const [shouldCreateProject, shouldCreateDemoProject] = yield race([
    take(SETUP_ACCOUNT_FLOW.CREATE_PROJECT),
    take(SETUP_ACCOUNT_FLOW.CREATE_DEMO_PROJECT),
  ]);

  if (shouldCreateProject) {
    yield call(createProject, teamId);
  } else if (shouldCreateDemoProject) {
    // If the user doesn't create a new project at the end of the flow, we want to create a demo
    // project and redirect the user to it
    yield call(createAndRedirectToDemoProject, teamId);
  }
  yield spawn(track, 'setup-account-flow-submitted');
}

// https://redux-saga.js.org/docs/advanced/TaskCancellation.html
function* startSetupAccountFlow(accountId) {
  const flow = yield fork(watchSetupAccountFlow, accountId);
  yield take([MODAL.CLOSE, SETUP_ACCOUNT_FLOW.END]);
  yield cancel(flow);
}

function* startSetupAccountOrGoToV4(accountId) {
  yield call(flowHistory.push, START_SETUP_ACCOUNT_OR_GO_TO_V4_URL, accountId);
}

function* startGoToV4() {
  yield call(flowHistory.push, GO_TO_V4_URL);
}

function* changeAccountName(name) {
  const { id: accountId } = yield select(currentAccountSelector);
  const { failure } = yield call(updateAccount, accountId, {
    // Trim leading spaces because we don't want them.
    display_name: name.trim(),
  });

  if (failure) {
    yield call(showErrorToast, CHANGE_ACCOUNT_NAME_ERROR);
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to change account name in Setup Account Flow',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
  }
}

function* changeTeamName(teamName) {
  const { id: teamId } = yield select(currentTeamEntitySelector);
  const data = { name: teamName };

  const { failure } = yield call(updateTeamSaga, teamId, data);

  if (failure) {
    yield call(showErrorToast, CHANGE_TEAM_NAME_ERROR);
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to change team name in Setup Account Flow',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
  }
}

// We want to use the same name for the team and the account
function* changeTeamAndAccountName(name) {
  yield call(changeTeamName, name);
  yield call(changeAccountName, name);
  yield spawn(track, 'name-your-team-step-submitted', { name });
  yield put(continueFlow());
}

function* applyPromotionToMyAccount(promoCode) {
  yield put(isApplyingPromotion(true));

  const { id: userId } = yield select(currentUserEntitySelector);
  const { account_id: accountId } = yield select(userEntitySelector, {
    userId,
  });

  // We can't use `currentAccount` since it's set to the account that invited the collaborator.
  // Instead, we need to get the account owned by the collaborator to apply the default promotion to
  // it.
  const { failure } = yield call(applyPromotion, accountId, promoCode);
  yield put(isApplyingPromotion(false));

  if (failure) {
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to apply promotion in Setup Account Flow',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
  }

  yield spawn(track, 'start-account-step-submitted', { promoCode });

  yield put(continueFlow());
}

function* applyStarterTrialToMyAccount() {
  yield put(isApplyingPromotion(true));
  let accountId;
  let fromAdobe;

  const { success, failure } = yield call(upsertAccountAndTeamForMe);
  const failureResponse409 =
    failure && failure.payload.error.response.status === 409;

  // if we successfully created an account or the account already exists, we can proceed.
  // otherwise, we need to show the user an error message
  if (success || failureResponse409) {
    const ownedAccountId = yield select(ownedAccountSelector);
    const ownedAccount = yield select(accountEntitySelector, {
      accountId: ownedAccountId,
    });
    accountId = ownedAccountId;
    fromAdobe = ownedAccount?.managed_by_adobe;

    yield call(syncAccountData);
  } else {
    yield call(
      showErrorToast,
      'Failed to create your account, please try again.'
    );

    yield spawn(
      [Raven, Raven.captureMessage],
      'Failed to create account in Setup Account Flow',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );

    yield put(isApplyingPromotion(false));
    yield call(endSetupAccountFlow);
    return;
  }

  // We can't use `currentAccount` since it's set to the account that invited the collaborator.
  // Instead, we need to get the account owned by the collaborator to apply the default promotion to
  // it.
  const { success: applyTrialSuccess, failure: applyTrialFailure } = yield call(
    applyStarterTrial,
    accountId
  );
  const promotionId = applyTrialSuccess?.payload?.response.result;

  yield put(isApplyingPromotion(false));

  if (applyTrialFailure) {
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to apply starter trial in Setup Account Flow',
      {
        level: 'error',
        extra: { error: applyTrialFailure.payload.error },
      }
    );
  }

  yield spawn(track, 'start-account-step-submitted', {
    promotionId,
    from_adobe: fromAdobe,
  });

  yield put(goToV4());
}

function* uploadAccountImage(file, accountId) {
  yield put(
    createPendingAction(SETUP_ACCOUNT_FLOW.UPLOADING_ACCOUNT_IMAGE.PENDING)
  );

  const { failure, success } = yield call(updateAccount, accountId, {
    image: {
      type: file.type,
      uploaded: true,
    },
  });

  if (failure) {
    yield call(showErrorToast, UPLOAD_IMAGE_ERROR);
    yield put(
      createFailureAction(SETUP_ACCOUNT_FLOW.UPLOADING_ACCOUNT_IMAGE.FAILURE)
    );
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to upload account image in Setup Account Flow',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
    return;
  }

  const account = yield call(
    getEntityFromNormalizedResponse,
    success.payload.response
  );
  const uploadUrl = account.upload_url;
  const newImageUrl = account.image_64;

  const uploadSuccessful = yield call(
    selectAndUploadImage,
    uploadUrl,
    file,
    newImageUrl
  );

  if (!uploadSuccessful) {
    yield put(
      createFailureAction(SETUP_ACCOUNT_FLOW.UPLOADING_ACCOUNT_IMAGE.FAILURE)
    );
  }

  yield put(
    createSuccessAction(SETUP_ACCOUNT_FLOW.UPLOADING_ACCOUNT_IMAGE.SUCCESS)
  );
}

function* uploadTeamImage(file, teamId) {
  // When a user uploads a team image to a given team, we need to update the team passing the
  // dropped / selected file so the backend can create the URL for this file first. At this point,
  // the team image url is created but it doesn't return an image yet. We then proceed with the
  // upload where `selectAndUploadImage` is responsible for polling the team image url when it's
  // available.

  yield put(
    createPendingAction(SETUP_ACCOUNT_FLOW.UPLOADING_TEAM_IMAGE.PENDING)
  );

  const { failure, success } = yield call(updateTeamSaga, teamId, {
    image: {
      type: file.type,
      uploaded: true,
    },
  });

  if (failure) {
    yield call(showErrorToast, UPLOAD_IMAGE_ERROR);
    yield put(
      createFailureAction(SETUP_ACCOUNT_FLOW.UPLOADING_TEAM_IMAGE.FAILURE)
    );
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to upload team image in Setup Account Flow',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
    return;
  }

  const team = yield call(
    getEntityFromNormalizedResponse,
    success.payload.response
  );
  const uploadUrl = team.upload_url;
  const newImageUrl = team.image_128;

  const uploadSuccessful = yield call(
    selectAndUploadImage,
    uploadUrl,
    file,
    newImageUrl
  );

  if (!uploadSuccessful) {
    yield put(
      createFailureAction(SETUP_ACCOUNT_FLOW.UPLOADING_TEAM_IMAGE.FAILURE)
    );
  }

  yield put(
    createSuccessAction(SETUP_ACCOUNT_FLOW.UPLOADING_TEAM_IMAGE.SUCCESS)
  );
}

// We want to use the same image for the team and the account
function* uploadTeamAndAccountImage(file, teamId, accountId) {
  yield call(uploadTeamImage, file, teamId);
  yield call(uploadAccountImage, file, accountId);
  yield spawn(track, 'upload-team-image-successful');
}

function* uploadImageForMe(file) {
  // When a user uploads its profile image, we need to update the user passing the
  // dropped / selected file so the backend can create the URL for this file first. At this point,
  // the user image url is created but it doesn't return an image yet. We then proceed with the
  // upload where `selectAndUploadImage` is responsible for polling the user image url when it's
  // available.

  yield put(
    createPendingAction(SETUP_ACCOUNT_FLOW.UPLOADING_IMAGE_FOR_ME.PENDING)
  );

  // This action sets up the upload_url for the user so we can use it to upload the image.
  yield put(
    updateUser({
      image: {
        type: file.type,
        uploaded: true,
      },
    })
  );

  const { failure, success } = yield race({
    success: take(AUTHED_USER.UPDATE.SUCCESS),
    failure: take(AUTHED_USER.UPDATE.FAILURE),
  });

  if (failure) {
    yield call(showErrorToast, UPLOAD_IMAGE_ERROR);
    yield put(
      createFailureAction(SETUP_ACCOUNT_FLOW.UPLOADING_IMAGE_FOR_ME.FAILURE)
    );
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to upload profile image in Setup Account Flow',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
    return;
  }

  const user = yield call(
    getEntityFromNormalizedResponse,
    success.payload.response
  );
  const uploadUrl = user.upload_url;
  const newImageUrl = user.image_128;

  const uploadSuccessful = yield call(
    selectAndUploadImage,
    uploadUrl,
    file,
    newImageUrl
  );

  if (!uploadSuccessful) {
    yield put(
      createFailureAction(SETUP_ACCOUNT_FLOW.UPLOADING_IMAGE_FOR_ME.FAILURE)
    );
  }

  yield put(
    createSuccessAction(SETUP_ACCOUNT_FLOW.UPLOADING_IMAGE_FOR_ME.SUCCESS)
  );

  yield spawn(track, 'upload-user-image-successful');
}

function* uploadDroppedTeamImage(file, teamId) {
  const { id: accountId } = yield select(currentAccountSelector);

  // We want to use the same image for the team and the account
  yield call(uploadTeamAndAccountImage, file, teamId, accountId);
}

function* uploadSelectedTeamImage(teamId) {
  const [file] = yield call(selectFilesFromInput);
  const { id: accountId } = yield select(currentAccountSelector);

  // We want to use the same image for the team and the account
  yield call(uploadTeamAndAccountImage, file, teamId, accountId);
}

function* uploadDroppedImageForMe(file) {
  yield call(uploadImageForMe, file);
}

function* uploadSelectedImageForMe() {
  const [file] = yield call(selectFilesFromInput);
  yield call(uploadImageForMe, file);
}

function* removeTeamImage(teamId) {
  const { failure } = yield call(updateTeamSaga, teamId, {
    image: {
      uploaded: false,
    },
  });

  if (failure) {
    yield call(showErrorToast, REMOVE_IMAGE_ERROR);
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to remove team image in Setup Account Flow',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
    return;
  }

  yield put(
    showSuccessToast({
      header: REMOVE_IMAGE_SUCCESS,
      autoCloseDelay: TOAST_DELAY,
    })
  );

  yield spawn(track, 'team-image-removed');
}

function* removeImageForMe() {
  yield put(
    updateUser({
      image: {
        uploaded: false,
      },
    })
  );

  const { failure } = yield race({
    success: take(AUTHED_USER.UPDATE.SUCCESS),
    failure: take(AUTHED_USER.UPDATE.FAILURE),
  });

  if (failure) {
    yield call(showErrorToast, REMOVE_IMAGE_ERROR);
    yield call(
      [Raven, Raven.captureMessage],
      'Failed to remove profile image in Setup Account Flow',
      {
        level: 'error',
        extra: { error: failure.payload.error },
      }
    );
    return;
  }

  yield put(
    showSuccessToast({
      header: REMOVE_IMAGE_SUCCESS,
      autoCloseDelay: TOAST_DELAY,
    })
  );

  yield spawn(track, 'user-image-removed');
}

export const testExports = {
  watchSetupAccountFlow,
  startSetupAccountFlow,
  createProject,
  createAndRedirectToDemoProject,
  changeTeamAndAccountName,
  uploadAccountImage,
  uploadTeamAndAccountImage,
  applyPromotionToMyAccount,
  applyStarterTrialToMyAccount,
  uploadTeamImage,
  uploadImageForMe,
  removeTeamImage,
  removeImageForMe,
  SETUP_ACCOUNT_FLOW_SHOWN,
};

export default [
  takeLatest(SETUP_ACCOUNT_FLOW.START, ({ payload: { accountId } }) =>
    startSetupAccountFlow(accountId)
  ),
  takeLatest(
    SETUP_ACCOUNT_FLOW.SETUP_ACCOUNT_OR_GO_TO_V4,
    ({ payload: { accountId } }) => startSetupAccountOrGoToV4(accountId)
  ),
  takeLatest(SETUP_ACCOUNT_FLOW.GO_TO_V4, startGoToV4),
  takeLatest(
    SETUP_ACCOUNT_FLOW.CHANGE_TEAM_AND_ACCOUNT_NAME,
    ({ payload: { name } }) => changeTeamAndAccountName(name)
  ),
  takeLatest(SETUP_ACCOUNT_FLOW.APPLY_PROMOTION, ({ payload: { promoCode } }) =>
    applyPromotionToMyAccount(promoCode)
  ),
  takeLatest(
    SETUP_ACCOUNT_FLOW.APPLY_STARTER_TRIAL,
    applyStarterTrialToMyAccount
  ),
  takeLatest(
    SETUP_ACCOUNT_FLOW.UPLOAD_DROPPED_TEAM_IMAGE,
    ({ payload: { file, teamId } }) => uploadDroppedTeamImage(file, teamId)
  ),
  takeLatest(
    SETUP_ACCOUNT_FLOW.UPLOAD_SELECTED_TEAM_IMAGE,
    ({ payload: { teamId } }) => uploadSelectedTeamImage(teamId)
  ),
  takeLatest(SETUP_ACCOUNT_FLOW.REMOVE_TEAM_IMAGE, ({ payload: { teamId } }) =>
    removeTeamImage(teamId)
  ),
  takeLatest(
    SETUP_ACCOUNT_FLOW.UPLOAD_DROPPED_IMAGE_FOR_ME,
    ({ payload: { file } }) => uploadDroppedImageForMe(file)
  ),
  takeLatest(
    SETUP_ACCOUNT_FLOW.UPLOAD_SELECTED_IMAGE_FOR_ME,
    uploadSelectedImageForMe
  ),
  takeLatest(SETUP_ACCOUNT_FLOW.REMOVE_IMAGE_FOR_ME, removeImageForMe),
  takeLatest(SETUP_ACCOUNT_FLOW.FETCH_PROMOTION, ({ payload: { promoCode } }) =>
    fetchPromotion(promoCode)
  ),
];
