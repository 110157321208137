import React from 'react';
import track, { trackViewer } from 'analytics';
import { get } from 'lodash';
import {
  all,
  call,
  debounce,
  put,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  getAssetChildren,
  getFrameGrab,
  clearAsset,
  ASSET,
} from '@frameio/core/src/assets/actions';
import { getTeam } from '@frameio/core/src/teams/sagas';
import {
  getAccountByResource,
  updateLastViewedProjectForAccount,
} from '@frameio/core/src/accounts/services';
import { teamEntitySelector } from '@frameio/core/src/teams/selectors';
import {
  getCommentsByAsset,
  COMMENT_BY_ASSET,
} from '@frameio/core/src/comments/actions';
import {
  assetEntitySelector,
  assetFrameGrabSelector,
} from '@frameio/core/src/assets/selectors';
import {
  createViewAssetImpression,
  getImpressionsByAsset,
} from '@frameio/core/src/assetImpressions/actions';
import { type as ASSET_TYPE } from '@frameio/core/src/assets/helpers/constants';
import { getAsset } from '@frameio/core/src/assets/sagas';
import { getAsset as getAssetService } from '@frameio/core/src/assets/services';
import { getProject } from '@frameio/core/src/projects/sagas';
import { commentEntitySelector } from '@frameio/core/src/comments/selectors';
import { projectEntitySelector } from '@frameio/core/src/projects/selectors';

import { error, priority } from 'components/Dialog/SimpleDialog/sagas';
import { openModal } from 'components/Modal/actions';
import { reorderVersionInStack } from 'components/ManageVersionStack/sagas';
import { setCurrentAccount } from 'actions/accounts';
import { setCurrentProject } from 'actions/projects';
import { setCurrentFolder } from 'actions/folders';
import history from 'browserHistory';
import { currentAccountSelector } from 'selectors/accounts';
import { currentProjectSelector } from 'selectors/projects';
import { currentFolderSelector } from 'selectors/folders';
import { commentCountsByAssetSelector } from 'selectors/comments';
import { hasCurrentUserConfirmedEmailAddress } from 'selectors/users';
import { downloadUrlAsFile } from 'utils/downloads';
import { getMediaType } from '@frameio/core/src/assets/helpers/mediaTypes';
import { NEXT_VERSION_NUMBER } from 'config';
import { redirectToNext } from 'utils/router';
import RequestAccessToEntity from '../../components/RequestAccessToEntity/RequestAccessToEntity';
import { returnToAccount } from '../../components/RequestAccessToEntity/actions';
import { ENTITY_TYPE } from '../../components/AuthGate/AuthGate';
import {
  PLAYER_CONTAINER,
  setNewestCommentCreatedId,
  setRelationalData,
  showAssetNotFoundError,
} from './actions';

export function redirectToVersions(assetId, versionStackId) {
  const url = `/player/${versionStackId}?version=${assetId}`;
  history.replace(url);
}

export function* getAssetEntity(assetId) {
  const { failure } = yield call(getAsset, assetId);
  // This handles an edge case where a user loses access to an asset while
  // they're still in a session. If this is the case, we want to clear the asset
  // from the store.
  if (failure) {
    yield put(clearAsset(assetId));
    return null;
  }
  const asset = yield select(assetEntitySelector, { assetId });

  // [TODO]: make getAssetChildren optionally include metadata flag.
  // For now, if asset is a bundle, we want to make a get request for
  // each asset which will return its metadata.
  if (asset?.bundle) {
    yield all(
      Object.keys(asset.layout || {}).map((childAssetId) =>
        call(getAsset, childAssetId)
      )
    );
  }
  return asset;
}

export function* fetchAssetOrganizationData(assetEntity) {
  // have project_id. Fetch project, team, account.
  const projectId = assetEntity.project_id;
  const { failure } = yield call(getProject, projectId);
  if (failure) return {};

  const projectEntity = yield select(projectEntitySelector, { projectId });
  const teamId = projectEntity.team_id;
  yield call(getTeam, teamId);
  const teamEntity = yield select(teamEntitySelector, { teamId });

  if (!teamEntity) {
    const response = yield call(getAccountByResource, 'project', projectId);
    if (response?.version === NEXT_VERSION_NUMBER) {
      redirectToNext({
        path: `/project/${projectId}/view/${assetEntity.id}`,
      });
    }
  }
  // finally return organization ids.
  return {
    projectId: projectEntity.id,
    teamId,
    accountId: teamEntity.account_id,
  };
}

const FOLDER_CACHE_TIMEOUT = 15000;

export const folderCacheExpired = (lastSet) =>
  lastSet ? Date.now() - lastSet > FOLDER_CACHE_TIMEOUT : true;

export function* getParentAsset(assetParentId) {
  const { id: currentFolderId, lastSet } = yield select(
    currentFolderSelector
  ) || {};

  // If the asset's parent is the current folder, we don't need to fetch it or its children again.
  // We will reuse the current folder and its children for calls within the FOLDER_REFRESH_TIMEOUT ms.
  if (assetParentId === currentFolderId) {
    if (folderCacheExpired(lastSet)) {
      // If the folder cache has expired update the lastSet time as we are about to fetch the folder again.
      yield put(setCurrentFolder(assetParentId, currentFolderId));
    } else {
      return yield select(assetEntitySelector, { assetId: assetParentId });
    }
  }
  yield put(getAssetChildren(assetParentId));
  return yield call(getAssetEntity, assetParentId);
}

export function* fetchAssetHierarchicalData(assetEntity, versionStackId) {
  const assetParentId = assetEntity.parent_id;
  const parentAsset = yield call(getParentAsset, assetParentId);
  if (!parentAsset) return {};

  let folderId = parentAsset.id;

  if (parentAsset.type === ASSET_TYPE.VERSION_STACK && !versionStackId) {
    // I have a version stack but the URL showed we weren't in version stack.
    yield call(redirectToVersions, assetEntity.id, parentAsset.id);
  } else if (parentAsset.type === ASSET_TYPE.VERSION_STACK && versionStackId) {
    // Then i've fetched the parent version stack already the URL is correct.
    // Still need to fetch folder.
    folderId = parentAsset.parent_id;
    yield call(getParentAsset, folderId);
  }

  // finally return hierarchical ids.
  return {
    folderId,
  };
}

function isValidUUID(uuid) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

function* verifyAssetAccess(assetId) {
  // if there is an attempted malicious attack via a url like the below example:
  // https://app.dev.frame.io/player/4a60cca7-ce10-44ec-b511-e1f6d9453174?version=../auth/device/verify%3fuser_code%3d7BBPA5%26allow%3d1%23
  if (!isValidUUID(assetId)) {
    yield put(showAssetNotFoundError());
    return;
  }
  // We need to make an extra call to getAsset endpoint in order to know the error status code.
  let errorStatus;
  try {
    yield call(getAssetService, assetId);
  } catch (err) {
    errorStatus = get(err, 'response.status');
  }
  const hasCurrentUserConfirmedEmail = yield select(
    hasCurrentUserConfirmedEmailAddress
  );

  // if the user is forbidden to access an asset (and assuming that the user confirmed their
  // email address), it should still have the option to request access to that asset
  if (errorStatus === 403 && hasCurrentUserConfirmedEmail) {
    yield put(
      openModal(
        <RequestAccessToEntity
          entityType={ENTITY_TYPE.ASSET}
          entityId={assetId}
        />,
        { canCloseModal: false }
      )
    );
  } else if (errorStatus === 404 && hasCurrentUserConfirmedEmail) {
    yield call(
      error,
      'Asset no longer available',
      'This asset may have been deleted',
      {
        priority: priority.PROJECT_NOT_FOUND,
        primaryText: 'Return to project view',
      }
    );
    yield put(returnToAccount());
  } else {
    yield put(showAssetNotFoundError());
  }
}

export function* fetchPlayerContainerAssets(action) {
  const { assetId, versionStackId } = action.payload;
  const assetEntity = yield call(getAssetEntity, assetId);
  if (!assetEntity) {
    // Decides if the user can request access to the asset or not.
    yield call(verifyAssetAccess, assetId);
    return;
  }

  yield put(getCommentsByAsset(assetId));
  yield put(getImpressionsByAsset(assetId));
  yield put(createViewAssetImpression(assetId));

  const [hierarchicalData, organizationalData] = yield all([
    call(fetchAssetHierarchicalData, assetEntity, versionStackId),
    call(fetchAssetOrganizationData, assetEntity),
  ]);

  const { projectId, accountId } = organizationalData;
  const { folderId } = hierarchicalData;
  const lastViewedProjectId = projectId;

  const { id: prevProjectId } = (yield select(currentProjectSelector)) || {};
  const { id: prevFolderId } = (yield select(currentFolderSelector)) || {};
  const { id: prevAccountId } = (yield select(currentAccountSelector)) || {};

  if (accountId !== prevAccountId) {
    yield put(setCurrentAccount(accountId, prevAccountId, lastViewedProjectId));
  }
  if (projectId !== prevProjectId) {
    yield put(setCurrentProject(projectId, prevProjectId));
    yield spawn(updateLastViewedProjectForAccount, accountId, projectId);
  }
  if (folderId !== prevFolderId) {
    yield put(setCurrentFolder(folderId, prevFolderId));
  }

  yield put(setRelationalData({ ...hierarchicalData, ...organizationalData }));

  yield spawn(track, 'media-viewed-client', {
    file_id: assetId,
    filesize: assetEntity.filesize,
    file_format: assetEntity.filetype,
    account_id: accountId,
    project_id: projectId,
    page_type: 'player page',
    media_type: getMediaType(assetEntity),
    view_count: assetEntity.view_count,
    is_version_stack: !!versionStackId,
  });
}

/**
 * This is useful if an asset needs to be fetched from the server to update local
 * state, but not update any other data around the asset
 * @param {*} action
 */
export function* fetchPlayerContainerAssetsWithoutTouchingData(action) {
  const { assetId } = action.payload;
  yield put(getAssetChildren(assetId));
  const assetEntity = yield call(getAssetEntity, assetId);

  if (!assetEntity) {
    // Decides if the user can request access to the asset or not.
    yield call(verifyAssetAccess, assetId);
  }
}

export function* downloadFrameGrab(action) {
  const { assetId, frame } = action.payload;
  yield put(getFrameGrab(assetId, frame));
  yield take(ASSET.FRAME_GRAB.SUCCESS);
  const frameGrabs = yield select(assetFrameGrabSelector);
  const frameGrabURL = frameGrabs[assetId][frame];
  if (frameGrabURL) {
    const { searchParams } = new URL(frameGrabURL);
    downloadUrlAsFile(frameGrabURL, searchParams.get('filename'));
  }
}

export function* setNewestCommentCreateId(resp) {
  const commentId = resp.payload.response.result;
  yield put(setNewestCommentCreatedId(commentId));
}

// TODO(marvin): this should be called from the call site saga instead
function* trackCommentCreation(commentId) {
  const comment = yield select(commentEntitySelector, { commentId });
  const asset = yield select(assetEntitySelector, {
    assetId: comment.asset_id,
  });
  const commentCountsByAsset = yield select(commentCountsByAssetSelector);

  const commentType = (() => {
    if (comment.annotation && comment.text) {
      return 'both';
    }
    if (comment.annotation) {
      return 'annotation';
    }
    if (comment.text) {
      return 'text';
    }

    return null;
  })();

  const hasMentions = comment.comment_entities.some(
    (entity) => entity.type === 'mention'
  );

  yield spawn(trackViewer, 'comment-created-client', {
    comment_id: commentId,
    comment_type: commentType,
    duration: comment.duration,
    file_format: asset.filetype,
    view_count: asset.view_count,
    existing_media_comment_count: commentCountsByAsset[comment.asset_id] || 0,
    includes_at_mention: hasMentions,
    is_timestamp_enabled: !!comment.timestamp,
    is_team_only: comment.private,
  });
}

export function* reorderVersionStackInPlayer(
  childAssetId,
  prevAssetId,
  nextAssetId
) {
  const location = 'player_page';
  yield call(
    reorderVersionInStack,
    childAssetId,
    prevAssetId,
    nextAssetId,
    location
  );
}

export const testExports = {
  trackCommentCreation,
  verifyAssetAccess,
};

export default [
  debounce(100, PLAYER_CONTAINER.IS_FETCHING, fetchPlayerContainerAssets),
  takeLatest(
    PLAYER_CONTAINER.IS_FETCHING_PARENT_ASSET,
    fetchPlayerContainerAssetsWithoutTouchingData
  ),
  takeLatest(PLAYER_CONTAINER.DOWNLOAD_FRAME_GRAB, downloadFrameGrab),
  takeLatest(COMMENT_BY_ASSET.CREATE.SUCCESS, setNewestCommentCreateId),
  takeLatest(
    COMMENT_BY_ASSET.CREATE.SUCCESS,
    ({
      payload: {
        response: { result: commentId },
      },
    }) => trackCommentCreation(commentId)
  ),
  takeEvery(
    PLAYER_CONTAINER.REORDER_VERSION_ASSET,
    ({ payload: { childAssetId, prevAssetId, nextAssetId } }) =>
      reorderVersionStackInPlayer(childAssetId, prevAssetId, nextAssetId)
  ),
  takeLatest(PLAYER_CONTAINER.ASSET_NOT_FOUND, ({ payload: { assetId } }) =>
    verifyAssetAccess(assetId)
  ),
];
