import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import { checkoutFlowV2Enabled } from 'utils/featureFlags';
import AddCreditCardWithPlanSummary from './AddCreditCardWithPlanSummary';

const mapDispatchToProps = {
  closeModal,
};

function mapStateToProps(state) {
  const isCheckoutFlowV2Enabled = checkoutFlowV2Enabled(state);

  return {
    isCheckoutFlowV2Enabled,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCreditCardWithPlanSummary);
