import { connect } from 'react-redux';
import { errorMessageSelector } from 'components/PaymentFlow/selectors';
import { currentAccountSelector } from 'selectors/accounts';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { checkoutVatIdCollectionEnabled } from 'utils/featureFlags';
import { saveAddress, saveTaxId } from './actions';
import CreditCardFormElements from './CreditCardFormElements';

function mapStateToProps(state) {
  const submissionError = errorMessageSelector(state);
  const isCheckoutVatIdCollectionEnabled = checkoutVatIdCollectionEnabled(
    state
  );

  const currentAccount = currentAccountSelector(state);
  const account =
    accountEntitySelector(state, { accountId: currentAccount.id }) || {};

  return {
    submissionError,
    valueAddedTax: account?.vat,
    isCheckoutVatIdCollectionEnabled,
  };
}

const mapDispatchToProps = {
  saveAddress,
  saveTaxId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditCardFormElements);
