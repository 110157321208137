import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

export const ADD_STORAGE_FLOW = generateActionTypes('ADD_STORAGE_FLOW', [
  'IS_FETCHING',
  'START',
  'CONTINUE',
  'END',
]);

export const startFlow = createAction(
  ADD_STORAGE_FLOW.START,
  (accountId, type, trackingProps) => ({ accountId, type, trackingProps })
);

export const continueFlow = createAction(
  ADD_STORAGE_FLOW.CONTINUE,
  (updatedLimit, totalStorageAdded, incrementTotal) => ({
    updatedLimit,
    totalStorageAdded,
    incrementTotal,
  })
);

export const endFlow = createAction(ADD_STORAGE_FLOW.END, () => ({}));
