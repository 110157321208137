import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import { isAccountOnPlanWithUserMaxSelector } from 'selectors/accounts';
import {
  v8PricingOnlyEnabled,
  checkoutFlowV2Enabled,
} from 'utils/featureFlags';
import { startFlow, startFlowWithSelectedPlan, endFlow } from './actions';
import SelectPlanFlow from './SelectPlanFlow';

function mapStateToProps(state, { selectedPlanId }) {
  const isAccountOnPlanWithUserMax =
    v8PricingOnlyEnabled(state) || isAccountOnPlanWithUserMaxSelector(state);

  const isCheckoutFlowV2Enabled = checkoutFlowV2Enabled(state);

  return {
    isAccountOnPlanWithUserMax,
    isCheckoutFlowV2Enabled,
    selectedPlanId,
  };
}

const mapDispatchToProps = {
  startFlow,
  startFlowWithSelectedPlan,
  endFlow,
  onCancel: closeModal,
  onComplete: closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectPlanFlow);
