import { createAction } from 'redux-actions';
import {
  generateActionTypes,
  generateFetchActionTypes,
} from 'shared/actions/helpers';

const module = 'SETUP_ACCOUNT_FLOW';
export const SETUP_ACCOUNT_FLOW: Object = {
  ...generateActionTypes(module, [
    'START',
    'CONTINUE',
    'END',
    'APPLY_PROMOTION',
    'APPLY_STARTER_TRIAL',
    'IS_APPLYING_PROMOTION',
    'CHANGE_TEAM_AND_ACCOUNT_NAME',
    'CREATE_PROJECT',
    'CREATE_DEMO_PROJECT',
    'FETCH_PROMOTION',
    'UPLOAD_DROPPED_TEAM_IMAGE',
    'UPLOAD_SELECTED_TEAM_IMAGE',
    'UPLOAD_DROPPED_IMAGE_FOR_ME',
    'UPLOAD_SELECTED_IMAGE_FOR_ME',
    'REMOVE_TEAM_IMAGE',
    'REMOVE_IMAGE_FOR_ME',
    'SETUP_ACCOUNT_OR_GO_TO_V4',
    'GO_TO_V4',
  ]),
  ...generateFetchActionTypes(module, [
    'UPLOADING_ACCOUNT_IMAGE',
    'UPLOADING_TEAM_IMAGE',
    'UPLOADING_IMAGE_FOR_ME',
  ]),
};

export const startFlow = createAction(
  SETUP_ACCOUNT_FLOW.START,
  (accountId) => ({ accountId })
);

export const continueFlow = createAction(
  SETUP_ACCOUNT_FLOW.CONTINUE,
  () => ({})
);

export const goToV4 = createAction(SETUP_ACCOUNT_FLOW.GO_TO_V4, () => ({}));

export const endFlow = createAction(SETUP_ACCOUNT_FLOW.END, () => ({}));

export const changeTeamAndAccountName = createAction(
  SETUP_ACCOUNT_FLOW.CHANGE_TEAM_AND_ACCOUNT_NAME,
  (name) => ({ name })
);

export const applyPromotion = createAction(
  SETUP_ACCOUNT_FLOW.APPLY_PROMOTION,
  (promoCode) => ({ promoCode })
);

export const applyStarterTrial = createAction(
  SETUP_ACCOUNT_FLOW.APPLY_STARTER_TRIAL,
  () => ({})
);

export const uploadDroppedTeamImage = createAction(
  SETUP_ACCOUNT_FLOW.UPLOAD_DROPPED_TEAM_IMAGE,
  (file, teamId) => ({ file, teamId })
);

export const uploadSelectedTeamImage = createAction(
  SETUP_ACCOUNT_FLOW.UPLOAD_SELECTED_TEAM_IMAGE,
  (file, teamId) => ({ file, teamId })
);

export const uploadDroppedImageForMe = createAction(
  SETUP_ACCOUNT_FLOW.UPLOAD_DROPPED_IMAGE_FOR_ME,
  (file) => ({ file })
);

export const uploadSelectedImageForMe = createAction(
  SETUP_ACCOUNT_FLOW.UPLOAD_SELECTED_IMAGE_FOR_ME,
  () => ({})
);

export const removeTeamImage = createAction(
  SETUP_ACCOUNT_FLOW.REMOVE_TEAM_IMAGE,
  (teamId) => ({ teamId })
);

export const isApplyingPromotion = createAction(
  SETUP_ACCOUNT_FLOW.IS_APPLYING_PROMOTION,
  (isApplyingPromo) => ({ isApplyingPromo })
);

export const removeImageForMe = createAction(
  SETUP_ACCOUNT_FLOW.REMOVE_IMAGE_FOR_ME,
  () => ({})
);

export const createProject = createAction(
  SETUP_ACCOUNT_FLOW.CREATE_PROJECT,
  () => ({})
);

export const createDemoProject = createAction(
  SETUP_ACCOUNT_FLOW.CREATE_DEMO_PROJECT,
  () => ({})
);

export const fetchPromotion = createAction(
  SETUP_ACCOUNT_FLOW.FETCH_PROMOTION,
  (promoCode) => ({ promoCode })
);
