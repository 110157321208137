import countries from './data/countries.json';
import { VAT_EXEMPT_ELIGIBLE_COUNTRIES } from './constants';

export const countryNameMap = {};
export const countryCodeMap = {};

function mapCodeAndName(entityNameMap, entityCodeMap, entity) {
  // eslint-disable-next-line no-param-reassign
  entityNameMap[entity.name] = entity.code;
  // eslint-disable-next-line no-param-reassign
  entityCodeMap[entity.code] = entity.name;
}

countries.forEach((country) =>
  mapCodeAndName(countryNameMap, countryCodeMap, country)
);

export const isCountryCanada = (country) => country === 'CA';
export const isCountryUS = (country) => country === 'US';
export const isCountryUSorCanada = (country) =>
  isCountryCanada(country) || isCountryUS(country);

export const getIsTaxExempt = (taxExempt) => taxExempt === 'exempt';

/**
 * util that checks if the users current billing country
 * exist in the list of vat exempt elligible countries.
 */
export const getIsBillingCountryVatExemptEligible = (
  billingCountryAbbreviation
) =>
  VAT_EXEMPT_ELIGIBLE_COUNTRIES.some(
    (country) => country.code === billingCountryAbbreviation
  );

/**
 * util used to map a countries abbreviation to
 * their countries unabbreviated name
 */
export const getCountryNameFromAbbreviation = (billingCountryAbbreviation) =>
  VAT_EXEMPT_ELIGIBLE_COUNTRIES.find(
    (element) => element.code === billingCountryAbbreviation
  )?.country;
