/**
 * This is a list of vat eligible countries.
 * We will be exanding upon this list in the future.
 */
export const VAT_EXEMPT_ELIGIBLE_COUNTRIES = [
  {
    country: 'Denmark',
    code: 'DK',
  },
];
