import React from 'react';
import { push } from 'connected-react-router';
import { keyBy, uniq, mapKeys, snakeCase } from 'lodash';
import {
  call,
  put,
  fork,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import track from 'analytics';

import { formatLongDate } from '@frameio/components/src/utils/datetimeHelpers';
import {
  status as AssetStatus,
  type as AssetType,
} from '@frameio/core/src/assets/helpers/constants';
import {
  assetEntitySelector,
  assetEntitiesByAssetIdsSelector,
  assetEntitiesSelector,
} from '@frameio/core/src/assets/selectors';
import {
  createReviewLinkForProject as createReviewLinkForProjectCoreSaga,
  sendReviewLinkEmails as sendReviewLinkEmailsCoreSaga,
  updateReviewLink as updateReviewLinkCoreSaga,
  deleteReviewerFromReviewLink as deleteReviewerFromReviewLinkCoreSaga,
  deletePendingReviewerFromReviewLink as deletePendingReviewerFromReviewLinkCoreSaga,
} from '@frameio/core/src/reviewLinks/sagas';
import { listSessionWatermarkTemplatesForReviewLink } from '@frameio/core/src/sessionWatermarkTemplate/sagas';
import {
  listPendingAndRegularReviewersForReviewLinkId as listPendingAndRegularReviewersForReviewLinkIdCoreSaga,
  batchAddTeamMembersToReviewLink as batchAddTeamMembersToReviewLinkCoreSaga,
} from '@frameio/core/src/reviewers/sagas';
import { updateProjectUserPreferences } from '@frameio/core/src/projects/sagas';
import { updateTeam as updateTeamSaga } from '@frameio/core/src/teams/sagas';
import { batchCreateReviewLinkItems } from '@frameio/core/src/reviewLinkItems/sagas';
import { getEntityFromNormalizedResponse } from '@frameio/core/src/shared/utils/entities';

import { showErrorToast, showSuccessToast } from 'actions/toasts';
import { openModal, closeModal, MODAL } from 'components/Modal/actions';
import { resetLinks as resetLinksListFetchStatus } from 'pages/ProjectContainer/ProjectLinks/actions';
import {
  fetchedAssetIdsSelector,
  isPlaceholderInFolder,
  projectIdSelector,
  assetsSortBySelector,
  assetsSortDescendingSelector,
} from 'pages/ProjectContainer/selectors';
import { currentFolderSelector } from 'selectors/folders';
import { pathnameSelector } from 'selectors/router';
import { currentProjectSelector } from 'selectors/projects';
import { currentAccountSelector } from 'selectors/accounts';
import { permittedActionsForAccountSelector } from 'selectors/permissions';
import { openSelectPlanFlowModal } from 'components/SelectPlanFlow/actions';
import { didUpgradePlan } from 'components/SelectPlanFlow/sagas';
import { allFeatures } from 'planFeatures';
import { sortAssetIds } from 'pages/ProjectContainer/ProjectAssets/sortOptions';

import { getPath, isPathNamePlayerPage, PROJECT_LINKS_URL } from 'URLs';
import {
  folderSharingEnabled,
  newSharingModalEnabled,
} from 'utils/featureFlags';
import { SORT_CONST } from 'components/SharePanel/DropDown/sortOptions';
import { getTokensToSubmit, getTrackingData } from '../UserSearch/utils';
import { calculateInvitedUsersCount } from '../UserSearch/sagas';

import ReviewLinkEditor from './ConnectedReviewLinkEditor';
import REVIEW_LINK_EDITOR, {
  selectReviewLinkItems,
  setReviewLinkEditorId,
  toggleReviewLinkSelectionMode,
  storeInvitedIds,
  exitEditor,
} from './actions';
import {
  reviewLinkEditorAssetIdsSelector,
  reviewLinkEditorIdSelector,
  invitedEntityAndTypeForId,
  invitedEntitiesForItemIdSelector,
  invitedTotalForItemIdSelector,
} from './selectors';

/**
 * Return a list of shareable asset ids.
 */
function* filterShareableAssetIds(assetIds) {
  const { id: folderId } = yield select(currentFolderSelector);
  const assetEntities = yield select(assetEntitiesSelector);
  const isFolderSharingEnabled = yield select(folderSharingEnabled);

  // Shareable assets ids within a given folder (e.g. project root, child folder)
  const shareAssetIds = assetIds.filter((id) => {
    const isPlaceholder = isPlaceholderInFolder(id, folderId);
    const { status, type } = assetEntities[id] || {};
    const isFolder = type === AssetType.FOLDER;
    const isFile = type === AssetType.FILE;
    const isPendingUpload = isFile && status === AssetStatus.CREATED;

    const isShareable = !isPlaceholder && !isPendingUpload;

    return isFolderSharingEnabled ? isShareable : isShareable && !isFolder;
  });

  return shareAssetIds;
}

/**
 * Create a new review link for a project with an array of selected asset
 * ids. Upon successful creation of the review link and its associated items,
 * return the review link id.
 */
function* createReviewLinkForProject(selectedAssetIds) {
  let reviewLinkName;
  const { id: projectId } = yield select(currentProjectSelector);
  const projectSortBy = yield select(assetsSortBySelector);
  const isDescending = yield select(assetsSortDescendingSelector);
  // Sorting by filetype is not currently supported in review links
  const sortType =
    projectSortBy === SORT_CONST.FILETYPE ? SORT_CONST.INDEX : projectSortBy;
  const sortDirection = isDescending ? SORT_CONST.DESC : SORT_CONST.ASC;

  const assets = yield select(assetEntitiesByAssetIdsSelector, {
    assetIds: selectedAssetIds,
  });

  const selectedAssetsSortedByProjectSort = sortAssetIds(
    selectedAssetIds,
    keyBy(assets, 'id'),
    projectSortBy,
    isDescending
  );

  const now = formatLongDate(Date.now());

  /*
    If only a single asset is selected to share, then default the
    review link name to the asset name.
  */
  if (selectedAssetIds.length === 1) {
    const { name: assetName } = yield select(assetEntitySelector, {
      assetId: selectedAssetIds[0],
    });
    reviewLinkName = assetName;
  } else {
    reviewLinkName = `Review Link - ${now}`;
  }

  const { success } = yield call(
    createReviewLinkForProjectCoreSaga,
    projectId,
    {
      name: reviewLinkName,
      sort_direction: sortDirection,
      sort_type: sortType,
    }
  );

  if (!success) {
    yield put(
      showErrorToast({
        header: 'Something went wrong while creating the review link.',
        subHeader: 'Please try again.',
      })
    );
    return false;
  }

  const { id: newReviewLinkId } = yield call(
    getEntityFromNormalizedResponse,
    success.payload.response
  );

  // Make a separate POST to batch create the review link's associated items
  yield call(
    batchCreateReviewLinkItems,
    newReviewLinkId,
    selectedAssetsSortedByProjectSort
  );

  // CORE-1783: Currently, logic exists in Project Links to determine if the paginated
  // links list needs to be fetched, depending the list page's fetch status. If the list
  // page has been previously fetched successfully (either (1) by first loading the Project
  // Links tab, or (2) clicking on the Links tab, and subsequently returning to the Media
  // tab), the list isn't updated to reflect a newly created review link. To ensure that
  // list page is re-fetched as needed, we reset its fetch status here.
  yield put(resetLinksListFetchStatus(true));

  return newReviewLinkId;
}

/**
 * Route to ‘/projects/:projectId/review-links‘
 */
function* redirectToReviewLinkList() {
  const projectId = yield select(projectIdSelector);
  yield put(push(getPath(PROJECT_LINKS_URL, { projectId })));
}

/**
 * Update existing review link items, and redirect to Links tab.
 */
function* updateReviewLinkItemsAndRedirect(reviewLinkId, assetIds) {
  const { success } = yield call(
    batchCreateReviewLinkItems,
    reviewLinkId,
    assetIds
  );

  if (success) {
    yield put(toggleReviewLinkSelectionMode(undefined));
    yield call(redirectToReviewLinkList);
  } else {
    yield put(
      showErrorToast({
        header: 'Something went wrong while updating the review link items.',
        subHeader: 'Please try again.',
      })
    );
  }
}

export function* exitSelectionMode() {
  yield put(toggleReviewLinkSelectionMode(undefined));
  yield put(setReviewLinkEditorId(null));
}

function* openEditor({ payload: { id, defaultTab } }) {
  // ReviewLinkEditor determines the id of the review link entity being edited by
  // reading the `id` proprty of the `reviewLinkEditor` slice. We could probably
  // refactor ReviewLinkEditor to be providd with an id directly, but for now, we
  // can set the `id` below if an argument is provided to `openEditor`
  if (id) {
    yield put(setReviewLinkEditorId(id));
  }

  let reviewLinkId;
  reviewLinkId = yield id || select(reviewLinkEditorIdSelector);
  yield fork(listSessionWatermarkTemplatesForReviewLink, reviewLinkId);

  const isNewSharingModalEnabled = yield select(newSharingModalEnabled);
  yield put(
    openModal(<ReviewLinkEditor defaultTab={defaultTab} />, {
      style: {
        height: isNewSharingModalEnabled ? '96vh' : 'auto',
        maxHeight: '670px', // ENT-2451
      },
    })
  );

  // NOTE [ENT-2473] This is a bit of a hack in order to ensure that the review link editor
  // resets the review link state and exits selection mode. While the pattern of `take`ing
  // MODAL.CLOSE isn't recommended because of the potential to clash with other modals (see
  // https://github.com/Frameio/web-client/pull/8998 for more info) calling exitSelectionMode
  // will effectively be a noop for non-review link modals. Additionally, we check for the
  // presence of `reviewLinkEditorId` in Redux as an additional defensive measure of safety.
  const didEditorClose = yield take(MODAL.CLOSE);
  reviewLinkId = yield select(reviewLinkEditorIdSelector);

  if (didEditorClose && reviewLinkId) {
    yield spawn(track, 'review-link-modal-closed', {
      modal_version: isNewSharingModalEnabled
        ? '2020_01_tabs'
        : '2019_07_no_tabs',
    });
    yield call(exitSelectionMode);
  }
}

// On modal close, we can assume the conclusion of the review link
// sharing flow and can safely unset the review link editor id and
// deselect any assets. MODAL.CLOSE should not trigger this action.
// See https://github.com/Frameio/web-client/pull/8998
function* exitEditorAndCleanup() {
  yield put(closeModal());
  yield call(exitSelectionMode);
}

/**
 * Confirm the selected asset ids in the reviewLinkEditor redux slice
 * for sharing as a review link.
 */
function* confirmSelection() {
  const reviewLinkId = yield select(reviewLinkEditorIdSelector);
  const assets = yield select(reviewLinkEditorAssetIdsSelector);
  const isNewSharingModalEnabled = yield select(newSharingModalEnabled);

  if (reviewLinkId) {
    yield call(updateReviewLinkItemsAndRedirect, reviewLinkId, assets);
  } else {
    const id = yield call(createReviewLinkForProject, assets);

    if (!id) {
      yield put(
        showErrorToast({
          header: 'Something went wrong whil updating the review link',
          subHeader: 'Please try again',
        })
      );
      return;
    }

    yield spawn(track, 'review-link-modal-shown', {
      modal_version: isNewSharingModalEnabled
        ? '2020_01_tabs'
        : '2019_07_no_tabs',
      source: 'selection_mode',
    });
    yield put(setReviewLinkEditorId(id));
    yield call(openEditor, { payload: {} });
  }
}

/**
 * Create a new review link for a project with an array of selected
 * asset ids.
 */
function* createReviewLinkWithAssets(assets) {
  const pathname = yield select(pathnameSelector);
  const isPlayerPage = isPathNamePlayerPage(pathname);
  const shareAssetIds = yield call(filterShareableAssetIds, assets);
  const isNewSharingModalEnabled = yield select(newSharingModalEnabled);

  /*
    If no shareable assets are selected, toggle selection mode to prompt
    user selection instead of launching the share modal.
  */
  if (shareAssetIds.length === 0) {
    yield put(toggleReviewLinkSelectionMode([]));
    return;
  }

  const id = yield call(createReviewLinkForProject, shareAssetIds);
  if (id) {
    yield spawn(track, 'review-link-modal-shown', {
      modal_version: isNewSharingModalEnabled
        ? '2020_01_tabs'
        : '2019_07_no_tabs',
      source: isPlayerPage ? 'player_page' : 'project_view',
    });
    yield put(setReviewLinkEditorId(id));
    yield call(openEditor, { payload: {} });
  }
}

/**
 * Update the settings for a given review link id.
 */
function* updateReviewLinkSettings(id, params) {
  const { success } = yield call(updateReviewLinkCoreSaga, id, params);

  if (!success) {
    yield put(
      showErrorToast({
        header: 'Something went wrong while updating the review link.',
        subHeader: 'Please try again.',
      })
    );
  }
}

/**
 * Send a review link email
 */
function* sendReviewLinkEmails(id, options) {
  const { success } = yield call(sendReviewLinkEmailsCoreSaga, id, options);
  if (success) {
    yield put(
      showSuccessToast({
        header: 'Email invitations sent!',
      })
    );
    yield put(exitEditor());
    yield put(setReviewLinkEditorId(null));
  } else {
    yield put(
      showErrorToast({
        header: 'Something went wrong while sending email invitations.',
        subHeader: 'Please try again.',
      })
    );
  }
}

function* inviteUsersToReviewLink({ payload: { tokens, sendEmail, message } }) {
  const isNewSharingModalEnabled = yield select(newSharingModalEnabled);
  const reviewLinkId = yield select(reviewLinkEditorIdSelector);
  const tokensToSubmit = getTokensToSubmit(tokens);

  const serverParams = {
    batch: tokensToSubmit,
    send_email: sendEmail,
    default_message: message,
  };

  const { failure } = yield call(
    batchAddTeamMembersToReviewLinkCoreSaga,
    reviewLinkId,
    serverParams
  );
  if (failure) {
    if (tokens.length > 50) {
      yield put(
        showErrorToast({
          header: 'You cannot share with more than 50 people at a time',
        })
      );
    } else {
      yield put(
        showErrorToast({
          header: 'Something went wrong while inviting reviewers.',
          subHeader: 'Please try again.',
        })
      );
    }
    return;
  }

  const invitedUsersCount = yield call(calculateInvitedUsersCount, tokens);

  yield put(
    showSuccessToast({
      header: `Shared with ${invitedUsersCount} ${
        invitedUsersCount === 1 ? 'person' : 'people'
      }`,
    })
  );
  yield put(exitEditor());
  yield put(setReviewLinkEditorId(null));
  yield spawn(track, 'share-link-modal-submitted', {
    ...getTrackingData(tokens, 'review_link'),
    modal_version: isNewSharingModalEnabled
      ? '2020_01_tabs'
      : '2019_07_no_tabs',
  });
}

function* deleteInviteeFromReviewLink({ payload: { entityId } }) {
  const reviewLinkId = yield select(reviewLinkEditorIdSelector);

  const { type } = yield select(invitedEntityAndTypeForId, { entityId });

  const deletionSaga =
    type === 'reviewer'
      ? deleteReviewerFromReviewLinkCoreSaga
      : deletePendingReviewerFromReviewLinkCoreSaga;

  const { success } = yield call(deletionSaga, reviewLinkId, entityId);

  // TODO(Scott) we need non-happy path support here.
  if (success) {
    const invitedCount = yield select(invitedTotalForItemIdSelector);
    let invitedIds = yield select(invitedEntitiesForItemIdSelector);
    invitedIds = invitedIds.filter((i) => i !== entityId);
    yield put(storeInvitedIds(invitedIds, invitedCount - 1));
  }
}

/**
 * Select all assets within a given project context (e.g. in the root folder,
 * or a child folder) to share as a review link.
 */
function* selectAllAssets() {
  const assetIdsInFolder = yield select(fetchedAssetIdsSelector);

  // Asset ids currently staged in the review link editor to share
  const reviewLinkEditorAssetIds = yield select(
    reviewLinkEditorAssetIdsSelector
  );

  const shareAssetIds = yield call(filterShareableAssetIds, assetIdsInFolder);

  yield put(
    selectReviewLinkItems(
      uniq([...reviewLinkEditorAssetIds, ...shareAssetIds]),
      true
    )
  );
}

/**
 * Cancel asset selection for a new or existing review link
 */
function* cancelSelection() {
  /*
    If a review link's content is being edited and a user elects to
    cancel the edit, ensure that the editor id in redux in set to null,
    and redirect to the links tab.
  */
  const isEditingExistingReviewLink = !!(yield select(
    reviewLinkEditorIdSelector
  ));
  yield call(exitSelectionMode);

  if (!isEditingExistingReviewLink) return;
  yield call(redirectToReviewLinkList);
}

function* listInvitedEntitiesForReviewLink({ payload }) {
  const { page } = payload;
  const reviewLinkId = yield select(reviewLinkEditorIdSelector);

  const data = yield call(
    listPendingAndRegularReviewersForReviewLinkIdCoreSaga,
    reviewLinkId,
    { page }
  );
  if (data.failure) return;

  const loadedIds = yield select(invitedEntitiesForItemIdSelector);
  const fetchedIds = data.success.result;
  const reviewerIds = (loadedIds || []).concat(fetchedIds);

  const reviewerCount = data.success.headers.total;
  yield put(storeInvitedIds(reviewerIds, reviewerCount));
}

function* setDefaultSessionWatermarkTemplateIdForTeam(action) {
  const {
    payload: { teamId, ...actionPayload },
  } = action;

  const toSnakeCasePayload = (obj) => mapKeys(obj, (_, k) => snakeCase(k));
  const payload = toSnakeCasePayload(actionPayload);
  const result = yield call(updateTeamSaga, teamId, payload);

  if (result.failure) {
    yield put(
      showErrorToast({
        header:
          'Something went wrong setting the default template for this team',
      })
    );
  }
}

function* upgradePlan({ payload: { reviewLinkId, feature } }) {
  const { id: accountId } = yield select(currentAccountSelector);
  const action = {
    payload: {
      id: reviewLinkId,
      defaultTab: 'settings',
    },
  };

  const isShareLinkExpirationFeature =
    feature === allFeatures.shareLinkExpiration;
  const source = isShareLinkExpirationFeature
    ? 'share link expiration feature gate'
    : 'pw-protected shares feature gate';

  yield put(
    openSelectPlanFlowModal(accountId, {
      source,
      feature,
    })
  );

  const planUpgraded = yield call(didUpgradePlan);

  if (!planUpgraded) {
    yield call(openEditor, action);
    return;
  }

  const {
    canUseShareLinkExpiration,
    canUsePasswordProtectedShares,
  } = yield select(permittedActionsForAccountSelector, { accountId });

  if (canUseShareLinkExpiration && planUpgraded) {
    yield spawn(track, 'feature-unlocked', {
      feature: allFeatures.shareLinkExpiration,
    });
  }

  if (canUsePasswordProtectedShares && planUpgraded) {
    yield spawn(track, 'feature-unlocked', {
      feature: allFeatures.passwordProtectedShares,
    });
  }
}

function* updateProjectReviewLinkPreferences(action) {
  const { projectId, params } = action.payload;

  const { failure } = yield call(
    updateProjectUserPreferences,
    projectId,
    params.user_preferences
  );

  if (failure) {
    yield put(
      showErrorToast({ header: 'Review link preferences failed to update' })
    );
  }
}

export const testExports = {
  cancelSelection,
  confirmSelection,
  createReviewLinkForProject,
  createReviewLinkWithAssets,
  deleteInviteeFromReviewLink,
  exitEditorAndCleanup,
  filterShareableAssetIds,
  inviteUsersToReviewLink,
  listInvitedEntitiesForReviewLink,
  openEditor,
  redirectToReviewLinkList,
  selectAllAssets,
  updateProjectReviewLinkPreferences,
  updateReviewLinkItemsAndRedirect,
  updateReviewLinkSettings,
  upgradePlan,
};

export default [
  takeLatest(REVIEW_LINK_EDITOR.CANCEL_SELECTION, cancelSelection),
  takeLatest(REVIEW_LINK_EDITOR.CONFIRM_FROM_SELECTION, confirmSelection),
  takeLatest(REVIEW_LINK_EDITOR.CREATE_WITH_ASSETS, ({ payload: { assets } }) =>
    createReviewLinkWithAssets(assets)
  ),
  takeLatest(REVIEW_LINK_EDITOR.OPEN_EDITOR, openEditor),
  takeLatest(REVIEW_LINK_EDITOR.SELECT_ALL, selectAllAssets),
  takeLatest(
    REVIEW_LINK_EDITOR.SET_DEFAULT_TEAM_SESSION_WATERMARK_ID,
    setDefaultSessionWatermarkTemplateIdForTeam
  ),
  takeEvery(
    REVIEW_LINK_EDITOR.LIST_REVIEWERS_FOR_REVIEW_LINK_ID,
    listInvitedEntitiesForReviewLink
  ),
  takeLatest(REVIEW_LINK_EDITOR.SEND_EMAILS, ({ payload: { id, options } }) =>
    sendReviewLinkEmails(id, options)
  ),
  takeLatest(
    REVIEW_LINK_EDITOR.UPDATE_SETTINGS,
    ({ payload: { id, params } }) => updateReviewLinkSettings(id, params)
  ),
  takeLatest(REVIEW_LINK_EDITOR.INVITE_USERS, inviteUsersToReviewLink),
  takeLatest(REVIEW_LINK_EDITOR.DELETE_INVITEE, deleteInviteeFromReviewLink),
  takeLatest(REVIEW_LINK_EDITOR.EXIT, exitEditorAndCleanup),
  takeLatest(REVIEW_LINK_EDITOR.UPGRADE_PLAN, upgradePlan),
  takeLatest(
    REVIEW_LINK_EDITOR.UPDATE_PROJECT_REVIEW_LINK_PREFERENCES,
    updateProjectReviewLinkPreferences
  ),
];
