/*eslint-disable*/
import { connect } from 'react-redux';
import { canCloseModalSelector } from 'components/Modal/selectors';
import { isFreePlanWithUserMaxSelectedSelector } from 'components/SelectPlanFlow/selectors';
import PaymentFlow from './PaymentFlow';
import { startFlow, continueFlow, endFlow, setPaymentError } from './actions';
import { checkoutFlowV2Enabled } from 'utils/featureFlags';

function mapStateToProps(state) {
  const isCheckoutFlowV2Enabled = checkoutFlowV2Enabled(state);

  return {
    canCloseModal: canCloseModalSelector(state),
    isFreePlanWithUserMaxSelected: isFreePlanWithUserMaxSelectedSelector(state),
    isCheckoutFlowV2Enabled: isCheckoutFlowV2Enabled,
  };
}

const mapDispatchToProps = {
  startFlow,
  endFlow,
  continueFlow,
  setPaymentError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentFlow);

export const testExports = {
  mapDispatchToProps,
};
